export default {
  emailsSimple (state) {
    return state.emailsChecked.filter(
      (email) => {
        if (email !== null) { return email.validationType === 'SIMPLE' }
      }
    )
  },
  emailsDeep (state) {
    return state.emailsChecked.filter(
      (email) => {
        if (email !== null) { return email.validationType === 'DEEP' }
      }
    )
  }
}
