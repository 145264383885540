import findIndex from 'lodash/findIndex'

export default {
    openDialog ({ commit, state }, dialogName) {
        try {
            if (typeof name !== 'string') throw new Error('dialog name must be a string !')
            const index = findIndex(state.dialog, { name: dialogName })
            if (index < 0) throw new Error('dialog name doesn\'t exist !')

            commit('openDialog', dialogName)
        } catch (e) {
            console.error(e)
        }
    },

    closeDialog ({ commit, state }, dialogName) {
        try {
            if (typeof name !== 'string') throw new Error('dialog name must be a string !')
            const index = findIndex(state.dialog, { name: dialogName })
            if (index < 0) throw new Error('dialog name doesn\'t exist !')

            commit('closeDialog', dialogName)
        } catch (e) {
            console.error(e)
        }
    }
}
