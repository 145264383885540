<template>
  <material-card
    class="campaign_statistic"
    icon="mdi-chart-bar"
    :title="$t('views.home.campaign.statistic.title')"
    color="primary"
  >
    <v-row data-v-tour-interface-step="4" />
    <v-row
      align-content="start"
      style="align-items: center"
    >
      <v-col
        :cols="6"
        align="right"
      >
        <v-select
          v-model="selectModel"
          class="select"
          :items="numberOfWeeks"
          item-text="text"
          item-value="value"
          :label="$t('views.home.campaign.statistic.selectWeeks')"
        />
      </v-col>
      <v-col
        :cols="6"
        align="left"
      >
        <v-switch
          :v-model="enableDeepStat"
          :input-value="enableDeepStat"
          color="primary"
          :inset="false"
          :label="$t('views.home.campaign.statistic.enableDeepStat.' + enableDeepStat.toString())"
          @change="switchChange"
        />
      </v-col>
    </v-row>
    <div class="chart-container-campaign">
      <canvas id="campaign_statistic-week-curve" />
    </div>
  </material-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Chart from 'chart.js'
import CampaignCurveCharts from './CampaignCurveCharts'

const campaignCurveCharts = new CampaignCurveCharts()

export default {
  data: function () {
    return {
      enableLoader: false,
      enableDeepStat: false,
      selectModel: 13,
      numberOfWeeks: [],
      myChart: null
    }
  },
  computed: {
    ...mapState('campaign', ['weekStatistics']),
    locale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    selectModel () {
      this.updateCurveChart()
    },
    locale: function () {
      this.generateNumberOfWeeksOptions()
      this.createCurveChart()
    }
  },
  mounted () {
    this.generateNumberOfWeeksOptions()
    this.createCurveChart()
    window.addEventListener('resize', this.switchLegendOnCharts)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.switchLegendOnCharts)
  },
  methods: {
    ...mapActions('campaign', ['fetchWeekStatistics']),
    switchChange (value) {
      this.enableDeepStat = value
      this.updateCurveChart()
    },
    async updateCurveChart () {
      await this.fetchWeekStatistics(this.selectModel)
      if (this.enableDeepStat) {
        this.myChart.data = campaignCurveCharts.generateCurveDeep(this.weekStatistics, this)
      } else {
        this.myChart.data = campaignCurveCharts.generateCurveSimple(this.weekStatistics, this)
      }
      this.myChart.update()
    },
    async createCurveChart () {
      // on fait l'appel pour récupérer les données statistiques : this.weekStatistics
      await this.fetchWeekStatistics(this.selectModel)
      // construction du graphique
      const ctx = document.getElementById('campaign_statistic-week-curve')
      if (ctx) {
        // eslint-disable-next-line no-unused-vars
        this.myChart = new Chart(ctx, {
          type: 'line',
          data: this.enableDeepStat ? campaignCurveCharts.generateCurveDeep(this.weekStatistics, this) : campaignCurveCharts.generateCurveSimple(this.weekStatistics, this),
          options: {
            showLine: true,
            stepped: true,
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: window.innerWidth > 420
            },
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                // Permet d'afficher autre chose que les données brut par défaut
                label: function (tooltipItem, data) {
                  const item = data.datasets[tooltipItem.datasetIndex]
                  return `${item.label} : ${item.count[tooltipItem.index]}`
                }
              }
            },
            hover: {
              mode: 'index'

            },
            scales: {
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Date'
                }
              }],
              yAxes: [{
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: '%'
                },
                ticks: {
                  max: 100,
                  min: 0
                }
              }]
            }
          }
        })
      }
    },
    switchLegendOnCharts () {
      if (this.myChart !== null) {
        this.myChart.legend.options.display = window.innerWidth > 420
      }
    },
    generateNumberOfWeeksOptions () {
      this.numberOfWeeks = [{
        text: this.$t('views.home.campaign.statistic.graph.title.1'),
        value: 1
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.2'),
        value: 2
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.4'),
        value: 4
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.13'),
        value: 13
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.26'),
        value: 26
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.52'),
        value: 52
      }]
    }
  }
}
</script>
