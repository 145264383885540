export default {
    dialogCgu (state) {
        return state.dialog.filter((item) => item.name === 'cgu')[0]
    },
    dialogChangelog (state) {
        return state.dialog.filter((item) => item.name === 'changelog')[0]
    },
    dialogDonneesPersonnelles (state) {
        return state.dialog.filter((item) => item.name === 'donneesPersonnelles')[0]
    },
    dialogTour (state) {
        return state.dialog.filter((item) => item.name === 'tour')[0]
    }
}
