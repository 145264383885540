<template>
  <div class="pricing-table__inner row">
    <div class="pricing-table__head col-12 justify-center">
      <h3 class="pricing-table__title">
        <b>
          {{ $t("views.pricing.product.pack." + product.credits) }}
        </b>
      </h3>
      <div class="pricing-table__image">
        <img
          :src="imagePath"
          class="img-fluid"
          :alt="$t('views.pricing.product.pack.' + product.credits)"
        >
      </div>
    </div>
    <div class="pricing-table__body col-12">
      <del
          v-if="product.discount"
          class="text-warning"
      >
        <small v-if="vatIsNotZero">
          {{
            $t("views.pricing.product.noTaxes", [$n(product.mostExpensiveProductPrice, displayCurrency)])
          }}
        </small>
      </del>
      <div v-if="vatIsNotZero">
        <h3>
          {{ $t("views.pricing.product.noTaxes", [$n(htPrice, displayCurrency)]) }}
          <span v-if="product.discount">({{ product.discount }})</span>
        </h3>
        <small class="font-weight-light">
          {{ $t("views.pricing.product.vat", [$n(price, displayCurrency)]) }}
        </small>
        <p>
          {{ $t("views.pricing.product.perCredit", [$n(priceUnit)]) }}
        </p>
      </div>
      <div v-else>
        <h3>
          {{ $n(htPrice, displayCurrency) }}
        </h3>
        <p>
          {{ $t("views.pricing.product.perCreditNoVat", [$n(priceUnit)]) }}
        </p>
      </div>

      <v-btn
          color="primary"
          @click="$emit('addProduct', product)"
      >
        {{ $t("views.pricing.product.addToCart") }}
        <v-icon right>
          mdi-basket-outline
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: null
    },
    imagePath: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: 'eur'
    }
  },
  computed: {
    price() {
      return this.currency === 'eur' ? this.product.price : this.product.usdPrice
    },
    htPrice() {
      return this.currency === 'eur' ? this.product.htPrice : this.product.usdHtPrice
    },
    priceUnit() {
      return this.currency === 'eur' ? this.product.priceUnit : this.product.priceUnitUsd
    },
    vatIsNotZero() {
      return this.price > this.htPrice
    },
    displayCurrency() {
      return this.currency === 'eur' ? 'eur' : 'usdSimple'
    }
  }
}
</script>
<style>
div.pricing-table__inner {
  background-color: #f8fbef;
  margin: 0 1px;
  text-align: center;
  border-radius: 5%;
  height: 100%;
}

.pricing-table__inner::before {
  background-image: linear-gradient(-180deg, #dedc00 0%, #95c11f 50%, #638015 100%);
  }

  .pricing-table__title {
    color: #121212;
    font-size: 1.8rem;
    margin: 0 15%;
  }

   .pricing-table__head {
    display: flex;
    flex-wrap: wrap;
  }

  .pricing-table__head .pricing-table__title {
    flex-basis: 100%;
  }

  .pricing-table__head .pricing-table__image {
    margin-bottom: 8px;
    flex-basis: 100%;
    align-self: flex-end;
    max-width: 250px;
  }

  .pricing-table__body {
    align-self: flex-end;
  }

  .pricing-table__body p {
    color: #7e7e7e;
    margin-bottom: 0;
  }
  .pricing-table__body h3 {
    color: #95c11f;
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .hover-style-link.hover-style-mailnjoy:after {
    background-color: #95c11f;
  }
</style>
