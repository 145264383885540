import findIndex from 'lodash/findIndex'

export default {
    openDialog (state, dialogName) {
        const index = findIndex(state.dialog, { name: dialogName })
        state.dialog[index].isOpen = true
    },

    closeDialog (state, dialogName) {
        const index = findIndex(state.dialog, { name: dialogName })
        state.dialog[index].isOpen = false
    }

}
