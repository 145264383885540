export default {
  getLang (state) {
    return state.lang
  },
  entitiesPageCampaigns (state) {
    return state.entitiesPage.filter((item) => item.name === 'campaigns')[0]
  },
  entitiesPageValidateUnitary (state) {
    return state.entitiesPage.filter((item) => item.name === 'validate-unitary')[0]
  },
  entitiesPagePricing (state) {
    return state.entitiesPage.filter((item) => item.name === 'pricing')[0]
  },
  entitiesPageCredit (state) {
    return state.entitiesPage.filter((item) => item.name === 'credits-history')[0]
  },
  entitiesPageProfile (state) {
    return state.entitiesPage.filter((item) => item.name === 'profile')[0]
  },
  entitiesPageContact (state) {
    return state.entitiesPage.filter((item) => item.name === 'contact')[0]
  }
}
