<template>
  <div class="pdf-button text-center">
    <v-select
      v-if="size === 'large'"
      v-model="language"
      :items="languages"
      item-value="icon"
      item-text="lang"
      hide-details="auto"
      class="align-center justify-center"
      dense
    >
      <template v-slot:item="{ item }">
        {{ item.lang }}
        <flag
          :iso="item.icon"
          class="ml-2"
        />
      </template>
      <template v-slot:selection="{ item }">
        {{ item.lang }}
        <flag
          :iso="item.icon"
          class="ml-2"
        />
      </template>
      <template v-slot:prepend>
        <v-btn
          color="primary"
          @click="downloadPdf"
        >
          {{ $t('components.pdf-report.downloadButtonLarge') }}
          <flag :iso="language.icon" />
        </v-btn>
      </template>
    </v-select>
    <div v-else-if="size === 'small'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            class="cursor-pointer"
            color="#ED2224"
            v-on="on"
            @click="downloadPdf"
          >
            mdi-file-pdf
          </v-icon>
        </template>
        <span>
          {{ $t('components.pdf-report.downloadButtonSmall') }}
        </span>
      </v-tooltip>
    </div>
    <v-dialog
      v-model="pdfDialog"
      persistent
      max-width="700px"
    >
      <div>
        <material-card
          center
          light
        >
          <h2 class="text-center">
            {{ $t('components.pdf-report.generationInProgress') }}
            <v-progress-circular
              color="primary"
              indeterminate
              size="50"
            />
          </h2>
          <canvas
            :id="list.id + 'simpleChart'"
            width="600"
            height="300"
          />
          <canvas
            :id="list.id + 'detailedChart'"
            width="600"
            height="300"
          />
        </material-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
/* import campaignAPI from '@/api/campaigns' */
import Chart from 'chart.js'
import CampaignCharts from './CampaignsCharts'
import {jsPDF} from 'jspdf'

const campaignCharts = new CampaignCharts()

  export default {
    props: {
      size: { // style du bouton, large ou small
        type: String,
        default: ''
      },
      list: { // doit être validée et terminée
        type: Object
      }
    },
    data: function () {
      return {
        activeGeneration: false,
        simpleChart: null,
        simpleChartReady: false,
        detailedChart: null,
        detailedChartReady: false,
        bannerImageReady: false,
        pdfDialog: false,
        language: '',
        languages: [
          {
            'lang': this.$t('components.pdf-report.languages.fr'),
            'icon': 'fr'
          }, {
            'lang': this.$t('components.pdf-report.languages.uk'),
            'icon': 'gb'
          }, {
            'lang': this.$t('components.pdf-report.languages.us'),
            'icon': 'us'
          }
        ],
        languagesI18nCodes: {
          'fr': 'fr',
          'gb': 'en',
          'us': 'en'
        }
      }
    },
    computed: {
      ...mapState('user', ['settingWhiteLabelImageUrl']),
      ...mapState('campaign', ['campaignFirst']),
      locale: function () {
        return this.$i18n.locale
      }
    },
    watch: {
      locale: function () {
        this.setDefaultReportLanguage()
      }
    },
    created: function () {
      this.setDefaultReportLanguage()
      // Non traduit car non visible des utilisateurs, erreurs uniquement pour le développement
      if (this.size !== 'small' && this.size !== 'large') {
        // eslint-disable-next-line no-throw-literal
        throw 'Erreur pdf-report: valeur de la prop "size" doit être égale à "small" ou "large"'
      }
      if (this.list === undefined || this.list.id === undefined) {
        // eslint-disable-next-line no-throw-literal
        throw 'Erreur pdf-report: "list" non initialisée'
      }
      this.refreshSettingWhiteLabelImageUrl()
    },
    methods: {
      ...mapActions('user', {
        refreshSettingWhiteLabelImageUrl: 'refreshSettingWhiteLabelImageUrl'
      }),
      ...mapActions('campaign', {
        getCampaignsByListAction: 'getCampaignsByListAction',
        getCampaignStatsFirstCampaignAction: 'getCampaignStatsFirstCampaignAction'
      }),
      // bug: formatNumber, ne gére pas les espace en séparateur dans ce contexte. Fait des sorties avec /
      formatNumber(number, lang) {
        if (!number)
          number = 0
        const spacer = (lang === 'fr') ? ' ' : ','
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spacer);
      },
      downloadPdf: async function () {
        // rien si téléchargement déjà en cours
        if (this.activeGeneration) {
          return
        }

        this.activeGeneration = true
        this.pdfDialog = true // ouvrir le dialog avant de commencer le rendu

        // préparation bouton choix langue
        const lang = this.languagesI18nCodes[this.language]
        const date = this.$d(new Date(this.campaignFirst.created), {locale: lang})

        // récupérer la campagne
        await this.getCampaignsByListAction(this.list.id)
        if (!this.campaignFirst) {
          this.$toast(this.$t('components.pdf-report.errors.noCampaign'), {
            icon: 'mdi-alert-circle',
            color: 'red'
          })
          this.activeGeneration = false
          return
        }

        // récupération des statistiques de la campagne
        await this.getCampaignStatsFirstCampaignAction()
        if (!this.campaignFirst.stats || this.campaignFirst.stats.state !== 'terminee') {
          this.$toast(this.$t('components.pdf-report.errors.noCampaign'), {
            icon: 'mdi-alert-circle',
            color: 'red'
          })
          this.activeGeneration = false
          return
        }

        // créer les graphs
        const context = this
        this.simpleChart = this.getNewChart(this.list.id + 'simpleChart', campaignCharts.generateSimpleTableDataForExportPie(
          this.campaignFirst.statistics, this, lang), function () {
            context.simpleChartReady = true
          })
        this.detailsChart = this.getNewChart(this.list.id + 'detailedChart', campaignCharts.generateDetailsTableDataForExportPie(
          this.campaignFirst.statistics, this, lang), function () {
            context.detailedChartReady = true
          })

        // charger les images statiques
        /*const banner = new Image()
        banner.setAttribute('crossOrigin', 'anonymous')
        banner.onload = function () {
          context.bannerImageReady = true
        }
        if (this.settingWhiteLabelImageUrl.length > 0 && this.settingWhiteLabelImageUrl.toLowerCase().startsWith('http')) {
          banner.src = this.settingWhiteLabelImageUrl
        } else {
          banner.src = '/img/pdf_banner.png'
        }*/

        // generation du pdf
        setTimeout(function () {
          // vérifier que tout est chargé
          if (!context.simpleChartReady || !context.detailedChartReady) {
              context.$toast(context.$t('components.pdf-report.errors.internalError'), {
              icon: 'mdi-alert-circle',
              color: 'red'
            })
            context.activeGeneration = false
            return
          }

          // construire le pdf
          // eslint-disable-next-line new-cap
          const doc = new jsPDF()
          const stats = context.campaignFirst.statistics
          // Page 1
          //doc.addImage(banner, 'PNG', 5, 5, 200, 25, 'banner') // mise en cache de l'image
          doc.setFontSize(22)
          doc.setTextColor(23, 23, 23)
          doc.text(context.$t('components.pdf-report.content.title', lang, [date]), 105, 24.5, 'center')
          doc.setFontSize(12)
          doc.text(context.$t('components.pdf-report.content.subtitle', lang, [context.campaignFirst.list.name]), 10, 37)
          if (context.campaignFirst.stats.totalDuplicate > 1) {
            doc.text(context.formatNumber(context.campaignFirst.list.emailCount, lang)
                + context.$t('components.pdf-report.content.subtitleCount', lang)
                + context.formatNumber(context.campaignFirst.list.totalDuplicate, lang)
                + context.$t('components.pdf-report.content.subtitleDuplicates', lang), 10, 43)
          } else if (context.campaignFirst.stats.totalDuplicate === 1) {
            doc.text(context.formatNumber(context.campaignFirst.list.emailCount, lang)
                + context.$t('components.pdf-report.content.subtitleCount', lang)
                + context.$t('components.pdf-report.content.subtitleDuplicate', lang), 10, 43)
          } else {
            doc.text(context.formatNumber(context.campaignFirst.list.emailCount, lang)
                + context.$t('components.pdf-report.content.subtitleCount', lang), 10, 43)
          }
          doc.setFontSize(18)
          doc.text(context.$t('components.pdf-report.content.simpleTitleGraph', lang), 10, 53)
          doc.addImage(context.simpleChart.toBase64Image(), 'PNG', 10, 58, 180, 90, 'simple-chart')
          doc.text(context.$t('components.pdf-report.content.detailsTitleGraph', lang), 10, 164)
          doc.addImage(context.detailsChart.toBase64Image(), 'PNG', 10, 169, 180, 90, 'detailed-chart')
          doc.setFontSize(12)
          doc.text('1/4', 105, 290, 'center')
          // Page 2
          doc.addPage()
          //doc.addImage(banner, 'PNG', 5, 5, 200, 25, 'banner')
          doc.setFontSize(22)
          doc.setTextColor(23, 23, 23)
          doc.text(context.$t('components.pdf-report.content.title', lang, [context.$d(new Date(context.campaignFirst.created))]), 105, 24.5, 'center')
          doc.setFontSize(18)
          doc.text(context.$t('components.pdf-report.content.simpleTitleText', lang), 10, 40)
          doc.setFontSize(12)
          doc.text(context.$t('components.pdf-report.content.categories.safe', lang), 100, 47, 'right')
          doc.text(context.formatNumber(stats.simpleStat.count.Safe, lang), 110, 47)
          doc.text(context.$t('components.pdf-report.content.categories.risky', lang), 100, 52, 'right')
          doc.text(context.formatNumber(stats.simpleStat.count.Risky, lang), 110, 52)
          doc.text(context.$t('components.pdf-report.content.categories.unsafe', lang), 100, 57, 'right')
          doc.text(context.formatNumber(stats.simpleStat.count.Unsafe, lang), 110, 57)
          doc.text(context.$t('components.pdf-report.content.categories.duplicate', lang), 100, 62, 'right')
          doc.text(context.formatNumber(context.campaignFirst.stats.totalDuplicate, lang), 110, 62)
          doc.text(context.$t('components.pdf-report.content.categories.unverified', lang), 100, 67, 'right')
          doc.text(context.formatNumber(context.campaignFirst.stats.totalUnverified, lang), 110, 67)
          doc.setLineWidth(0.5)
          doc.setLineDash([3, 2], 0)
          doc.line(105, 44, 105, 68)
          doc.setFontSize(18)
          doc.text(context.$t('components.pdf-report.content.detailsTitleText', lang), 10, 80)
          doc.setFontSize(12)
          doc.text(context.$t('components.pdf-report.content.categories.valid', lang), 100, 87, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Valid, lang), 110, 87)
          doc.text(context.$t('components.pdf-report.content.categories.validUnfaith', lang), 100, 92, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.ValidUnfaith, lang), 110, 92)
          doc.text(context.$t('components.pdf-report.content.categories.full', lang), 100, 97, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Full, lang), 110, 97)
          doc.text(context.$t('components.pdf-report.content.categories.disposable', lang), 100, 102, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Disposable, lang), 110, 102)
          doc.text(context.$t('components.pdf-report.content.categories.catchall', lang), 100, 107, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Catchall, lang), 110, 107)
          doc.text(context.$t('components.pdf-report.content.categories.catchallUnfaith', lang), 100, 112, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.CatchallUnfaith, lang), 110, 112)
          doc.text(context.$t('components.pdf-report.content.categories.autogenerated', lang), 100, 117, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Autogenerated, lang), 110, 117)
          doc.text(context.$t('components.pdf-report.content.categories.protected', lang), 100, 122, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Protected, lang), 110, 122)
          doc.text(context.$t('components.pdf-report.content.categories.role', lang), 100, 127, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Role, lang), 110, 127)
          doc.text(context.$t('components.pdf-report.content.categories.suspect', lang), 100, 132, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Suspect, lang), 110, 132)
          doc.text(context.$t('components.pdf-report.content.categories.incorrect', lang), 100, 137, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Incorrect, lang), 110, 137)
          doc.text(context.$t('components.pdf-report.content.categories.invalid', lang), 100, 142, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Invalid, lang), 110, 142)
          doc.text(context.$t('components.pdf-report.content.categories.spamtrap', lang), 100, 147, 'right')
          doc.text(context.formatNumber(stats.deepStat.count.Spamtrap, lang), 110, 147)
          doc.text(context.$t('components.pdf-report.content.categories.duplicate', lang), 100, 152, 'right')
          doc.text(context.formatNumber(context.campaignFirst.stats.totalDuplicate, lang), 110, 152)
          doc.text(context.$t('components.pdf-report.content.categories.unverified', lang), 100, 157, 'right')
          doc.text(context.formatNumber(context.campaignFirst.stats.totalUnverified, lang), 110, 157)
          doc.line(105, 84, 105, 158)
          doc.text('2/4', 105, 290, 'center')

          // Page 3
          doc.addPage()
          //doc.addImage(banner, 'PNG', 5, 5, 200, 25, 'banner')
          doc.setFontSize(22)
          doc.setTextColor(23, 23, 23)
          doc.text(context.$t('components.pdf-report.content.explanations.title', lang), 105, 24.5, 'center')
          doc.setFontSize(16)
          doc.text(context.$t('components.pdf-report.content.explanations.safeTitle', lang), 10, 40)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.safeBody', lang), 190), 10, 47)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.roleTitle', lang), 185), 15, 59)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.roleBody', lang), 185), 15, 64)
          doc.setFontSize(16)
          doc.text(context.$t('components.pdf-report.content.explanations.riskyTitle', lang), 10, 94)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.riskyBody', lang), 190), 10, 101)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.validUnfaithTitle', lang), 185), 15, 143)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.validUnfaithBody', lang), 185), 15, 149)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.fullTitle', lang), 185), 15, 162)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.fullBody', lang), 185), 15, 168)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.disposableTitle', lang), 185), 15, 195)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.disposableBody', lang), 185), 15, 201)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.catchallTitle', lang), 185), 15, 237)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.catchallBody', lang), 185), 15, 243)
          doc.setFontSize(12)
          doc.text('3/4', 105, 290, 'center')

          // Page 4
          doc.addPage()
          //doc.addImage(banner, 'PNG', 5, 5, 200, 25, 'banner')
          doc.setFontSize(22)
          doc.setTextColor(23, 23, 23)
          doc.text(context.$t('components.pdf-report.content.explanations.title', lang), 105, 24.5, 'center')
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.catchallUnfaithTitle', lang), 185), 15, 40)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.catchallUnfaithBody', lang), 185), 15, 46)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.suspectTitle', lang), 185), 15, 67)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.suspectBody', lang), 185), 15, 73)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.autogeneratedTitle', lang), 185), 15, 94)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.autogeneratedBody', lang), 185), 15, 100)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.protectedTitle', lang), 185), 15, 116)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.protectedBody', lang), 185), 15, 122)
          doc.setFontSize(16)
          doc.text(context.$t('components.pdf-report.content.explanations.unsafeTitle', lang), 10, 145)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.unsafeBody', lang), 190), 10, 151)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.incorrectTitle', lang), 185), 15, 173)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.incorrectBody', lang), 185), 15, 179)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.invalidTitle', lang), 185), 15, 191)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.invalidBody', lang), 185), 15, 197)
          doc.setFontSize(14)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.spamtrapTitle', lang), 185), 15, 209)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.spamtrapBody', lang), 185), 15, 215)
          doc.setFontSize(16)
          doc.text(context.$t('components.pdf-report.content.explanations.duplicateTitle', lang), 10, 236)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.duplicateBody', lang), 190), 10, 241)
          doc.setFontSize(16)
          doc.text(context.$t('components.pdf-report.content.explanations.unverifiedTitle', lang), 10, 263)
          doc.setFontSize(12)
          doc.text(doc.splitTextToSize(context.$t('components.pdf-report.content.explanations.unverifiedBody', lang), 190), 10, 269)
          doc.text('4/4', 105, 290, 'center')

          // finalisation
          doc.save(context.campaignFirst.list.name + '-' + context.$t('components.pdf-report.filename', lang) + '.pdf')
          context.pdfDialog = false
          context.activeGeneration = false
        }, 2000)
      },
      getNewChart: function (elementId, data, onCompleteCallback) {
        return new Chart(document.getElementById(elementId), {
          type: 'doughnut',
          data: data,
          options: {
            responsive: false,
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }
            },
            animation: {
              onComplete: onCompleteCallback(), // https://stackoverflow.com/questions/20206038/converting-chart-js-canvas-chart-to-image-using-todataurl-results-in-blank-im
              duration: 0
            },
            legend: {
              position: 'right',
              align: 'center',
              fullWidth: true
            },
            hover: {
              animationDuration: 0
            },
            responsiveAnimationDuration: 0
          }
        })
      },
      setDefaultReportLanguage: function() {
        // init langage
        if (this.locale === 'fr') {
          this.language = this.languages[0].icon
        } else {
          this.language = this.languages[2].icon
        }
      }
    }
  }
</script>
<style type="text/css">
  .cursor-pointer {
    cursor: pointer;
  }
  .pdf-button div.v-input__control {
    align-items: center;
    max-width: 70px;
  }
  .pdf-button div.v-input {
    margin: auto;
  }
</style>
