import campaignUtils from '@/utils/campaign'
import colorUtils from '@/utils/color'

export default class CampaignCurveCharts {
    generateCurveDeep (statistics, _vueApp) {
        const dates = statistics.date.map((date) => _vueApp.$d(date))
        return {
            datasets: [
            {
                label: _vueApp.$t('views.campaigns.totalValid.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Valid,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Valid),
                data: statistics.deepStat.percent.Valid,
                count: statistics.deepStat.count.Valid
            }, {
                label: _vueApp.$t('views.campaigns.totalValidUnfaith.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.ValidUnfaith,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.ValidUnfaith),
                data: statistics.deepStat.percent.ValidUnfaith,
                count: statistics.deepStat.count.ValidUnfaith
            }, {
                label: _vueApp.$t('views.campaigns.totalInboxFull.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.InboxFull,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.InboxFull),
                data: statistics.deepStat.percent.Full,
                count: statistics.deepStat.count.Full
            }, {
                label: _vueApp.$t('views.campaigns.totalDisposable.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Disposable,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Disposable),
                data: statistics.deepStat.percent.Disposable,
                count: statistics.deepStat.count.Disposable
            }, {
                label: _vueApp.$t('views.campaigns.totalCatchall.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Catchall,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Catchall),
                data: statistics.deepStat.percent.Catchall,
                count: statistics.deepStat.count.Catchall
            }, {
                label: _vueApp.$t('views.campaigns.totalCatchallUnfaith.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.CatchallUnfaith,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.CatchallUnfaith),
                data: statistics.deepStat.percent.CatchallUnfaith,
                count: statistics.deepStat.count.CatchallUnfaith
            }, {
                label: _vueApp.$t('views.campaigns.totalAutogenerated.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Autogenerated,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Autogenerated),
                data: statistics.deepStat.percent.Autogenerated,
                count: statistics.deepStat.count.Autogenerated
            }, {
                label: _vueApp.$t('views.campaigns.totalProtected.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Protected,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Protected),
                data: statistics.deepStat.percent.Protected,
                count: statistics.deepStat.count.Protected
            }, {
                label: _vueApp.$t('views.campaigns.totalRole.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Role,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Role),
                data: statistics.deepStat.percent.Role,
                count: statistics.deepStat.count.Role
            }, {
                label: _vueApp.$t('views.campaigns.totalSuspect.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Suspect,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Suspect),
                data: statistics.deepStat.percent.Suspect,
                count: statistics.deepStat.count.Suspect
            }, {
                label: _vueApp.$t('views.campaigns.totalIncorrect.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Incorrect,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Incorrect),
                data: statistics.deepStat.percent.Incorrect,
                count: statistics.deepStat.count.Incorrect
            }, {
                label: _vueApp.$t('views.campaigns.totalInvalid.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Invalid,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Invalid),
                data: statistics.deepStat.percent.Invalid,
                count: statistics.deepStat.count.Invalid
            }, {
                label: _vueApp.$t('views.campaigns.totalSpamtrap.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Spamtrap,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Spamtrap),
                data: statistics.deepStat.percent.Spamtrap,
                count: statistics.deepStat.count.Spamtrap
            }],
            labels: dates
        }
    }

    generateCurveSimple (statistics, _vueApp) {
        const dates = statistics.date.map((date) => _vueApp.$d(date))
        return {
            datasets: [
              {
                label: _vueApp.$t('views.campaigns.totalSafe.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Safe,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Safe),
                data: statistics.simpleStat.percent.Safe,
                count: statistics.simpleStat.count.Safe
              }, {
                label: _vueApp.$t('views.campaigns.totalRisky.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Risky,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Risky),
                data: statistics.simpleStat.percent.Risky,
                count: statistics.simpleStat.count.Risky
              }, {
                label: _vueApp.$t('views.campaigns.totalUnsafe.label'),
                borderWidth: 5,
                borderColor: campaignUtils.colorSheme.Unsafe,
                backgroundColor: colorUtils.pSBC(0.10, campaignUtils.colorSheme.Unsafe),
                data: statistics.simpleStat.percent.Unsafe,
                count: statistics.simpleStat.count.Unsafe
              }
            ],
            labels: dates
        }
    }
};
