import credits from '@/api/orders'

export default {
  refreshOrders ({ commit, state, rootState }, language) {
    if (rootState.user.accessToken && rootState.user.userInfo) {
      credits.getOrders(rootState.user.accessToken, rootState.user.userInfo.id, language)
        .then(orders => {
          if (orders) {
            commit('setOrders', orders)
          }
        })
        .catch(error => {
          console.error('order/actions.js/refreshOrders: ' + error)
        })
    } else {
      console.warn('Les transaction n\'ont pas les crédentials pour se lancer')
    }
  }
}
