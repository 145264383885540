// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
// Validation forms
import VeeValidate, { Validator } from 'vee-validate'
import ValidateLocaleFR from 'vee-validate/dist/locale/fr'
import ValidateLocaleEN from 'vee-validate/dist/locale/en'
import { VuePlausible } from 'vue-plausible'
// Gestion des cookies
import VueCookie from 'vue-cookie'
// Tuto
import VueTour from 'vue-tour'
// issue : https://github.com/eolant/vuetify-toast-snackbar/issues/42
import Vuetify, { VSnackbar } from 'vuetify/lib'
// notification https://github.com/eolant/vuetify-toast-snackbar
import VuetifyToast from 'vuetify-toast-snackbar'
// flag
import FlagIcon from 'vue-flag-icon'
// mdi
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
// Components
import './components'
// Plugins
import './plugins'
import vuetify from '@/plugins/vuetify'
// axios
import axios from './plugins/axios'
// Sync router with store
import { sync } from 'vuex-router-sync'
// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
// Filter
import vueNumeralFilterInstaller from 'vue-numeral-filter'

Vue.prototype.$http = axios

// Sync store with router
sync(store, router)

Vue.config.productionTip = false

Vue.use(VeeValidate)

Vue.use(VuePlausible, {
  domain: process.env.VUE_APP_Plausible_Data_Domain,
  trackLocalhost: true,
  apiHost: 'https://webstats.ownmailist.net',
  enableAutoPageviews: true
})

switch (i18n.locale) {
  case 'fr':
    Validator.localize('fr', ValidateLocaleFR)
    break
  default:
    Validator.localize('en', ValidateLocaleEN)
    break
}
Vue.use(VueCookie)

Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

Vue.use(mdiVue, {
    icons: mdijs
})

// START issue https://github.com/eolant/vuetify-toast-snackbar/issues/42
Vue.use(Vuetify, {
    components: {
        VSnackbar
    }
})
const opts = {} // your options

const vueObj = new Vuetify(opts)

export default vueObj

Vue.use(VuetifyToast, { $vuetify: vueObj.framework })
// END issue https://github.com/eolant/vuetify-toast-snackbar/issues/42

Vue.use(FlagIcon)
// filter
Vue.use(vueNumeralFilterInstaller)
/* eslint-disable no-new */
window.vueApp = new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
