import ApiValidateUnitary from '@/api/validate-unitary'

export default {
  async fetchEmailsValided ({ commit, state, rootState }) {
    if (rootState.user.accessToken) {
      try {
        const emailsChecked = await ApiValidateUnitary.getLimitedValidateUnitaries(rootState.user.accessToken)
        commit('setEmailsChecked', state.emailsBeingValidated.concat(emailsChecked))
      } catch (error) {
        console.error('validateUnitary/actions.js/fetchEmailsValided: ' + error)
      }
    } else {
      console.warn('problème de token')
    }
  },
  async fetchProductValidationUnitary ({ commit, state, rootState }) {
    if (rootState.user.accessToken) {
      try {
        const productValidationUnitary = await ApiValidateUnitary.getDetailType(rootState.user.accessToken)
        commit('setProductValidationUnitary', productValidationUnitary)
      } catch (error) {
        console.error('validateUnitary/actions.js/fetchProductValidationUnitary: ' + error)
      }
    } else {
      console.warn('problème le token')
    }
  },
  async fetchWeekStatistics ({ commit, state, rootState }, numberOfWeeks) {
    if (rootState.user.accessToken) {
      try {
        const weekStatistics = await ApiValidateUnitary.getWeeksStatistics(rootState.user.accessToken, numberOfWeeks)
        commit('setWeekStatistics', weekStatistics)
      } catch (error) {
        console.error('validateUnitary/actions.js/fetchWeekStatistics: ' + error)
      }
    } else {
      console.warn('problème le token')
    }
  },
  addEmailIntoEmailsChecked ({ commit }, email) {
    commit('addEmailIntoEmailsChecked', email)
  },
  replaceEmailIntoEmailsChecked ({ commit }, email) {
    commit('replaceEmailIntoEmailsChecked', email)
  },
  removeEmailFromEmailsChecked ({ commit }, key) {
    commit('removeEmailFromEmailsChecked', key)
  }
}
