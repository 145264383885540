export default {
  colorSheme: {
    Safe: 'rgb(105,233,52)',
    Risky: 'rgb(255,149,0)',
    Unsafe: 'rgb(255,37,42)',
    Valid: 'rgb(105,233,52)',
    ValidUnfaith: 'rgb(158,255,118)',
    InboxFull: 'rgb(118,255,132)',
    Disposable: 'rgb(28,68,255)',
    Catchall: 'rgb(61,164,255)',
    CatchallUnfaith: 'rgb(157,209,255)',
    Role: 'rgb(255,253,124)',
    Suspect: 'rgb(255,192,37)',
    Incorrect: 'rgb(245,127,33)',
    Invalid: 'rgb(235,98,92)',
    Spamtrap: 'rgb(235,4,0)',
    Unverified: 'rgb(60,60,60)',
    Autogenerated: 'rgb(177,177,177)',
    Protected: 'rgb(139,0,139)',
    Duplicate: 'rgb(105,233,52)'
  }
}
