import axios from '@/plugins/axios'

export default {
    async getLists (accessTokenHeader, userId) {
        const url = process.env.VUE_APP_List_Url + '?userId=' + userId
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.get(url, { headers: headers })
            .then(response => {
                return response.data.lists
            })
    },

    async getListFromId (accessTokenHeader, listId) {
        const url = process.env.VUE_APP_List_Url + listId
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.get(url, { headers: headers })
            .then(response => {
                return response.data
            })
    },

    createEmtpyList (accessTokenHeader, name, description) {
        const url = process.env.VUE_APP_List_Url + '?empty=true&acceptTerms=true'
        const data = {
            name: name,
            description: description
        }

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }
        // eslint-disable-next-line no-undef
        return axios.post(url, data, { headers: headers })
            .then(response => {
                return response.data
            })
    },

    finishListCreation (accessTokenHeader, listId, fileExtension) {
      const url = process.env.VUE_APP_List_Url + listId
        const data = fileExtension

        const headers = {
          'Content-Type': 'text/plain;charset=UTF-8',
          Authorization: accessTokenHeader
        }
        // eslint-disable-next-line no-undef
        return axios.put(url, data, { headers: headers })
          .then(response => {
            return response.data
        })
    },

    deleteList (accessTokenHeader, listId) {
        const url = process.env.VUE_APP_List_Url + listId + '/'

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }
        // eslint-disable-next-line no-undef
        return axios.delete(url, { headers: headers })
            .then(response => {
                return response.data
            })
    },

    archiveList (accessTokenHeader, listId) {
        const url = process.env.VUE_APP_List_Url + listId + '/mode=archive'

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }
        // eslint-disable-next-line no-undef
        return axios.delete(url, { headers: headers })
            .then(response => {
                return response.data
            })
    },

    async getLatest (accessTokenHeader) {
        const url = process.env.VUE_APP_List_Url + 'latest/'
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }
        // eslint-disable-next-line no-undef
        return axios.get(url, { headers: headers })
            .then(response => response.data)
            .catch(() => null)
    }
}
