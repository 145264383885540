import axios from '@/plugins/axios'
import productAPI from './validate-products.js'

export default {
  createTransaction(products, accessTokenHeader, lang, currency, method) {
    const url = process.env.VUE_APP_Order_Url + 'v2?lang=' + lang + '&currency=' + currency + '&method=' + method

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios
        .post(url, products, {headers: headers})
        .then(response => {
          return response.data.id
      })
      .catch(error => {
        // @todo: handle error
        console.error('API order - createTransaction : ' + error)
      })
  },
  getOrders (accessTokenHeader, userId, language) {
    if (typeof language === 'undefined') {
      language = 'fr'
    }
    const url = process.env.VUE_APP_Order_Url + userId + '/__validate?language=' + language

    const headers = {
      Authorization: accessTokenHeader
    }

    let transactions = null
    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        transactions = response.data.transactions
        // récupérer la liste des produits
        return productAPI.getFullProductList(accessTokenHeader).then(result => {
          // augmenter transactions en ajoutant le produit lié à chaque transaction
          if (transactions) {
            transactions.forEach(transaction => {
              transaction.product = result.find(product => product.productKey === transaction.productKey)
            })
            return transactions
          }

          return null
        })
      })
  }
}
