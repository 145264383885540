import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

// Pour le moment j'en fais simplement une constante
// todo: réfléchir à comment l'implémenter sur le long terme : service ? Dans chacun des produits ?
const TVA = 20

function roundDecimal (number, _precision) {
  const precision = _precision || 2
  const factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

export default {
  setProducts (state, { products, vat }) {
    // trie des produits par credits puis par prix
    const productsSorted = sortBy(
      products,
      [
        function (array) {
          return parseFloat(array.credits)
        }
      ],
      function (array) {
        return parseFloat(array.price)
      }
    )

    // création de groupes par rapport au nombre de crédit
    const productsSortedAndGroup = groupBy(productsSorted, function (array) {
      return array.credits
    })

    // on peut maintenant boucler sur le tableau pour sélectionner
    // le premier item (le moins chère de sa catégorie)
    // ET calculer la remise par rapport au produit le plus chère (le dernier de sa catégorie)
    const productForPricing = []
    const productsSortedAndGroupKey = Object.keys(productsSortedAndGroup)
    const productsSortedAndGroupLen = productsSortedAndGroupKey.length

    for (let index = 0; index < productsSortedAndGroupLen; index++) {
      const cheapestProduct =
        productsSortedAndGroup[productsSortedAndGroupKey[index]][0]

      // On verifie que le produit soit pour plus de 1 crédit
      if (cheapestProduct.credits <= 1) {
        continue
      }

      const mostExpensiveProduct =
        productsSortedAndGroup[productsSortedAndGroupKey[index]][productsSortedAndGroup[productsSortedAndGroupKey[index]].length - 1]
      const cheapestPrice = parseFloat(cheapestProduct.price)
      const mostExpensivePrice = parseFloat(mostExpensiveProduct.price)

      const discountInPercent =
          Math.floor(((cheapestPrice - mostExpensivePrice) / mostExpensivePrice) * -100)

      // on insert la donné dans l'object produit pour pouvoir l'afficher correctement
      // Si la remise est intéressante
      if (discountInPercent > 0) {
        cheapestProduct.discount = `-${discountInPercent}%`
      }

      // On insert le prix TTC, HT et unitaires avec les arrondis qui conviennent
      cheapestProduct.priceTTC = roundDecimal(cheapestPrice)
      cheapestProduct.priceHT = roundDecimal(parseFloat(cheapestProduct.htPrice))
      // le prix est exprimé en centimes
      cheapestProduct.priceUnit = roundDecimal(cheapestProduct.priceHT / cheapestProduct.credits * 100)
        cheapestProduct.priceUnitUsd = roundDecimal(cheapestProduct.usdHtPrice / cheapestProduct.credits * 100)

      // on pousse le prix le plus cher pour référence
      cheapestProduct.mostExpensiveProductPrice = roundDecimal(parseFloat(mostExpensiveProduct.htPrice))

      // On insert le produit avec le plus petit prix dans le tableau final qui servira le FRONT
      productForPricing.push(cheapestProduct)
    }

    state.products = productForPricing
    // console.table(productForPricing);
  }
}
