<template>
  <form
    class="valdiate_unitary_form"
    @submit.prevent="onSubmit"
  >
    <v-text-field
      :value="emailModel"
      class="email_input"
      :rules="emailValidator"
      :label="$t('views.validateUnitary.form.mailLabel') + ' *'"
      required
      @input="onInput($event)"
    />
    <v-card-actions class="send_button">
      <v-btn
        color="primary"
        data-v-tour-unitary-step="5"
        @click="onSubmit"
      >
        <span class="label">{{ $t('views.validateUnitary.form.inputValidation') }}</span>
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-card-actions>
  </form>
</template>

<script>
export default {
  props: {
    emailModel: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    emailRegex: new RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}')
  }),
  computed: {
    emailValidator () {
      return [
        value => {
          if (value === '') return ''
          if (value.split('@').length > 2) return this.$t('views.validateUnitary.errorMultipleMails')
          return this.emailRegex.test(value) || this.$t('global.emailError')
        }
      ]
    }
  },
  methods: {
    onInput: function (value) {
      this.$emit('update:emailModel', value)
    },
    onSubmit: function () {
      if (this.emailModel.split('@').length == 2 && this.emailRegex.test(this.emailModel)) {
          this.$emit('submitEmail', this.emailModel)
      }
    }
  }
}
</script>
