export default {
  primary: '#95C11F',
  secondary: '#DEDC00',
  tertiary: '#495057',
  accent: '#82B1FF',
  error: '#f55a4e',
  info: '#00d3ee',
  success: '#95C11F',
  warning: '#ffa21a'
}
