<template>
  <div v-if="predictive === null">
    <v-progress-linear
      indeterminate
      color="primary"
      height="25"
    />
    <h5>{{ $t('views.campaigns.predictive.inProgress') }}</h5>
  </div>
  <div v-else-if="['abandonnee','erreur','annulee'].includes(predictive.status)">
    <h5>{{ $t('views.campaigns.predictive.error') }}</h5>
  </div>
  <div v-else-if="predictive.status !== 'terminee'">
    <v-progress-linear
      indeterminate
      color="primary"
      height="25"
    />
    <h5>{{ $t('views.campaigns.predictive.inProgress') }}</h5>
  </div>
  <div v-else>
    <h3>{{ $t('views.campaigns.predictive.finished') }}</h3>
    <div class="text-center">
      <h4
        :class="{ 'bounce-percentage': true,
                  'rating-unknown': predictive.bouncePercentage == -1,
                  'rating-good': (predictive.bouncePercentage >= 0 && predictive.bouncePercentage < 4),
                  'rating-bad': (predictive.bouncePercentage >= 4 && predictive.bouncePercentage < 10),
                  'rating-terrible': predictive.bouncePercentage >= 10 }"
      >
        <span v-if="predictive.bouncePercentage == -1">
          {{ $t('views.campaigns.predictive.bouncePercentageUnkown') }}
        </span>
        <span v-else>
          {{ $t('views.campaigns.predictive.bouncePercentage', [ predictive.bouncePercentage ]) }}
        </span>
      </h4>
      <div :class="{ 'rating-good': !predictive.spamtrapPresence, 'rating-terrible': predictive.spamtrapPresence }">
        {{ $t('views.campaigns.predictive.spamtrap.' + predictive.spamtrapPresence) }}
      </div>
      <div :class="{ 'rating-good': !predictive.disposablePresence, 'rating-bad': predictive.disposablePresence }">
        {{ $t('views.campaigns.predictive.disposable.' + predictive.disposablePresence) }}
      </div>
      <div :class="{ 'rating-good': !predictive.rolePresence, 'rating-bad': predictive.rolePresence }">
        {{ $t('views.campaigns.predictive.role.' + predictive.rolePresence) }}
      </div>
      <div :class="{ 'rating-good': !predictive.autogeneratedPresence, 'rating-bad': predictive.autogeneratedPresence }">
        {{ $t('views.campaigns.predictive.autogenerated.' + predictive.autogeneratedPresence) }}
      </div>
    </div>
    <h3 :class="'font-weight-black conclusion-' + conclusionCategory">
      {{ $t('views.campaigns.predictive.conclusion.' + conclusionCategory) }}
    </h3>
    <div class="text--disabled">
      <small>
        {{ $t('views.campaigns.predictive.reminder') }}
      </small>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      predictive: {
        type: Object,
        default: null
      }
    },
    computed: {
      conclusionCategory: function () {
        /* not necessary < 4, no spam, no disp no role no autogenerated
        usefull < 4, disp / role / spam / autogenerated
        recommended 4/10
        must do > 10 */
        if (this.predictive.bouncePercentage == -1) {
          return 'unknown'
        } else if (this.predictive.bouncePercentage >= 10) {
          return 'must'
        } else if (this.predictive.bouncePercentage >= 4) {
          return 'recommended'
        } else if (this.predictive.disposablePresence || this.predictive.rolePresence || this.predictive.spamtrapPresence || this.predictive.autogeneratedPresence) {
          return 'useful'
        } else {
          return 'optional'
        }
      }
    }
  }
</script>

<style type="text/css">
  .bounce-percentage {
    margin-bottom: 0;
  }
  .rating-unknown, .conclusion-unknown {
    color: #3C3C3B;
  },
  .conclusion-unknown {
    font-size: 16px;
  }
  .rating-good, .conclusion-optional {
    color: #95c11f;
  }
  .rating-bad, .conclusion-recommended {
    color: orange;
  }
  .rating-terrible, .conclusion-must {
    color:  #8B0000;
  }
  .conclusion-useful {
    color: #DEDC00;
  }
</style>
