<template>
  <v-btn
    block
    color="primary"
    rounded
    data-v-tour-campaigns-step="2"
    @click="setCreateCampaignModal(true)"
  >
    <v-icon class="text-xs-right">
      mdi-plus
    </v-icon>
    <span>{{ $t('views.campaigns.newList') }}</span>

    <!-- modal pour inscrire une nouvelle liste d'email -->
    <v-dialog
      v-model="createCampaignModal"
      class="component-list-create-form-dialog"
      max-width="800"
      @click:outside="setCreateCampaignModal(false)"
    >
      <v-form
        ref="form"
        @submit.prevent="onSubmit"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('views.campaigns.createListHeadline') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col :cols="12">
                  <v-text-field
                    v-model="form.title"
                    :rules="rulesTitleRequired"
                    :label="$t('views.campaigns.createListName') + '*'"
                    :counter="100"
                    class="message-input"
                    required
                    data-v-tour-campaigns-step="3"
                  />
                  <span
                    v-if="errors.first('listName')"
                    class="text-danger"
                  >
                    {{ $t('views.campaigns.createListNameErrorMessage') }}
                  </span>
                </v-col>
                <v-col :cols="12">
                  <v-textarea
                    v-model="form.description"
                    :label="$t('views.campaigns.createListDesc')"
                    filled
                  />
                </v-col>
                <v-col :cols="12">
                  <vue-dropzone
                    id="dropzone"
                    ref="listDropzone"
                    :options="dropzoneOptions"
                    :use-custom-slot="true"
                    data-v-tour-campaigns-step="4"
                    @vdropzone-file-added="dropzoneFileAdded"
                    @vdropzone-removed-file="dropzoneFileRemoved"
                    @vdropzone-error="dropzoneError"
                  >
                    <div>{{ $t('views.campaigns.createDropzoneTitle') }}</div>
                    <div>{{ $t('views.campaigns.createDropzoneSubtitle') }}</div>
                  </vue-dropzone>
                  <span
                    v-if="isDropzoneErrorFile"
                    class="text-danger"
                  >
                    {{ $t("global.form.fileError") }}
                  </span>
                  <span
                    v-if="isDropzoneErrorLength"
                    class="text-danger"
                  >
                    {{ $t("global.form.fileErrorLength") }}
                  </span>
                  <span
                    v-if="isDropzoneErrorEmpty"
                    class="text-danger"
                  >
                    {{ $t("views.campaigns.createListUploadWrongExtension") }}
                  </span>
                  <v-text-field
                    v-model="form.dropzone"
                    :rules="rulesCheckBoxRequired"
                    style="display: none"
                    type="hidden"
                    required
                  />
                  <small>{{ $t("global.form.starMeansMandatory") }}</small>
                </v-col>
                <v-col
                  :cols="12"
                  data-v-tour-campaigns-step="5"
                >
                  <v-checkbox
                    v-model="form.isCgu"
                    :rules="rulesCheckBoxRequired"
                    :label="$t('views.campaigns.createListe.cgu') + ' *'"
                    color="primary"
                    required
                  />
                  <a :href="cguLink" class="small-text" target="_blank">
                    {{ $t('views.campaigns.createListe.linkToCgu') }}
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="warning"
              text
              @click="setCreateCampaignModal(false)"
            >
              {{ $t('views.campaigns.closeCreationModal') }}
            </v-btn>
            <v-spacer />
            <div
              v-if="canStartCreationAction"
              id="createActions"
            >
              <v-btn
                color="primary"
                outlined
                @click="submit"
              >
                {{ $t('views.campaigns.createListModalBtn') }}
              </v-btn>
              <v-tooltip
                color="#fff"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    class="mr-2"
                    v-on="on"
                    @click="submitAndAnalyze"
                  >
                    {{ $t('views.campaigns.createListAndAnalyzeModalBtn') }}
                  </v-btn>
                </template>
                {{ $t('views.campaigns.validation.whatIsPredictive') }}
              </v-tooltip>
              <v-btn
                color="primary"
                @click="authorizeValidate"
              >
                {{ $t('views.campaigns.createListAndValidateModalBtn') }}
              </v-btn>
            </div>
            <v-progress-circular
              v-else
              color="primary"
              indeterminate
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- modal affiché durant upload d'une liste -->
    <v-dialog
      v-model="uploadListDialog"
      max-width="400"
      persistent
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ $t('views.campaigns.waitCreation') }}
          <v-progress-linear
            v-model="emailsUpload.percentage"
            class="mb-0"
            color="white"
          />
          {{ emailsUpload.uploaded }} / {{ emailsUpload.toUpload }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <!---->
    <campaigns-validation-launcher-confirmation-dialog
      :list="generatedList"
      :validation-launcher-confirmation-dialog="confirmationDialogModel"
      @close="resetConfirmationDialog()"
      @userCanceled="resetConfirmationDialog()"
      @userConfirmed="uploadAndValidate"
    />
  </v-btn>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {mapActions, mapMutations, mapState} from 'vuex'
import utils from '@/utils/email'
import listAPI from '@/api/lists'
import emailAPI from '@/api/emails'

export default {
    components: {
      vueDropzone: vue2Dropzone
    },
    data () {
      return {
        MAX_NUMBER_MAILS_PER_LIST_AUTHORIZED: 2000000,
        // Formulaire
        form: Object.assign({}, Object.freeze({
          title: '',
          description: '',
          isCgu: false,
          dropzone: null
        })),

        // champs drop file spécifique
        fileCount: 0,
        fileExtension: '',
        isDropzoneErrorFile: false,
        isDropzoneErrorLength: false,
        isDropzoneErrorEmpty: false,
        dropzoneOptions: {
          url: '/no_url',
          acceptedFiles: '.txt,.csv',
          addRemoveLinks: true,
          accept: (_) => {
            // /!\ TRICKS /!\
            // permet simplement d'interférer avec l'upload du fichier sur une url et ainsi pouvoir le lire
            // simplement dans le JS
          }
        },

        // Upload d'une liste d'email
        uploadListDialog: false,
        emailsUpload: {
          uploaded: 0,
          toUpload: 0,
          percentage: 0,
          emailsPerUpload: 1000
        },

        canStartCreationAction: true,

        // planification auto-lancement
        launchAnalysis: false,
        launchValidation: false,
        confirmationDialogModel: false,
        emails: [],
        generatedList: {}
      }
    },
    computed: {
      ...mapState('user', ['accessToken']),
      ...mapState('campaign', ['createCampaignModal']),
      rulesTitleRequired() {
        return [
          val => !!val || this.$t('global.form.required')
        ]
      },
      rulesCheckBoxRequired() {
        return [
          val => !!val || this.$t('global.form.required')
        ]
      },
      cguLink() {
        return `${process.env.VUE_APP_Landing_Page_Url}${this.$i18n.locale}/CGU`
      }
    },
    watch: {
      createCampaignModal (isOpen) {
        if (isOpen) {
          this.goToTopDialog()
          // reset des valeurs à chaque ouverture
          this.launchValidation = false
          this.emails = []
        }
      }
    },
    created () {
      if (this.$route.query.from === 'buttonCreateFirstList') {
        this.setCreateCampaignModal(true)
      } else {
        this.setCreateCampaignModal(false)
      }
    },
    methods: {
      ...mapMutations('campaign', ['setCreateCampaignModal']),
      ...mapActions('dialog', ['openDialog']),
      dropzoneFileRemoved () {
        this.fileCount--
        // pas de reset de l'extension car fichier supprimer avant upload
        // this.fileExtension = ""
        if (this.fileCount === 0) this.form.dropzone = null
      },
      dropzoneFileAdded (file) {
        this.canStartCreationAction = false
        if (this.fileCount > 0) {
          this.$toast(this.$t('global.form.fileErrorLength'), {
            icon: 'mdi-alert-circle',
            color: 'red'
          })
          this.fileCount++
          this.$refs.listDropzone.removeFile(file)
          this.isDropzoneErrorLength = true
          return
        }

        this.isDropzoneErrorLength = false

        // lire extension pour finalisation
        this.fileExtension = file.name.split('.').pop()

        // csv ou txt, surcouche au acceptedFiles, qui fail sur certain navigateurs apparement...
        if (!['csv', 'txt'].includes(this.fileExtension.toLowerCase())) {
          this.$toast(this.$t('views.campaigns.createListUploadWrongExtension'), {
            icon: 'mdi-alert-circle',
            color: 'red'
          })
          this.$refs.listDropzone.removeFile(file)
          this.canStartCreationAction = true
          return
        }

        this.fileCount++
        const reader = new FileReader()
        reader.addEventListener('loadend', (event) => {
          // vérifier nombre de lignes
          const nbLines = (String(event.target.result).match(/\n/g) || '').length + 1
          if (nbLines > this.MAX_NUMBER_MAILS_PER_LIST_AUTHORIZED) {
            this.$toast(this.$t('views.campaigns.createListTooManyEmails'), {
              icon: 'mdi-alert-circle',
              timeout: -1,
              showClose: true,
              closeIcon: 'mdi-close',
              color: 'red'
            })
            this.$refs.listDropzone.removeFile(file)
            this.canStartCreationAction = true
          } else {
            this.canStartCreationAction = true
            this.isDropzoneErrorFile = false
            this.isDropzoneErrorLength = false
            this.isDropzoneErrorEmpty = false
            this.form.dropzone = event.target.result
          }
        })
        reader.readAsText(file)
      },
      dropzoneError (file, message) {
        console.error('ERROR', file, ' message =>', message)
        this.isDropzoneErrorFile = true
        this.isDropzoneErrorEmpty = true
        this.$refs.listDropzone.removeFile(file)
      },
      goToTopDialog () {
        setTimeout(() => {
          document.querySelector('.v-dialog--active').scrollTop = 0
        }, 0)
      },
      submit () {
        this.canStartCreationAction = false
        // https://github.com/vuejs/vue/issues/9200
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            const isValid = this.$refs.form.validate()
            if (isValid) {
              this.createList()
            } else {
              this.isDropzoneErrorEmpty = typeof this.form.dropzone !== 'string'
              this.canStartCreationAction = true
            }
          })
        })
      },
      submitAndAnalyze () {
        this.canStartCreationAction = false
        // https://github.com/vuejs/vue/issues/9200
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            if (this.$refs.form.validate()) {
              // vérifier la longueur et générer la pseudo liste
              const emails = utils.sanitizeEmailListTemp(this.form.dropzone) // utils.sanitizeEmailList(this.form.dropzone)
              if (emails.length < 1) {
                this.$toast(this.$t('views.campaigns.createListUploadNoMailErrorMessage'), {
                  icon: 'mdi-alert-circle',
                  color: 'red'
                })
                this.canStartCreationAction = true
                return
              }
              this.launchAnalysis = true
              this.createList()
            } else {
              this.isDropzoneErrorEmpty = typeof this.form.dropzone !== 'string'
              this.canStartCreationAction = true
            }
          })
        })
      },
      authorizeValidate () {
        this.canStartCreationAction = false
        // https://github.com/vuejs/vue/issues/9200
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            if (this.$refs.form.validate()) {
              // vérifier la longueur et générer la pseudo liste
              const emails = utils.sanitizeEmailListTemp(this.form.dropzone) // utils.sanitizeEmailList(this.form.dropzone)
              if (emails.length < 1) {
                this.$toast(this.$t('views.campaigns.createListUploadNoMailErrorMessage'), {
                  icon: 'mdi-alert-circle',
                  color: 'red'
                })
                this.canStartCreationAction = true
                return
              }
              this.generatedList = { emailCount: emails.length }
              // ouvrir la popup de confirmation
              this.confirmationDialogModel = true
            } else {
              if (typeof this.form.dropzone !== 'string') {
                this.isDropzoneErrorEmpty = true
              } else {
                this.isDropzoneErrorEmpty = false
              }
              this.canStartCreationAction = true
            }
          })
        })
      },
      uploadAndValidate () {
        this.launchValidation = true
        this.createList()
      },
      createList: function () {
        // Nettoyage du fichier pour vérifier un minimum les données envoyées
        // méthode temp du comptage d'@ pour renaud
        const emails = utils.sanitizeEmailListTemp(this.form.dropzone) // utils.sanitizeEmailList(this.form.dropzone)
        if (emails.length < 1) {
          this.$toast(this.$t('views.campaigns.createListUploadNoMailErrorMessage'), {
            icon: 'mdi-alert-circle',
            color: 'red'
          })
          return
        }

        // créer la liste vide
        listAPI.createEmtpyList(this.accessToken, this.form.title, this.form.description)
          .then((listId) => {
            this.uploadEmailList(listId, emails)
          })
          .catch((error) => {
            console.error("Erreur durant l'upload de la liste => ", error)
          })
          .finally((_) => {
            this.$refs.form.reset()
            this.$refs.listDropzone.removeAllFiles(true)
            this.setCreateCampaignModal(false)
          })
      },
      uploadEmailList (listId, emails) {
        this.emailsUpload.toUpload = Math.ceil(emails.length / this.emailsUpload.emailsPerUpload)
        // afficher le loader infini le temps de l'envoi
        this.uploadListDialog = true
        // découper et up
        let lastIndex = 0
        for (let index = 0; index < emails.length; index += this.emailsUpload.emailsPerUpload) {
          if (index + this.emailsUpload.toUpload < emails.length) {
            lastIndex = index + this.emailsUpload.emailsPerUpload
          } else {
            lastIndex = Math.max(emails.length - 1, 1) // fix pour permettre liste 1 email
          }
          // todo: Cas d'erreur
          emailAPI.addEmailsBulk(this.accessToken, listId, emails.slice(index, lastIndex))
            .then(() => {
              this.emailsUpload.uploaded++
              this.emailsUpload.percentage = Math.floor(
                this.emailsUpload.uploaded / this.emailsUpload.toUpload * 100)
              if (this.emailsUpload.uploaded === this.emailsUpload.toUpload) {
                // appel api de finialisation
                listAPI.finishListCreation(this.accessToken, listId, this.fileExtension)
                  .then(() => {
                    // fin de l'upload
                    this.uploadListDialog = false
                    this.resetEmailsUpload()
                    // afficher la notif de fin
                    this.$toast(this.$t('views.campaigns.uploadListSuccessfully'), {
                      icon: 'mdi-information-outline',
                      color: 'primary'
                    })
                    this.$emit('listCreationComplete', { listId: listId, launchAnalysis: this.launchAnalysis, launchValidation: this.launchValidation })
                    this.canStartCreationAction = true
                    this.launchAnalysis = false
                    this.launchValidation = false
                  })
              }
            })
        }
      },
      // reset emailsUpload afin de pouvoir passer un nouveau fichier d'upload
      resetEmailsUpload () {
        this.emailsUpload = {
          uploaded: 0,
          toUpload: 0,
          percentage: 0,
          emailsPerUpload: 1000
        }
      },
      resetConfirmationDialog () {
        this.confirmationDialogModel = false
        this.canStartCreationAction = true
      }
    }
  }

</script>
