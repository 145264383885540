export default {
    lang: '',
    drawer: null,
    entitiesPage: [
        {
            name: 'home',
            link: '/page-home',
            icon: 'mdi-chart-bar',
            label: 'components.drawer.home',
            additionnalAttributs: {}
        },
        {
            name: 'campaigns',
            link: '/page-campaigns',
            icon: 'mdi-email-check',
            label: 'components.drawer.campaigns',
            additionnalAttributs: {}
        },
        {
            name: 'validate-unitary',
            link: '/page-validate-unitary',
            icon: 'mdi-email',
            label: 'components.drawer.validate-unitary',
            additionnalAttributs: {}
        },
        {
            name: 'pricing',
            link: '/page-pricing',
            icon: 'mdi-currency-eur',
            label: 'components.drawer.pricing',
            additionnalAttributs: {}
        },
        {
            name: 'credits-history',
            link: '/page-credits-history',
            icon: 'mdi-history',
            label: 'components.drawer.credits-history',
            additionnalAttributs: {}
        },
        {
            name: 'profile',
            link: '/page-user-profile',
            icon: 'mdi-account',
            label: 'components.drawer.user-profile',
            additionnalAttributs: {}
        },
        {
            name: 'faq',
            link: '/page-faq',
            icon: 'mdi-frequently-asked-questions',
            label: 'components.drawer.faq',
            additionnalAttributs: {}
        },
        {
            name: 'contact',
            link: '/page-contact',
            icon: 'mdi-help-circle',
            label: 'components.drawer.contact',
            additionnalAttributs: {}
        }
    ]
}
