const countries =
{
    'en': [
        { code: 'ca', name: 'Canada' },
        { code: 'ie', name: 'Ireland' },
        { code: 'gb', name: 'United Kingdom of Great Britain and Northern Ireland' },
        { code: 'us', name: 'United States of America' },
        { code: 'af', name: 'Afghanistan' },
        { code: 'al', name: 'Albania' },
        { code: 'dz', name: 'Algeria' },
        { code: 'ad', name: 'Andorra' },
        { code: 'ao', name: 'Angola' },
        { code: 'ag', name: 'Antigua and Barbuda' },
        { code: 'ar', name: 'Argentina' },
        { code: 'am', name: 'Armenia' },
        { code: 'au', name: 'Australia' },
        { code: 'at', name: 'Austria' },
        { code: 'az', name: 'Azerbaijan' },
        { code: 'bs', name: 'Bahamas' },
        { code: 'bh', name: 'Bahrain' },
        { code: 'bd', name: 'Bangladesh' },
        { code: 'bb', name: 'Barbados' },
        { code: 'by', name: 'Belarus' },
        { code: 'be', name: 'Belgium' },
        { code: 'bz', name: 'Belize' },
        { code: 'bj', name: 'Benin' },
        { code: 'bt', name: 'Bhutan' },
        { code: 'bo', name: 'Bolivia (Plurinational State of)' },
        { code: 'ba', name: 'Bosnia and Herzegovina' },
        { code: 'bw', name: 'Botswana' },
        { code: 'br', name: 'Brazil' },
        { code: 'bn', name: 'Brunei Darussalam' },
        { code: 'bg', name: 'Bulgaria' },
        { code: 'bf', name: 'Burkina Faso' },
        { code: 'bi', name: 'Burundi' },
        { code: 'cv', name: 'Cabo Verde' },
        { code: 'kh', name: 'Cambodia' },
        { code: 'cm', name: 'Cameroon' },
        { code: 'cf', name: 'Central African Republic' },
        { code: 'td', name: 'Chad' },
        { code: 'cl', name: 'Chile' },
        { code: 'cn', name: 'China' },
        { code: 'co', name: 'Colombia' },
        { code: 'km', name: 'Comoros' },
        { code: 'cg', name: 'Congo' },
        { code: 'cd', name: 'Congo, Democratic Republic of the' },
        { code: 'cr', name: 'Costa Rica' },
        { code: 'ci', name: "Côte d'Ivoire" },
        { code: 'hr', name: 'Croatia' },
        { code: 'cu', name: 'Cuba' },
        { code: 'cy', name: 'Cyprus' },
        { code: 'cz', name: 'Czechia' },
        { code: 'dk', name: 'Denmark' },
        { code: 'dj', name: 'Djibouti' },
        { code: 'dm', name: 'Dominica' },
        { code: 'do', name: 'Dominican Republic' },
        { code: 'ec', name: 'Ecuador' },
        { code: 'eg', name: 'Egypt' },
        { code: 'sv', name: 'El Salvador' },
        { code: 'gq', name: 'Equatorial Guinea' },
        { code: 'er', name: 'Eritrea' },
        { code: 'ee', name: 'Estonia' },
        { code: 'sz', name: 'Eswatini' },
        { code: 'et', name: 'Ethiopia' },
        { code: 'fj', name: 'Fiji' },
        { code: 'fi', name: 'Finland' },
        { code: 'fr', name: 'France' },
        { code: 'ga', name: 'Gabon' },
        { code: 'gm', name: 'Gambia' },
        { code: 'ge', name: 'Georgia' },
        { code: 'de', name: 'Germany' },
        { code: 'gh', name: 'Ghana' },
        { code: 'gr', name: 'Greece' },
        { code: 'gd', name: 'Grenada' },
        { code: 'gt', name: 'Guatemala' },
        { code: 'gn', name: 'Guinea' },
        { code: 'gw', name: 'Guinea-Bissau' },
        { code: 'gy', name: 'Guyana' },
        { code: 'ht', name: 'Haiti' },
        { code: 'hn', name: 'Honduras' },
        { code: 'hu', name: 'Hungary' },
        { code: 'is', name: 'Iceland' },
        { code: 'in', name: 'India' },
        { code: 'id', name: 'Indonesia' },
        { code: 'ir', name: 'Iran (Islamic Republic of)' },
        { code: 'iq', name: 'Iraq' },
        { code: 'il', name: 'Israel' },
        { code: 'it', name: 'Italy' },
        { code: 'jm', name: 'Jamaica' },
        { code: 'jp', name: 'Japan' },
        { code: 'jo', name: 'Jordan' },
        { code: 'kz', name: 'Kazakhstan' },
        { code: 'ke', name: 'Kenya' },
        { code: 'ki', name: 'Kiribati' },
        { code: 'kp', name: "Korea (Democratic People's Republic of)" },
        { code: 'kr', name: 'Korea, Republic of' },
        { code: 'kw', name: 'Kuwait' },
        { code: 'kg', name: 'Kyrgyzstan' },
        { code: 'la', name: "Lao People's Democratic Republic" },
        { code: 'lv', name: 'Latvia' },
        { code: 'lb', name: 'Lebanon' },
        { code: 'ls', name: 'Lesotho' },
        { code: 'lr', name: 'Liberia' },
        { code: 'ly', name: 'Libya' },
        { code: 'li', name: 'Liechtenstein' },
        { code: 'lt', name: 'Lithuania' },
        { code: 'lu', name: 'Luxembourg' },
        { code: 'mg', name: 'Madagascar' },
        { code: 'mw', name: 'Malawi' },
        { code: 'my', name: 'Malaysia' },
        { code: 'mv', name: 'Maldives' },
        { code: 'ml', name: 'Mali' },
        { code: 'mt', name: 'Malta' },
        { code: 'mh', name: 'Marshall Islands' },
        { code: 'mr', name: 'Mauritania' },
        { code: 'mu', name: 'Mauritius' },
        { code: 'mx', name: 'Mexico' },
        { code: 'fm', name: 'Micronesia (Federated States of)' },
        { code: 'md', name: 'Moldova, Republic of' },
        { code: 'mc', name: 'Monaco' },
        { code: 'mn', name: 'Mongolia' },
        { code: 'me', name: 'Montenegro' },
        { code: 'ma', name: 'Morocco' },
        { code: 'mz', name: 'Mozambique' },
        { code: 'mm', name: 'Myanmar' },
        { code: 'na', name: 'Namibia' },
        { code: 'nr', name: 'Nauru' },
        { code: 'np', name: 'Nepal' },
        { code: 'nl', name: 'Netherlands' },
        { code: 'nz', name: 'New Zealand' },
        { code: 'ni', name: 'Nicaragua' },
        { code: 'ne', name: 'Niger' },
        { code: 'ng', name: 'Nigeria' },
        { code: 'mk', name: 'North Macedonia' },
        { code: 'no', name: 'Norway' },
        { code: 'om', name: 'Oman' },
        { code: 'pk', name: 'Pakistan' },
        { code: 'pw', name: 'Palau' },
        { code: 'pa', name: 'Panama' },
        { code: 'pg', name: 'Papua New Guinea' },
        { code: 'py', name: 'Paraguay' },
        { code: 'pe', name: 'Peru' },
        { code: 'ph', name: 'Philippines' },
        { code: 'pl', name: 'Poland' },
        { code: 'pt', name: 'Portugal' },
        { code: 'qa', name: 'Qatar' },
        { code: 'ro', name: 'Romania' },
        { code: 'ru', name: 'Russian Federation' },
        { code: 'rw', name: 'Rwanda' },
        { code: 'kn', name: 'Saint Kitts and Nevis' },
        { code: 'lc', name: 'Saint Lucia' },
        { code: 'vc', name: 'Saint Vincent and the Grenadines' },
        { code: 'ws', name: 'Samoa' },
        { code: 'sm', name: 'San Marino' },
        { code: 'st', name: 'Sao Tome and Principe' },
        { code: 'sa', name: 'Saudi Arabia' },
        { code: 'sn', name: 'Senegal' },
        { code: 'rs', name: 'Serbia' },
        { code: 'sc', name: 'Seychelles' },
        { code: 'sl', name: 'Sierra Leone' },
        { code: 'sg', name: 'Singapore' },
        { code: 'sk', name: 'Slovakia' },
        { code: 'si', name: 'Slovenia' },
        { code: 'sb', name: 'Solomon Islands' },
        { code: 'so', name: 'Somalia' },
        { code: 'za', name: 'South Africa' },
        { code: 'ss', name: 'South Sudan' },
        { code: 'es', name: 'Spain' },
        { code: 'lk', name: 'Sri Lanka' },
        { code: 'sd', name: 'Sudan' },
        { code: 'sr', name: 'Suriname' },
        { code: 'se', name: 'Sweden' },
        { code: 'ch', name: 'Switzerland' },
        { code: 'sy', name: 'Syrian Arab Republic' },
        { code: 'tj', name: 'Tajikistan' },
        { code: 'tz', name: 'Tanzania, United Republic of' },
        { code: 'th', name: 'Thailand' },
        { code: 'tl', name: 'Timor-Leste' },
        { code: 'tg', name: 'Togo' },
        { code: 'to', name: 'Tonga' },
        { code: 'tt', name: 'Trinidad and Tobago' },
        { code: 'tn', name: 'Tunisia' },
        { code: 'tr', name: 'Türkiye' },
        { code: 'tm', name: 'Turkmenistan' },
        { code: 'tv', name: 'Tuvalu' },
        { code: 'ug', name: 'Uganda' },
        { code: 'ua', name: 'Ukraine' },
        { code: 'ae', name: 'United Arab Emirates' },
        { code: 'uy', name: 'Uruguay' },
        { code: 'uz', name: 'Uzbekistan' },
        { code: 'vu', name: 'Vanuatu' },
        { code: 've', name: 'Venezuela (Bolivarian Republic of)' },
        { code: 'vn', name: 'Viet Nam' },
        { code: 'ye', name: 'Yemen' },
        { code: 'zm', name: 'Zambia' },
        { code: 'zw', name: 'Zimbabwe' }
    ],
    'fr': [
        { code: 'be', name: 'Belgique' },
        { code: 'fr', name: 'France' },
        { code: 'lu', name: 'Luxembourg' },
        { code: 'ch', name: 'Suisse' },
        { code: 'af', name: 'Afghanistan' },
        { code: 'za', name: 'Afrique du Sud' },
        { code: 'al', name: 'Albanie' },
        { code: 'dz', name: 'Algérie' },
        { code: 'de', name: 'Allemagne' },
        { code: 'ad', name: 'Andorre' },
        { code: 'ao', name: 'Angola' },
        { code: 'ag', name: 'Antigua-et-Barbuda' },
        { code: 'sa', name: 'Arabie saoudite' },
        { code: 'ar', name: 'Argentine' },
        { code: 'am', name: 'Arménie' },
        { code: 'au', name: 'Australie' },
        { code: 'at', name: 'Autriche' },
        { code: 'az', name: 'Azerbaïdjan' },
        { code: 'bs', name: 'Bahamas' },
        { code: 'bh', name: 'Bahreïn' },
        { code: 'bd', name: 'Bangladesh' },
        { code: 'bb', name: 'Barbade' },
        { code: 'by', name: 'Biélorussie' },
        { code: 'bz', name: 'Belize' },
        { code: 'bj', name: 'Bénin' },
        { code: 'bt', name: 'Bhoutan' },
        { code: 'mm', name: 'Birmanie' },
        { code: 'bo', name: 'Bolivie' },
        { code: 'ba', name: 'Bosnie-Herzégovine' },
        { code: 'bw', name: 'Botswana' },
        { code: 'br', name: 'Brésil' },
        { code: 'bn', name: 'Brunei' },
        { code: 'bg', name: 'Bulgarie' },
        { code: 'bf', name: 'Burkina Faso' },
        { code: 'bi', name: 'Burundi' },
        { code: 'kh', name: 'Cambodge' },
        { code: 'cm', name: 'Cameroun' },
        { code: 'ca', name: 'Canada' },
        { code: 'cv', name: 'Cap-Vert' },
        { code: 'cl', name: 'Chili' },
        { code: 'cn', name: 'Chine' },
        { code: 'cy', name: 'Chypre' },
        { code: 'co', name: 'Colombie' },
        { code: 'km', name: 'Comores' },
        { code: 'kr', name: 'Corée du Sud' },
        { code: 'kp', name: 'Corée du Nord' },
        { code: 'cr', name: 'Costa Rica' },
        { code: 'ci', name: "Côte d'Ivoire" },
        { code: 'hr', name: 'Croatie' },
        { code: 'cu', name: 'Cuba' },
        { code: 'dk', name: 'Danemark' },
        { code: 'dj', name: 'Djibouti' },
        { code: 'dm', name: 'Dominique' },
        { code: 'eg', name: 'Égypte' },
        { code: 'ae', name: 'Émirats arabes unis' },
        { code: 'ec', name: 'Équateur' },
        { code: 'er', name: 'Érythrée' },
        { code: 'es', name: 'Espagne' },
        { code: 'ee', name: 'Estonie' },
        { code: 'sz', name: 'Eswatini' },
        { code: 'us', name: 'États-Unis' },
        { code: 'fm', name: 'États fédérés de Micronésie' },
        { code: 'et', name: 'Éthiopie' },
        { code: 'fj', name: 'Fidji' },
        { code: 'fi', name: 'Finlande' },
        { code: 'ga', name: 'Gabon' },
        { code: 'gm', name: 'Gambie' },
        { code: 'ge', name: 'Géorgie' },
        { code: 'gh', name: 'Ghana' },
        { code: 'gr', name: 'Grèce' },
        { code: 'gd', name: 'Grenade' },
        { code: 'gt', name: 'Guatemala' },
        { code: 'gn', name: 'Guinée' },
        { code: 'gw', name: 'Guinée-Bissau' },
        { code: 'gq', name: 'Guinée équatoriale' },
        { code: 'gy', name: 'Guyana' },
        { code: 'ht', name: 'Haïti' },
        { code: 'hn', name: 'Honduras' },
        { code: 'hu', name: 'Hongrie' },
        { code: 'sb', name: 'Îles Salomon' },
        { code: 'in', name: 'Inde' },
        { code: 'id', name: 'Indonésie' },
        { code: 'ir', name: 'Iran' },
        { code: 'iq', name: 'Irak' },
        { code: 'ie', name: 'Irlande' },
        { code: 'is', name: 'Islande' },
        { code: 'il', name: 'Israël' },
        { code: 'it', name: 'Italie' },
        { code: 'jm', name: 'Jamaïque' },
        { code: 'jp', name: 'Japon' },
        { code: 'jo', name: 'Jordanie' },
        { code: 'kz', name: 'Kazakhstan' },
        { code: 'ke', name: 'Kenya' },
        { code: 'kg', name: 'Kirghizistan' },
        { code: 'ki', name: 'Kiribati' },
        { code: 'kw', name: 'Koweït' },
        { code: 'la', name: 'Laos' },
        { code: 'ls', name: 'Lesotho' },
        { code: 'lv', name: 'Lettonie' },
        { code: 'lb', name: 'Liban' },
        { code: 'lr', name: 'Liberia' },
        { code: 'ly', name: 'Libye' },
        { code: 'li', name: 'Liechtenstein' },
        { code: 'lt', name: 'Lituanie' },
        { code: 'mk', name: 'Macédoine du Nord' },
        { code: 'mg', name: 'Madagascar' },
        { code: 'my', name: 'Malaisie' },
        { code: 'mw', name: 'Malawi' },
        { code: 'mv', name: 'Maldives' },
        { code: 'ml', name: 'Mali' },
        { code: 'mt', name: 'Malte' },
        { code: 'ma', name: 'Maroc' },
        { code: 'mh', name: 'Îles Marshall' },
        { code: 'mu', name: 'Maurice' },
        { code: 'mr', name: 'Mauritanie' },
        { code: 'mx', name: 'Mexique' },
        { code: 'md', name: 'Moldavie' },
        { code: 'mc', name: 'Monaco' },
        { code: 'mn', name: 'Mongolie' },
        { code: 'me', name: 'Monténégro' },
        { code: 'mz', name: 'Mozambique' },
        { code: 'na', name: 'Namibie' },
        { code: 'nr', name: 'Nauru' },
        { code: 'np', name: 'Népal' },
        { code: 'ni', name: 'Nicaragua' },
        { code: 'ne', name: 'Niger' },
        { code: 'ng', name: 'Nigeria' },
        { code: 'no', name: 'Norvège' },
        { code: 'nz', name: 'Nouvelle-Zélande' },
        { code: 'om', name: 'Oman' },
        { code: 'ug', name: 'Ouganda' },
        { code: 'uz', name: 'Ouzbékistan' },
        { code: 'pk', name: 'Pakistan' },
        { code: 'pw', name: 'Palaos' },
        { code: 'pa', name: 'Panama' },
        { code: 'pg', name: 'Papouasie-Nouvelle-Guinée' },
        { code: 'py', name: 'Paraguay' },
        { code: 'nl', name: 'Pays-Bas' },
        { code: 'pe', name: 'Pérou' },
        { code: 'ph', name: 'Philippines' },
        { code: 'pl', name: 'Pologne' },
        { code: 'pt', name: 'Portugal' },
        { code: 'qa', name: 'Qatar' },
        { code: 'cf', name: 'République centrafricaine' },
        { code: 'cd', name: 'République démocratique du Congo' },
        { code: 'do', name: 'République dominicaine' },
        { code: 'cg', name: 'République du Congo' },
        { code: 'ro', name: 'Roumanie' },
        { code: 'gb', name: 'Royaume-Uni' },
        { code: 'ru', name: 'Russie' },
        { code: 'rw', name: 'Rwanda' },
        { code: 'kn', name: 'Saint-Christophe-et-Niévès' },
        { code: 'sm', name: 'Saint-Marin' },
        { code: 'vc', name: 'Saint-Vincent-et-les-Grenadines' },
        { code: 'lc', name: 'Sainte-Lucie' },
        { code: 'sv', name: 'Salvador' },
        { code: 'ws', name: 'Samoa' },
        { code: 'st', name: 'Sao Tomé-et-Principe' },
        { code: 'sn', name: 'Sénégal' },
        { code: 'rs', name: 'Serbie' },
        { code: 'sc', name: 'Seychelles' },
        { code: 'sl', name: 'Sierra Leone' },
        { code: 'sg', name: 'Singapour' },
        { code: 'sk', name: 'Slovaquie' },
        { code: 'si', name: 'Slovénie' },
        { code: 'so', name: 'Somalie' },
        { code: 'sd', name: 'Soudan' },
        { code: 'ss', name: 'Soudan du Sud' },
        { code: 'lk', name: 'Sri Lanka' },
        { code: 'se', name: 'Suède' },
        { code: 'sr', name: 'Suriname' },
        { code: 'sy', name: 'Syrie' },
        { code: 'tj', name: 'Tadjikistan' },
        { code: 'tz', name: 'Tanzanie' },
        { code: 'td', name: 'Tchad' },
        { code: 'cz', name: 'Tchéquie' },
        { code: 'th', name: 'Thaïlande' },
        { code: 'tl', name: 'Timor oriental' },
        { code: 'tg', name: 'Togo' },
        { code: 'to', name: 'Tonga' },
        { code: 'tt', name: 'Trinité-et-Tobago' },
        { code: 'tn', name: 'Tunisie' },
        { code: 'tm', name: 'Turkménistan' },
        { code: 'tr', name: 'Turquie' },
        { code: 'tv', name: 'Tuvalu' },
        { code: 'ua', name: 'Ukraine' },
        { code: 'uy', name: 'Uruguay' },
        { code: 'vu', name: 'Vanuatu' },
        { code: 've', name: 'Venezuela' },
        { code: 'vn', name: 'Viêt Nam' },
        { code: 'ye', name: 'Yémen' },
        { code: 'zm', name: 'Zambie' },
        { code: 'zw', name: 'Zimbabwe' }
    ]
}

export default {
    getLocalizedCountries (locale) {
        if (locale in countries) {
            return countries[locale]
        } else {
           return countries.en
        }
    }
}