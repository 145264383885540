import validateProduct from '@/api/validate-products'

export default {
  getAllProducts ({ commit, state, rootState }, vat) {
    if (rootState.user.accessToken) {
      validateProduct
        .getProductListWithVat(rootState.user.accessToken, vat)
        .then(products => {
          commit('setProducts', { products, vat })
        })
        .catch(error => {
          console.error('store/product/actions/refreshProducts: ' + error)
        })
    } else {
      console.warn('Les produits n\'ont pas pu se chargé : pas d\'accessToken de dispo')
    }
  }
}
