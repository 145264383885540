<template>
  <div class="campaign_tabs">
    <v-tabs
      v-model="campaignTabs"
      :grow="true"
      :lefted="true"
      active-class="active-tabs"
    >
      <v-tab href="#detailsCampaign">
        {{ $t("views.campaigns.detailsCampaign") }}
      </v-tab>
      <v-tab
        id="simple-tab"
        href="#simpleCampaign"
      >
        {{ $t("views.campaigns.simpleCampaign") }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="campaignTabs">
      <v-tab-item
        value="simpleCampaign"
        class="campaign_tabs--simpleCampaign"
      >
        <!-- visualisation d'une campagne simple -->
        <v-row>
          <v-col
            :cols="12"
            :lg="6"
            class="campaign_tabs--simpleCampaign_table"
          >
            <v-data-table
              :headers="campaignSimpleHeaders"
              :items="selectedCampaign.campaigns.simple"
              hide-default-header
              hide-default-footer
            >
              <template v-slot:item.name="{ item }">
                <div class="campaign-name-row">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        {{ $t(`views.campaigns.${item.name}.label`) }}
                        <v-icon>
                          mdi-information
                        </v-icon>
                      </div>
                    </template>
                    <span>{{ $t(`views.campaigns.${item.name}.description`) }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.count="{ item }">
                <div>{{ $n(item.count) }}</div>
              </template>
              <template v-slot:item.percent="{ item }">
                <div>{{ ((item.percent !== "N/A")?(item.percent + "%"):$t("views.campaigns.naLabel")) }}</div>
              </template>
              <template v-slot:item.download="{ item }">
                <div v-if="selectedCampaign.list.emailsDeleted">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-cloud-off-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('views.campaigns.table.buttonNoDownloadArchived') }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="item.count > 0 && !isDownloading[item.download]"
                  class="cell-action"
                  @click="downloadCampaignListSpecific(selectedCampaign.name,selectedCampaign.engineName, item.download)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="'chartsColor-' + item.name"
                        v-on="on"
                      >
                        mdi-cloud-download
                      </v-icon>
                    </template>
                    <span>{{ $t(`views.campaigns.${item.name}.download`) }}</span>
                  </v-tooltip>
                </div>
                <div v-else-if="item.count > 0 && isDownloading[item.download]">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                    size="18"
                  />
                </div>
                <div v-else>
                  <v-icon
                    class="text--disabled"
                    style="color:#EFEFEF !important"
                  >
                    mdi-cloud-download
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            :cols="12"
            :lg="6"
            class="campaign_tabs--simpleCampaign_donuts"
          >
            <canvas id="simpleCampaign-pie" />
            <div class="text-center mt-3">
              <h3>{{ $t('views.campaigns.actions') }}</h3>
              <div>
                <v-btn
                  color="warning"
                  @click="switchChange"
                >
                  {{ $t('views.campaigns.switchToDetailed') }}
                </v-btn>
              </div>
              <div v-if="!selectedCampaign.list.emailsDeleted">
                <div>
                  <v-btn
                    class="white--text"
                    color="#3C3C3B"
                    @click="downloadCampaignListSpecific(selectedCampaign.name,selectedCampaign.engineName, 'all-results')"
                  >
                    {{ $t('views.campaigns.downloadGlobalFile') }}
                    <div v-if="isDownloading['all-results']">
                      <v-progress-circular
                        color="white"
                        indeterminate
                        size="18"
                      />
                    </div>
                  </v-btn>
                </div>
                <div>
                  <!-- Bouton de téléchargement du fichier csv multi-colonnes -->
                  <v-tooltip
                    color="#fff"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-btn
                          color="#777"
                          :disabled="selectedCampaign.stats.multiFieldStatus !== 'done' || isDownloading['multi-field']"
                          @click="downloadCampaignListSpecific(selectedCampaign.name,selectedCampaign.engineName,'multi-field')"
                        >
                          <span class="white--text">
                            {{ $t('views.campaigns.multiField.download') }}
                          </span>
                          <span
                            v-if="selectedCampaign.stats.multiFieldStatus === 'todo'"
                            class="white--text"
                          >
                            ({{ selectedCampaign.stats.multiFieldGenerationPercentage }})
                          </span>
                          <div v-if="isDownloading['multi-field']">
                            <v-progress-circular
                              color="white"
                              indeterminate
                              size="18"
                            />
                          </div>
                        </v-btn>
                      </div>
                    </template>
                    {{ $t('views.campaigns.multiField.tooltip-' + selectedCampaign.stats.multiFieldStatus) }}
                  </v-tooltip>
                </div>
              </div>
              <div>
                <campaigns-pdf-report-button
                  :list="selectedCampaign.list"
                  size="large"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
        value="detailsCampaign"
        class="campaign_tabs--detailsCampaign"
      >
        <!-- visualisation d'une campagne détaillées -->
        <v-row>
          <v-col
            :order="2"
            :order-lg="1"
            :cols="12"
            :lg="6"
            class="campaign_tabs--detailsCampaign_table"
          >
            <v-data-table
              :headers="campaignDetailsHeaders"
              :items="selectedCampaign.campaigns.details"
              :items-per-page="100"
              hide-default-footer
              hide-default-header
            >
              <!-- Customisation de certains items dans les colonnes -->
              <template v-slot:item.name="{ item }">
                <div class="campaign-name-row">
                  <v-tooltip bottom>
                    <template
                      v-slot:activator="{ on }"
                    >
                      <div v-on="on">
                        {{ $t(`views.campaigns.${item.name}.label`) }}
                        <v-icon>
                          mdi-information
                        </v-icon>
                      </div>
                    </template>
                    <span>
                      {{ $t(`views.campaigns.${item.name}.description`) }}
                    </span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.count="{ item }">
                <div>{{ $n(item.count) }}</div>
              </template>
              <template v-slot:item.percent="{ item }">
                <div>{{ ((item.percent !== "N/A")?(item.percent + "%"):$t("views.campaigns.naLabel")) }}</div>
              </template>
              <template v-slot:item.download="{ item }">
                <div v-if="selectedCampaign.list.emailsDeleted">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-cloud-off-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('views.campaigns.table.buttonNoDownloadArchived') }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="item.count > 0 && !isDownloading[item.download]"
                  class="cell-action"
                  @click="downloadCampaignListSpecific(selectedCampaign.name,selectedCampaign.engineName, item.download)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :class="'chartsColor-' + item.name"
                        v-on="on"
                      >
                        mdi-cloud-download
                      </v-icon>
                    </template>
                    <span>{{ $t(`views.campaigns.${item.name}.download`) }}</span>
                  </v-tooltip>
                </div>
                <div v-else-if="item.count > 0 && isDownloading[item.download]">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                    size="18"
                  />
                </div>
                <div v-else>
                  <v-icon
                    class="text--disabled"
                  >
                    mdi-cloud-download
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            :order="1"
            :order-lg="2"
            :cols="12"
            :lg="6"
            class="campaign_tabs--detailsCampaign_donuts"
          >
            <canvas id="detailsCampaign-pie" />
            <div class="text-center mt-3">
              <h3>{{ $t('views.campaigns.actions') }}</h3>
              <v-btn
                color="warning"
                @click="switchChange"
              >
                {{ $t('views.campaigns.switchToSimple') }}
              </v-btn>
              <div v-if="!selectedCampaign.list.emailsDeleted">
                <v-btn
                  class="white--text"
                  color="#3C3C3B"
                  @click="downloadCampaignListSpecific(selectedCampaign.name,selectedCampaign.engineName, 'all-results')"
                >
                  {{ $t('views.campaigns.downloadGlobalFile') }}
                  <div v-if="isDownloading['all-results']">
                    <v-progress-circular
                      color="white"
                      indeterminate
                      size="18"
                    />
                  </div>
                </v-btn>
                <div>
                  <!-- Bouton de téléchargement du fichier csv multi-colonnes -->
                  <v-tooltip
                    color="#fff"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-btn
                          color="#777"
                          :disabled="selectedCampaign.stats.multiFieldStatus !== 'done' || isDownloading['multi-field']"
                          @click="downloadCampaignListSpecific(selectedCampaign.name,selectedCampaign.engineName,'multi-field')"
                        >
                          <span class="white--text">
                            {{ $t('views.campaigns.multiField.download') }}
                          </span>
                          <span
                            v-if="selectedCampaign.stats.multiFieldStatus === 'todo'"
                            class="white--text"
                          >
                            ({{ selectedCampaign.stats.multiFieldGenerationPercentage }})
                          </span>
                          <div v-if="isDownloading['multi-field']">
                            <v-progress-circular
                              color="white"
                              indeterminate
                              size="18"
                            />
                          </div>
                        </v-btn>
                      </div>
                    </template>
                    {{ $t('views.campaigns.multiField.tooltip-' + selectedCampaign.stats.multiFieldStatus) }}
                  </v-tooltip>
                </div>
              </div>
              <campaigns-pdf-report-button
                :list="selectedCampaign.list"
                size="large"
              />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
  import campaignAPI from '@/api/campaigns'
  import tokenCampaignAPI from '@/api/campaign-via-token'
  import { mapState } from 'vuex'

  import Chart from 'chart.js'
  import CampaignCharts from '@/components/campaigns/CampaignsCharts'

  const campaignCharts = new CampaignCharts()

  export default {
    props: {
      // userToken ou campaignToken
      fileRetrieveMethod: {
        type: String,
        default: 'userToken'
      },
      validationLauncherProgressDialog: {
        type: Boolean
      },
      selectedCampaign: {
        type: Object
      },
      // necessaire si fileRetrieveMethod === 'campaignToken'
      campaignToken: {
        type: Object
      }
    },
    data: function () {
      return {
        detailsChart: null,
        simpleChart: null,

        listTabs: null,
        campaignTabs: null,
        alreadyGenerateSimple: false,
        alreadyGenerateDetail: false,
        isDownloading: {},

        // header pour la visualisation simplifié de la campagne
        campaignSimpleHeaders: [
          { text: '', value: 'name' },
          { text: '', value: 'count' },
          { text: '', value: 'percent' },
          { text: '', value: 'download', sortable: false } // téléchargement de la liste
        ],
        // header pour la visualisation détaillée de la campagne
        campaignDetailsHeaders: [
          { text: '', value: 'name' },
          { text: '', value: 'count' },
          { text: '', value: 'percent' },
          { text: '', value: 'download', sortable: false } // téléchargement de la liste
        ],
        refreshCampaign: false,
        refreshCampaignInterval: 30000 // 30s
      }
    },
    created () {
      window.addEventListener('resize', this.switchLegendOnCharts)
      // refresh de la campagne si fichier multi-colonne non reconstruit
      setTimeout(this.refreshStats, this.refreshCampaignInterval)// toutes les 30s
      this.refreshCampaign = true
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.switchLegendOnCharts)
      this.refreshCampaign = false
    },
    mounted () {
      // créer le tableau des états de téléchargement des fichiers
      const context = this
      this.selectedCampaign.campaigns.simple.forEach(function (category) {
        context.$set(context.isDownloading, category.download, false)
      })
      this.selectedCampaign.campaigns.details.forEach(function (category) {
        context.$set(context.isDownloading, category.download, false)
      })
      this.$set(context.isDownloading, 'all-results', false)
      this.$set(context.isDownloading, 'multi-field', false)
    },
    computed: {
      ...mapState('campaign', ['campaignFirst']),
      ...mapState('user', ['accessToken'])
    },
    watch: {
      validationLauncherProgressDialog (isOpen) {
        // réinitialisation des Tabs et de leur contenu lors de la fermeture de la dialog parent
        if (!isOpen) {
          this.alreadyGenerateSimple = false
          this.alreadyGenerateDetail = false
          this.campaignTabs = null
        }
      },

      campaignTabs (tabName) {
        // création du chart lors du changement de tab
        // le setTimeout permet au DOM d'être bien charger
        if (tabName) {
          setTimeout(() => {
            switch (tabName) {
              case 'simpleCampaign':
                if (!this.alreadyGenerateSimple) {
                  this.alreadyGenerateSimple = true
                  this.createPieChart('simpleCampaign-pie',
                    campaignCharts.generateSimpleTableDataForPie(this.campaignFirst.statistics, this)
                  )
                }
                break
              case 'detailsCampaign':
                if (!this.alreadyGenerateDetail) {
                  this.alreadyGenerateDetail = true
                  this.createPieChart('detailsCampaign-pie',
                    campaignCharts.generateDetailsTableDataForExportPie(this.campaignFirst.statistics, this, this.$i18n.locale)
                  )
                }
                break
            }
          }, 100)
        }
      }
    },
    methods: {
      downloadCampaignListSpecific (listName, campaignEngineName, fileName) {
        this.$set(this.isDownloading, fileName, true)
        if (this.fileRetrieveMethod === 'campaignToken') {
          tokenCampaignAPI.getResultFile(this.campaignToken, fileName)
            .then(blob => {
              this.doDownloadFile(blob, fileName, listName)
              this.$set(this.isDownloading, fileName, false)
            })
        } else {
          campaignAPI.getResultFile(this.accessToken, campaignEngineName, fileName)
            .then(blob => {
              this.doDownloadFile(blob, fileName, listName)
              this.$set(this.isDownloading, fileName, false)
            })
        }
      },
      /** todo: internationalisation filename **/
      doDownloadFile (blob, fileName, listName) {
        // suppression des caratères non supportés dans les noms de fichier
        // cf https://stackoverflow.com/questions/42210199/remove-illegal-characters-from-a-file-name-but-leave-spaces
        const listFileName = listName.replace(/[/\\?%*:|"<>]/g, '-')
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        if (fileName === 'full-archive') {
          link.download = listFileName + '--' + fileName + '.zip'
        } else {
          switch (fileName) {
            case 'malformes':
              fileName = 'incorrect'
              break
            case 'suspects':
              fileName = 'unknown'
              break
            case 'valides-unfaith':
              fileName = 'valides-unreliable'
              break
            case 'catchall-unfaith':
              fileName = 'catchall-unreliable'
              break
          }

          link.download = listFileName + '--' + fileName + '.csv'
        }
        link.click()
      },
      // Création d'une tarte de statistique (miam miam)
      createPieChart (chartId, chartData) {
        const ctx = document.getElementById(chartId)
        if (ctx) {
          if (chartId === 'detailsCampaign-pie') {
            // eslint-disable-next-line no-unused-vars
            this.detailsChart = new Chart(ctx, {
              type: 'doughnut',
              data: chartData,
              options: {
                responsive: true,
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                  }
                },
                legend: {
                  position: 'bottom',
                  align: 'start',
                  fullWidth: true
                },
                animation: {
                  animateScale: true,
                  animateRotate: true
                },
                tooltips: {
                  mode: 'index',
                  intersect: false,
                  callbacks: {
                    // Permet d'afficher autre chose que les données brut par défaut
                    label: function (tooltipItem, data) {
                      const item = data.datasets[tooltipItem.datasetIndex]
                      return `${data.labels[tooltipItem.index]} - ${item.data[tooltipItem.index]} emails - ${item.percent[tooltipItem.index]}%`
                    }
                  }
                }
              }
            })
          } else if (chartId === 'simpleCampaign-pie') {
            // eslint-disable-next-line no-unused-vars
            this.simpleChart = new Chart(ctx, {
              type: 'doughnut',
              data: chartData,
              options: {
                responsive: true,
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                  }
                },
                legend: {
                  position: 'bottom',
                  align: 'start',
                  fullWidth: true
                },
                animation: {
                  animateScale: true,
                  animateRotate: true
                },
                tooltips: {
                  mode: 'index',
                  intersect: false,
                  callbacks: {
                    // Permet d'afficher autre chose que les données brut par défaut
                    label: function (tooltipItem, data) {
                      const item = data.datasets[tooltipItem.datasetIndex]
                      return `${data.labels[tooltipItem.index]} - ${item.data[tooltipItem.index]} emails - ${item.percent[tooltipItem.index]}%`
                    }
                  }
                }
              }
            })
          }
        }
      },
      /* switchLegendOnCharts () {
        if (this.detailsChart !== null) {
          this.detailsChart.legend.options.display = window.innerWidth > 420
        }

        if (this.simpleChart !== null) {
          this.simpleChart.legend.options.display = window.innerWidth > 420
        }
      }, */
      switchChange () {
        if (this.campaignTabs === 'simpleCampaign') {
          this.campaignTabs = 'detailsCampaign'
        } else {
          this.campaignTabs = 'simpleCampaign'
        }
      },
      refreshStats () {
        if (this.selectedCampaign.stats.multiFieldStatus === 'todo') {
          this.$emit('refreshStats')
          setTimeout(this.refreshStats, this.refreshCampaignInterval)
        }
      }
    }
  }
</script>
<style type="text/css">
  #simple-tab {
    animation-name: highlight;
    animation-duration: 3s;
  }
  @keyframes highlight {
    0%   {background-color: #FFF;}
    25%  {background-color: #95C11F;}
    75%  {background-color: #F8FBEF;}
    100% {background-color: #FFF;}
  }
  .campaign_tabs .v-input input{
    max-height: 32px;
  }
  .campaign_tabs .v-tabs {
    margin-bottom: 0;
  }
  canvas {
    width: 100% !important;
    height: auto !important;
  }
</style>
