<template>
  <v-dialog
    v-model="detailModal"
    max-width="500px"
    width="95%"
    @click:outside="$emit('close')"
  >
    <material-card
      v-if="emailDetails"
      :title="emailDetails.email"
      :text="$d(new Date(emailDetails.validated), 'long')"
      :icon="'mdi-email-edit'"
      color="primary"
    >
      <v-simple-table class="valdiate_unitary_modal_details">
        <template v-slot:default>
          <template>
            <tr>
              <td>{{ $t('views.validateUnitary.modalDetail.category') }}</td>
              <td>
                <v-chip
                  :color="wichColorChip(emailDetails)"
                  :text-color="wichTextColorChip(emailDetails)"
                >
                  {{ $t('views.validateUnitary.simpleTable.'+ emailDetails.category) }}
                </v-chip>
              </td>
            </tr>
            <template v-if="emailDetails.validationType === 'DEEP'">
              <tr>
                <td>{{ $t('views.validateUnitary.modalDetail.score') }}</td>
                <td>{{ `${emailDetails.score.result}/${emailDetails.score.total}` }}</td>
              </tr>
            </template>
            <tr :class="wichClassRow(emailDetails)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <td v-on="on">
                    {{ $t('views.validateUnitary.modalDetail.status') }}
                    <v-icon>
                      mdi-information
                    </v-icon>
                  </td>
                </template>
                <span>{{ $t('views.validateUnitary.modalDetail.tooltip.status') }}</span>
              </v-tooltip>
              <td>{{ emailDetails.status }}</td>
            </tr>
            <tr
              v-for="(value, key) in emailDetails.attributs"
              v-if="value != null"
              :key="key"
              :class="wichClassRow(value)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <td v-on="on">
                    {{ $t('views.validateUnitary.modalDetail.'+ key) }}
                    <v-icon>
                      mdi-information
                    </v-icon>
                  </td>
                </template>
                <span>{{ $t('views.validateUnitary.modalDetail.tooltip.'+ key) }}</span>
              </v-tooltip>
              <td>{{ $t('views.validateUnitary.modalDetail.'+ value.value) }}</td>
            </tr>
            <template v-if="emailDetails.validationType === 'DEEP'">
              <!-- != suspect: duplication legacy -->
              <tr
                v-for="(value, key) in emailDetails.extras"
                v-if="key != 'suspect'"
                :key="key"
                :class="wichClassRow(value)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <td v-on="on">
                      {{ $t('views.validateUnitary.modalDetail.'+ key) }}
                      <v-icon>
                        mdi-information
                      </v-icon>
                    </td>
                  </template>
                  <span>{{ $t('views.validateUnitary.modalDetail.tooltip.'+ key) }}</span>
                </v-tooltip>
                <td>{{ $t('views.validateUnitary.modalDetail.'+ value.value) }}</td>
              </tr>
            </template>
          </template>
        </template>
      </v-simple-table>
    </material-card>
  </v-dialog>
</template>
<script>
    export default {
        props: {
            detailModalInput: {
                type: Boolean,
                default: false,
                required: true
            },
            emailDetails: {
                type: Object,
                default: () => {
                }
            }
        },
        computed: {
            detailModal () {
                return this.detailModalInput
            }
        },
        methods: {
            wichClassRow (value) {
                if (!this.isParamValid(value)) {
                  return ''
                }

                if (value.type === 'neutral') {
                    return 'neutral'
                } else if (
                    (value.type === 'positive' && value.value) ||
                    (value.type === 'negative' && !value.value)
                ) {
                    return 'positive'
                } else if (value.status === 'VALID') {
                    return 'positive'
                } else {
                    return 'negative'
                }
            },
            wichColorChip (value) {
                if (!this.isParamValid(value)) {
                  return ''
                }

                switch (true) {
                    case value.category === 'VERY_SAFE':
                        return 'primary'
                    case value.category === 'RISKY':
                        return 'orange'
                    case value.category === 'UNSAFE':
                        return 'red'
                    default:
                        return '#aadc25'
                }
            },
            wichTextColorChip (value) {
                return (this.isParamValid(value) ? 'white' : '')
            },
            isParamValid (param) {
              return (param && (typeof param === 'object' || typeof param.type === 'string' || typeof param.value === 'boolean'))
            }
        }
    }

</script>
