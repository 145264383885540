<template>
  <v-app>
    <core-toolbar/>
    <core-drawer
        v-if="accessTokenPayload || $route.path === '/page-connexion'"
        data-v-tour-beta-step="2"
        data-v-tour-campaigns-step="14"
        data-v-tour-interface-step="1"
        data-v-tour-unitary-step="10"
    />
    <core-view data-v-tour-beta-step="1" />

    <!--    Dialog qui ont besoin d'être accessible de partout -->
    <dialog-changelog />
    <dialog-tour />

    <!-- Dernier composant pour que tout soit monté avant le début du tour -->
    <core-tours />
  </v-app>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
    computed: {
      ...mapState('user', ['accessTokenPayload', 'accessToken'])
    },
    watch: {
      accessTokenPayload (token, oldToken) {
        if (token) {
          const interval = 20000 // vérification toutes les 20s. Pas de setTimeout à cause des soucis de tab inactive...
          setInterval(
            () => {
              if (this.accessTokenPayload && this.$route.path !== '/page-connexion') {
                const tokenExpirationDate = new Date(this.accessTokenPayload.exp * 1000)
                // on retire l'interval pour expirer en avance le token si jamais il expire dans le prochain interval
                if ((tokenExpirationDate - new Date()) - interval < 0) {
                  this.$toast(this.$t('views.connexion.expiredSession'), {
                    icon: 'mdi-information-outline',
                    color: 'orange'
                  })
                  this.$router.push('/page-connexion')
                }
              }
            },
            interval)
        }
      }
    },
    created () {
      document.addEventListener('visibilitychange', this.handleTokenExpiracyWhenWindowVisible, false)
    },
    mounted () {
      if (this.accessTokenPayload) {
        this.handleTokenExpiracyWhenWindowVisible()
        this.refreshUserInfo()
      }
    },
    methods: {
      ...mapActions('user', ['refreshUserInfo']),
      handleTokenExpiracyWhenWindowVisible () {
       if (this.accessTokenPayload && this.$route.path !== '/page-connexion') {
          const tokenExpirationDate = new Date(this.accessTokenPayload.exp * 1000)
          if ((tokenExpirationDate - new Date()) < 0) {
            this.$toast(this.$t('views.connexion.expiredSession'), {
              icon: 'mdi-information-outline',
              color: 'orange'
            })
            this.$router.push('/page-connexion')
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/styles/index.scss";
</style>
