<template>
  <v-container>
    <v-row>
      <v-col
        v-if="!isAddressValid"
        :cols="12"
      >
        <p>{{ $t("components.invoice.display.noAddress") }}</p>
        <v-btn
          :to="{ path: '/page-user-profile', hash: '#invoice-address' }"
          color="primary"
        >
          {{ $t("components.invoice.display.completeAddress") }}
        </v-btn>
      </v-col>
      <v-col
        v-else
        :cols="12"
      >
        <h4><b>{{ $t("components.invoice.display.invoiceFor") }}</b></h4>
        {{ address.name }}<br>
        {{ address.addressLine1 }}<br>
        <span v-if="address.addressLine2">{{ address.addressLine2 }}<br></span>
        {{ address.postalCode }} {{ address.city }} - {{ address.translatedCountry }}<br>
        <span v-if="address.tvaNumber">{{ $t("components.invoice.display.vatNumber") }} : {{ address.tvaNumber }}<br></span>
        <v-btn
          :to="{ path: '/page-user-profile', hash: '#invoice-address' }"
          color="primary"
          small
        >
          {{ $t("components.invoice.display.completeAddress") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    props: {
      address: {
        type: Object,
        default: null
      }
    },
    computed: {
      isAddressValid: function () {
        return this.address != null
      }
    }
  }
</script>
