import axios from '@/plugins/axios'

export default {
    getListEmailsWithLimit (accessTokenHeader, listId, limit) {
        const url = process.env.VUE_APP_Email_Url + '?listId=' + listId + '&limit=' + limit

        const headers = {
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.get(url, { headers: headers })
            .then(response => {
                return response.data
            })
    },

    // 1000 emails max, emails est un tableau de string
    addEmailsBulk (accessTokenHeader, listId, emails) {
        const url = process.env.VUE_APP_Email_Url + '?listId=' + listId

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.post(url, emails, { headers: headers })
            .then(response => {
                return response
            })
    }
}
