<template>
  <div id="core-app-bar">
    <v-app-bar
      class="v-toolbar"
      flat
    >
      <div class="v-app-bar-title">
        <v-toolbar-title class="tertiary--text">
          <v-btn
            v-if="isMenuButton"
            class="default v-btn--simple font-weight-medium"
            dark
            icon
            @click.stop="onClickBtn"
          >
            <v-icon>
              mdi-view-list
            </v-icon>
          </v-btn>
          {{ $t(title) }}
        </v-toolbar-title>
      </div>
      <v-spacer />
      <v-toolbar-items>
        <v-flex
          align-center
          layout
          py-2
        >
          <router-link
              v-if="isToolbarItem && isLoggedIn"
              v-ripple
              class="toolbar-items"
              color="primary"
              to="/page-credits-history"
          >
            <span>
              {{ $t('components.toolbar.credit', {'credits': $n(credits)}) }}
            </span>
          </router-link>
          <v-menu
            v-if="isToolbarItem"
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
              >
                <v-icon class="--icon">
                  mdi-translate-variant
                </v-icon>
                <span>
                  {{ $i18n.locale.toUpperCase() }}
                </span>
              </v-btn>
            </template>
            <v-list light>
              <v-list-item class="toolbar--v-list-item">
                <a @click="updatePreferredLanguage('en')">EN</a>
              </v-list-item>
              <v-list-item class="toolbar--v-list-item">
                <a @click="updatePreferredLanguage('fr')">FR</a>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
              v-if="isLoggedIn"
              v-model="userMenu"
              :offset-y="true"
              origin="center center"
              transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                  class="toolbar--user--button_menu"
                  color="#3C3C3B"
                  outlined
                  v-on="on"
              >
                <v-icon class="--icon">
                  mdi-account
                </v-icon>
                <span
                    v-if="userInfo"
                    class="--name"
                > {{ userInfo.firstName.charAt(0) + '.' + userInfo.lastName }} </span>
              </v-btn>
            </template>
            <v-list light>
              <v-list-item class="toolbar--v-list-item">
                <v-spacer />
                <router-link to="/page-user-profile">
                  <a class="toolbar--user--button_list">Profil</a>
                </router-link>
              </v-list-item>
              <v-list-item class="toolbar--v-list-item">
                <v-spacer />
                <a
                  class="toolbar--user--button_list"
                  @click="logOut()"
                >
                  <v-icon color="tertiary">mdi-off</v-icon>
                  {{ $t('components.toolbar.logout') }}
                </a>
              </v-list-item>
              <v-list-item
                  v-if="!isToolbarItem && isLoggedIn"
                  class="toolbar--v-list-item"
              >
                <v-spacer/>
                <router-link
                    v-ripple
                    class="toolbar-items"
                    color="primary"
                    to="/page-credits-history"
                >
                  <span>
                    {{ $t('components.toolbar.credit', {'credits': $n(credits)}) }}
                  </span>
                </router-link>
              </v-list-item>
              <v-list-item v-if="!isToolbarItem">
                <v-spacer />
                <v-menu
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                    >
                      <v-icon class="--icon">
                        mdi-translate-variant
                      </v-icon>
                      <span>
                        {{ $i18n.locale.toUpperCase() }}
                      </span>
                    </span>
                  </template>
                  <v-list light>
                    <v-list-item class="toolbar--v-list-item">
                      <a @click="updatePreferredLanguage('en')">EN</a>
                    </v-list-item>
                    <v-list-item class="toolbar--v-list-item">
                      <a @click="updatePreferredLanguage('fr')">FR</a>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon
            v-if="!['components.toolbar.page-title-connexion', 'components.toolbar.page-title-reset',
                    'components.toolbar.page-title-signin'].includes($route.name)"
            id="help"
            data-v-tour-firstlogin-step="1"
            data-v-tour-interface-step="14"
            @click="openDialog('tour')"
          >
            mdi-help-circle
          </v-icon>
        </v-flex>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import store from '../../store'
import VueCookie from 'vue-cookie'
import settingAPI from '@/api/setting'
import ValidateLocaleFR from 'vee-validate/dist/locale/fr'
import ValidateLocaleEN from 'vee-validate/dist/locale/en'

export default {
  data: function () {
    return {
      userMenu: false,
      title: null,
      isMenuButton: false,
      isToolbarItem: false,
      isLoggedIn: false,
      validatorDictionaries: {
        'en': ValidateLocaleEN,
        'fr': ValidateLocaleFR
      }
    }
  },
  computed: {
    ...mapState('user', ['accessToken', 'userInfo', 'isConnected']),
    ...mapState('credit', ['credits'])
  },
  watch: {
    '$route' (val) {
      this.title = val.name
      this.isLoggedIn = store.getters['user/isConnected']()
    },
    // Dès qu'une mise à jour sur le user est fait, on met à jour les crédits
    userInfo (value) {
      if (value) {
        // charger les crédits
        this.refreshCredits()
        // afficher le rappel du tuto si besoin
        if (VueCookie.get('tour-info') === null) {
          const expire = new Date(Date.now())
          expire.setMonth(expire.getMonth() + 2)
          VueCookie.set('tour-info', 'tour done', {expires: expire})
          this.$nextTick(function () {
            // eslint-disable-next-line dot-notation
            this.$tours['firstLogin'].start()
          })
        }
      }
      this.isLoggedIn = store.getters['user/isConnected']()
      this.loadAndSetPreferredLangage()
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.isLoggedIn = store.getters['user/isConnected']()
    this.loadAndSetPreferredLangage()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapActions('credit', { refreshCredits: 'refreshCredits' }),
    ...mapActions('dialog', ['openDialog']),
    onClickBtn () {
      this.$store.state.app.drawer = !this.$store.state.app.drawer
    },
    onResponsiveInverted () {
      // isMenuButton responsive
      this.isMenuButton = window.innerWidth < 991

      // isToolbarItems responsive
      this.isToolbarItem = window.innerWidth > 425
    },
    logOut () {
      this.$router.push('/page-connexion')
    },
    changeLocale (locale) {
      if (this.isSupportedLanguage(locale)) {
        this.$i18n.locale = locale
        this.$validator.localize(locale, this.validatorDictionaries[locale])
      }
    },
    updatePreferredLanguage (newLanguage) {
      if (this.isSupportedLanguage(newLanguage)) {
        this.changeLocale(newLanguage)
        if (this.isLoggedIn) {
          settingAPI.updatePreferredLanguage(this.accessToken, newLanguage)
        } else {
          const expire = new Date()
          expire.setFullYear(expire.getFullYear() + 1)
          VueCookie.set('locale', newLanguage, {expires: expire, domain: process.env.VUE_APP_domain_cookie})
        }
      }
    },
    loadAndSetPreferredLangage () {
      if (this.isLoggedIn) {
        settingAPI.getPreferredLanguage(this.accessToken)
            .then((language) => {
              if (this.$i18n.locale !== language) {
                this.changeLocale(language)
              }
            })
      } else {
        const lang = VueCookie.get('locale')
        if (lang !== null) {
          this.updatePreferredLanguage(lang)
        }
      }
    },
    isSupportedLanguage(lang) {
      return lang != null && lang.length === 2 && ['fr', 'en'].includes(lang) && this.$i18n.locale !== lang
    }
  }
}
</script>
<style>
#core-app-bar {
  max-height: 64px;
}

#help {
  color: #95C11F;
  margin-left: 10px;
}

.v-app-bar-title {
  width: 75%;
}

a.toolbar-items {
  padding: 10px;
}
</style>
