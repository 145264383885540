<template>
  <span>
    <template v-if="!taxRate">
      {{ $t("components.invoice.vatDisplay.noData") }}.
    </template>
    <template v-else-if="taxRate.countryCode.length == 0 && taxRate.taxPercentage == 0">
      {{ $t("components.invoice.vatDisplay.outOfEU") }}.
    </template>
    <template v-else-if="taxRate.countryCode == 'EU_AUTO' && taxRate.taxPercentage == 0">
      {{ $t("components.invoice.vatDisplay.EUSelfVat") }}.
    </template>
    <template v-else>
      {{ $t("components.invoice.vatDisplay.appliedVat") }}: <b>{{ countries.find(x => x.code === taxRate.countryCode.toLowerCase()).name }} ({{ taxRate.taxPercentage }}%)</b>.
    </template>
    <span>
      {{ $t("components.invoice.vatDisplay.toAddressEdition") }}
      <router-link :to="{ path: '/page-user-profile', hash: '#invoice-address' }">
        <b>{{ $t("components.invoice.vatDisplay.toAddressEditionAction") }}</b>
      </router-link>.
    </span>
  </span>
</template>
<script>
  export default {
    props: {
      taxRate: {
        type: Object,
        default: null
      },
      countries: {
        type: Array,
        default: []
      }
    }
  }
</script>
