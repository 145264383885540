<template>
  <material-card
    class="validate_unitary"
    icon="mdi-chart-bar"
    :title="$t('views.home.validateUnitary.title')"
    color="primary"
  >
    <v-row
      align-content="start"
      style="align-items: center"
    >
      <v-col
        :cols="12"
        align="center"
      >
        <v-select
          v-model="selectModel"
          class="select"
          :items="numberOfWeeks"
          item-text="text"
          item-value="value"
          :label="$t('views.home.validateUnitary.selectWeeks')"
        />
      </v-col>
    </v-row>
    <div class="chart-container">
      <canvas id="validateUnitary-week-curve" />
    </div>
  </material-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Chart from 'chart.js'
import ValidateUnitaryCurveCharts from './ValidateUnitaryCurveCharts'

const validateUnitaryCurveCharts = new ValidateUnitaryCurveCharts()

export default {
  data: function () {
    return {
      selectModel: 13,
      numberOfWeeks: [],
      myChart: null
    }
  },
  computed: {
    ...mapState('validateUnitary', ['weekStatistics']),
    locale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    selectModel (value) {
      this.updateCurveChart()
    },
    locale: function () {
      this.generateNumberOfWeeksOptions()
      this.createCurveChart()
    }
  },
  created () {
    this.generateNumberOfWeeksOptions()
    this.createCurveChart()
    window.addEventListener('resize', this.switchLegendOnCharts)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.switchLegendOnCharts)
  },
  methods: {
    ...mapActions('validateUnitary', ['fetchWeekStatistics']),
    async updateCurveChart () {
      await this.fetchWeekStatistics(this.selectModel)
      this.myChart.data = validateUnitaryCurveCharts.generateCurve(this.weekStatistics, this)
      this.myChart.update()
    },
    async createCurveChart () {
      // on fait l'appel pour récupérer les données statistiques : this.weekStatistics
      await this.fetchWeekStatistics(this.selectModel)
      // construction du graphique
      const ctx = document.getElementById('validateUnitary-week-curve')
      if (ctx) {
        // eslint-disable-next-line no-unused-vars
        this.myChart = new Chart(ctx, {
          type: 'line',
          data: validateUnitaryCurveCharts.generateCurve(this.weekStatistics, this),
          options: {
            legend: {
              display: window.innerWidth > 420
            },
            showLine: true,
            stepped: true,
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                // Permet d'afficher autre chose que les données brut par défaut
                label: function (tooltipItem, data) {
                  const item = data.datasets[tooltipItem.datasetIndex]
                  return `${item.label} : ${item.count[tooltipItem.index]}`
                }
              }
            },
            hover: {
              mode: 'index'

            },
            scales: {
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Date'
                }
              }],
              yAxes: [{
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: '%'
                },
                ticks: {
                  max: 100,
                  min: 0
                }
              }]
            }
          }
        })
      }
    },

    switchLegendOnCharts () {
      if (this.myChart !== null) {
        this.myChart.legend.options.display = window.innerWidth > 420
      }
    },
    generateNumberOfWeeksOptions () {
      this.numberOfWeeks = [{
        text: this.$t('views.home.validateUnitary.graph.title.1'),
        value: 1
      }, {
        text: this.$t('views.home.validateUnitary.graph.title.2'),
        value: 2
      }, {
        text: this.$t('views.home.validateUnitary.graph.title.4'),
        value: 4
      }, {
        text: this.$t('views.home.validateUnitary.graph.title.13'),
        value: 13
      }, {
        text: this.$t('views.home.validateUnitary.graph.title.26'),
        value: 26
      }, {
        text: this.$t('views.home.validateUnitary.graph.title.52'),
        value: 52
      }
      ]
    }
  }
}
</script>
