<template>
  <!-- Tableau affichant les listes d'emails -->
  <v-data-table
      :headers="tableHeaders"
      :items="lists"
      :search="search"
      data-v-tour-campaigns-step="6"
      sort-by="creationTime"
      sort-desc
      update:
  >
    <template v-slot:no-data>
      <span>{{ $t('views.campaigns.emptyList') }}</span>
    </template>
    <!-- Customisation de certains items dans les colonnes -->
    <template v-slot:item.emailCount="{ item }">
      {{ $n(item.emailCount) }}
    </template>
    <template v-slot:item.creationTime="{ item }">
      {{ $d(new Date(item.creationTime)) }}
    </template>
    <template v-slot:item.status="{ item }">
      <div class="inline-parent">
        <span
          v-if="statusLength && statusLength > 0"
          :class="{ validated: (status[item.id] === 'terminee') }"
          class="inline"
        >
          {{ $t('views.campaigns.shortStatus.' + getShortStatus(item)) }}
        </span>
        <v-progress-circular
          v-else
          color="primary"
          indeterminate
          size="18"
        />
      </div>
    </template>
    <template v-slot:item.name="{ item }">
      <div
        v-if="item.emailCount > 0"
        class="cell-action"
        @click="seeCampaign(item)"
      >
        {{ item.name }}
      </div>
    </template>
    <template v-slot:item.download="{ item }">
      <div v-if="item.emailsDeleted">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              mdi-cloud-off-outline
            </v-icon>
          </template>
          <span>{{ $t('views.campaigns.table.buttonNoDownloadArchived') }}</span>
        </v-tooltip>
      </div>
      <div
        v-else-if="item.emailCount > 0 && !downloadFilesStatus[item.id]"
        class="cell-action"
        @click="downloadList(item.id, item.name, item.fileExtension)"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              mdi-cloud-download
            </v-icon>
          </template>
          <span>{{ $t('views.campaigns.table.buttonDownload') }}</span>
        </v-tooltip>
      </div>
      <div v-else-if="item.emailCount > 0 && downloadFilesStatus[item.id]">
        <v-progress-circular
          color="primary"
          indeterminate
          size="18"
        />
      </div>
    </template>
    <template v-slot:item.seeCampaign="{ item }">
      <div
        v-if="item.emailCount > 0"
        class="cell-action"
        @click="seeCampaign(item)"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
              color="primary"
              x-large
              v-on="on"
            >
              mdi-email-search
            </v-icon>
          </template>
          <span>{{ $t('views.campaigns.table.buttonCampaign') }}</span>
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import emailAPI from '@/api/emails'
import {mapState} from 'vuex'

export default {
    props: {
      search: {
        type: String,
        default: ''
      },
      status: {
        default: () => ([])
      }
    },
    data: function () {
      return {
        // header des listes de mails
        tableHeaders: [],
        downloadFilesStatus: {}
      }
    },
    computed: {
      ...mapState('user', ['accessToken']),
      ...mapState('list', ['lists']),
      statusLength() {
        return Object.keys(this.status).length || 0
      }
    },
    watch: {
      '$i18n.locale'() {
        this.generateTableHeaders()
      }
    },
    mounted() {
      this.generateTableHeaders()
      for (let i = 0; i < this.lists.length; i++) {
        this.$set(this.downloadFilesStatus, this.lists[i].id, false)
      }
    },
    methods: {
      generateTableHeaders() {
        this.tableHeaders = [
          {text: this.$t('views.campaigns.table.name'), value: 'name', class: 'campaigns--table_list--col_name'},
          {
            text: this.$t('views.campaigns.table.description'),
            value: 'description',
            sortable: false,
            class: 'campaigns--table_list--col_description'
          },
          {text: this.$t('views.campaigns.table.emailCount'), value: 'emailCount'},
          {text: this.$t('views.campaigns.table.created'), value: 'creationTime'},
          {text: this.$t('views.campaigns.table.status'), value: 'status'},
          {text: '', value: 'download', sortable: false}, // téléchargement de la liste
          {text: this.$t('views.campaigns.table.detail'), value: 'seeCampaign', sortable: false} // voir la campagne
        ]
      },
      seeCampaign(list) {
        this.$emit('seeCampaign', list)
      },
      getShortStatus(list) {
        if (list.emailsDeleted) {
          return 'archived'
        } else {
          return this.status[list.id] ? this.status[list.id] : 'not-validated'
        }
      },
      downloadList (listId, listName, fileExtension) {
        this.$set(this.downloadFilesStatus, listId, true)
        const context = this
        emailAPI.getListEmailsWithLimit(this.accessToken, listId, -1)
          .then(emails => {
            const extension = (fileExtension != null && fileExtension.length > 0) ? fileExtension.toLowerCase() : 'txt'
            let mimeType
            switch (extension) {
              case 'csv':
                mimeType = 'text/csv'
                break
              case 'txt':
              default:
                mimeType = 'text/plain'
            }
            const blob = new Blob([emails.join('\n')], { type: mimeType })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = listName + '.' + extension
            link.click()
            context.$set(this.downloadFilesStatus, listId, false)
          })
      }
    }
  }
</script>
<style>
  span.validated {
    color: #95C11F;
  }
  .inline-parent {
    white-space: nowrap;
  }
  .inline {
    display: inline-block;
  }
  i.mdi-cloud-off-outline {
    cursor: not-allowed;
  }
</style>
