export default {
  sanitizeEmailList (emailList) {
    const newList = []
    const emailRegex = new RegExp('.+@.+')
    const splited = emailList.split(/\r\n|\n|\r/)
    const splitedLen = splited.length
    for (let i = 0; i < splitedLen; i++) {
      // on test si c'est bien un email valide avant de l'ajouter
      if (emailRegex.test(splited[i])) {
        newList.push(splited[i])
      }
    }
    return newList
  },

  sanitizeEmailListTemp (emailList) {
    const newList = []
    // https://stackoverflow.com/questions/8125709/javascript-how-to-split-newline
    const splited = emailList.split(/\r\n|\n|\r/)
    const splitedLen = splited.length
    for (let i = 0; i < splitedLen; i++) {
      // on test si c'est bien un email valide avant de l'ajouter
      if ((splited[i].match(/@/g) || []).length === 1) {
        newList.push(splited[i])
      }
    }
    return newList
  }
}
