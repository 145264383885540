<template>
  <!-- modal de visualisation d'une campagne -->
  <v-dialog
    id="viewCampaign"
    :value="validationLauncherProgressDialog"
    content-class="no-shadow viewCampaign__dialog"
    @click:outside="$emit('close')"
  >
    <template v-if="list != null && (!isLoading || list.emailCount < listSkeletonLoaderThreshold)">
      <material-card
        :key="validationLauncherProgressDialog__KEY"
        :date="campaignDate"
        :text="list.description"
        :title="list.name + ((list.emailsDeleted)?' - '+$t('views.campaigns.validation.cardTitleArchived'):'')"
        color="primary"
        class="viewCampaign"
      >
        <!-- enchainement de template dans la dialog pendant le processus de validation de la liste -->
        <template v-if="campaign != null && campaign.campaignStatus">
          <v-container fluid>
            <!-- CASE : Campagne qui n'a pas été lancée -->
            <v-row
              v-if="campaign.campaignStatus === 'not launch'"
              no-gutters
            >
              <v-col
                v-if="list.emailsDeleted"
                class="text-center"
                :cols="12"
              >
                <h2>
                  {{ $t('views.campaigns.validation.cantLaunch.title') }}
                </h2>
                <p>
                  {{ $t('views.campaigns.validation.cantLaunch.paragraph') }}
                </p>
                <campaigns-remove-list-button
                  :list-id="list.id"
                  @confirmation="removeListConfirmed"
                />
              </v-col>
              <v-col
                v-else-if="!templateValidateListPending"
                :cols="12"
              >
                <div class="modale-not-launch-campaign">
                  <h2>
                    {{ $t('views.campaigns.validation.launchValidationContent') }}
                    <campaigns-validate-weather-icon :validate-weather="validateWeather" />
                  </h2>
                  <div>
                    {{ $t('views.campaigns.validation.possibleAction') }}
                  </div>
                  <div>
                    <v-btn
                      color="primary"
                      @click="getUserConfirmation()"
                    >
                      {{ $t('views.campaigns.validation.launchValidation', [list.emailCount]) }}
                      <v-icon right>
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <v-tooltip
                      v-if="!predictiveLaunched"
                      color="#fff"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          color="primary"
                          outlined
                          v-on="on"
                          @click="launchPredictive()"
                        >
                          {{ $t('views.campaigns.validation.launchPredictive') }}
                          <v-icon right>
                            mdi-play-circle-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t('views.campaigns.validation.whatIsPredictive') }}
                    </v-tooltip>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="primary"
                    />
                  </div>
                  <div class="text-left">
                    <campaigns-remove-list-button
                      :list-id="list.id"
                      @confirmation="removeListConfirmed"
                    />
                    <campaigns-archive-list-button
                      v-if="!list.emailsDeleted"
                      :list-id="list.id"
                      @confirmation="archiveListConfirmed"
                    />
                  </div>
                </div>
              </v-col>
              <v-col
                v-else
                :cols="12"
              >
                <div class="circular-loader">
                  <v-progress-circular
                    color="primary"
                    indeterminate
                  />
                  <span> {{ $t('views.campaigns.validation.inProgress') }}</span>
                </div>
              </v-col>
            </v-row>
            <!-- CASE : Campagne non lancée, ayant une prédictive en cours ou terminée -->
            <v-row v-else-if="campaign.campaignStatus === 'preanalyse'">
              <v-col align="center">
                <campaigns-predictive-viewer :predictive="predictive" />
                <div class="mt-5" />
                <hr>
                <div class="mb-5" />
                <div
                  v-if="!list.emailsDeleted"
                  class="modale-not-launch-campaign"
                >
                  <h2>
                    {{ $t('views.campaigns.validation.launchValidationContent') }}
                    <campaigns-validate-weather-icon :validate-weather="validateWeather" />
                  </h2>
                  <div>
                    {{ $t('views.campaigns.validation.possibleActionNoPredictive') }}
                  </div>
                  <v-btn
                    color="primary"
                    @click="getUserConfirmation()"
                  >
                    {{ $t('views.campaigns.validation.launchValidation', [list.emailCount]) }}
                    <v-icon right>
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-btn>
                  <campaigns-remove-list-button
                    :list-id="list.id"
                    @confirmation="removeListConfirmed"
                  />
                  <campaigns-archive-list-button
                    :list-id="list.id"
                    @confirmation="archiveListConfirmed"
                  />
                </div>
                <div v-else>
                  <h2>
                    {{ $t('views.campaigns.validation.cantLaunch.title') }}
                  </h2>
                  <p>
                    {{ $t('views.campaigns.validation.cantLaunch.paragraph') }}
                  </p>
                  <campaigns-remove-list-button
                    :list-id="list.id"
                    @confirmation="removeListConfirmed"
                  />
                </div>
              </v-col>
            </v-row>
            <!-- CASE : Campagne pour laquelle une erreur est survenue -->
            <v-row v-else-if="campaign.campaignStatus === 'error'">
              <v-col align="center">
                {{ $t('global.defaultTechnicalError') }}
              </v-col>
            </v-row>
            <!-- CASE : Campagne avec l'un des statut suivant
                            [initialisation|terminee|annulee|abandonnee|en_attente|queued|encours]
                        -->
            <v-row v-else>
              <v-col v-if="campaign.stats && ['annulee', 'abandonnee'].includes(campaign.stats.state)">
                <p class="text-center">
                  {{ $t(`views.campaigns.status.${campaign.stats.state}`) }}
                </p>
                <v-row justify="right">
                  <campaigns-remove-list-button
                    :validated-context="true"
                    :list-id="list.id"
                    @confirmation="removeListConfirmed"
                  />
                  <v-btn
                    color="secondary"
                    text
                    @click="$emit('close')"
                  >
                    {{ $t('global.close') }}
                  </v-btn>
                </v-row>
              </v-col>
              <!-- CASE : terminee-->
              <v-col
                v-else-if="campaign.stats && ((campaign.stats.state === 'terminee' && campaign.stats.readyToPrintClient))"
              >
                <!--  Désactivation du bouton en attendant le fix -->
                <!--  <v-btn @click="downloadCampaignListSpecific(campaign.engineName, 'full-archive')"-->
                <!--  color="primary">-->
                <!--  <v-icon style="margin-right: 5px">mdi-cloud-download</v-icon>-->
                <!--  {{ $t('views.campaigns.full.download') }}-->
                <!--  </v-btn>-->
                <campaigns-result-tabs
                  :validation-launcher-progress-dialog="validationLauncherProgressDialog"
                  :selected-campaign="campaign"
                  @close="$emit('close')"
                  @refreshStats="$emit('refreshStats')"
                />

                <v-row justify="center">
                  <campaigns-remove-list-button
                    :validated-context="true"
                    :list-id="list.id"
                    @confirmation="removeListConfirmed"
                  />
                  <campaigns-archive-list-button
                    v-if="!list.emailsDeleted"
                    :validated-context="true"
                    :list-id="list.id"
                    @confirmation="archiveListConfirmed"
                  />
                  <v-btn
                    color="secondary"
                    text
                    @click="$emit('close')"
                  >
                    {{ $t('global.close') }}
                  </v-btn>
                </v-row>
              </v-col>
              <v-col v-else-if="campaign.stats">
                <campaigns-validation-progression-bar
                  :campaign="campaign"
                  @refreshCampaign="$emit('refresh')"
                />
              </v-col>
              <v-col v-else>
                {{ $t('views.campaigns.status.error') }}
              </v-col>
            </v-row>
          </v-container>
        </template>
        <!-- Loader à l'intérieur de la modal de visualisation de campagne-->
        <template v-else>
          <div class="circular-loader">
            <v-progress-circular
              color="primary"
              indeterminate
            />
          </div>
        </template>
      </material-card>
    </template>
    <template v-else-if="list != null">
      <material-card
        :text="list.description"
        :title="list.name + ((list.emailsDeleted)?' - '+$t('views.campaigns.validation.cardTitleArchived'):'')"
        color="primary"
        class="viewCampaign"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="table"
          max-width="75%"
        />
      </material-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import predictiveAPI from '@/api/predictives'

export default {
  props: {
    campaign: {
      type: Object,
      default: null
    },
    list: {
      type: Object,
      default: null
    },
    validateWeather: {
      type: Object,
      default: null
    },
    validationLauncherProgressDialog: {
      type: Boolean,
      default: false
    },
    isUserConfirmValidation: {
      type: Boolean,
      default: false
    },
    templateValidateListPending: {
      type: Boolean,
      default: false
    },
    validationLauncherProgressDialog__KEY: {
      type: Number,
      default: null
    },
    templateValidateList__KEY: {
      type: Number,
      default: null
    },
    validationDisabled: {
      type: Boolean,
      default: false
    },
    validationDisabledEndDateFormated: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      predictive: null,
      predictiveLaunched: false,
      listSkeletonLoaderThreshold: 500000
    }
  },
  computed: {
    ...mapState('user', ['accessToken']),
    isLoading: function () {
      return this.list === null || this.campaign === null || (this.campaign.campaignStatus === undefined)
    },
    campaignDate: function () {
      if (this.campaign == null || !this.campaign.created) {
        return ''
      } else if (this.campaign.campaignStatus === 'preanalyse') {
        return this.$t('views.campaigns.validation.lastPredictive', [this.$d(new Date(this.campaign.created))])
      } else {
        return this.$t('views.campaigns.validation.lastValidation', [this.$d(new Date(this.campaign.created))])
      }
    }
  },
  watch: {
    isLoading (newVal) {
      // si chargé et preanalyse et predictive non chargée, on le fait
      if (!newVal && this.campaign !== null && this.campaign.campaignStatus === 'preanalyse') {
        this.loadPredictive()
      }
    }
  },
  methods: {
    getUserConfirmation () {
      this.$emit('getUserConfirmation')
    },
    removeListConfirmed () {
      this.$emit('removeListConfirmed')
    },
    archiveListConfirmed () {
      this.$emit('archiveListConfirmed')
    },
    launchPredictive () {
      // empéchement si validation désactivée
      if (this.validationDisabled) {
        this.$toast(this.$t('views.campaigns.settings.validationDisabledToast', [this.validationDisabledEndDateFormated]), {
          icon: 'mdi-alert',
          color: 'red'
        })
        return
      }

      this.predictiveLaunched = true
      const context = this
      predictiveAPI.createPredictive(this.accessToken, this.list.id)
          .then(function () {
            context.predictiveLaunched = false
            context.$emit('refresh')
          })
    },
    loadPredictive: function () {
      const context = this
      predictiveAPI.getPredictiveByCampaign(this.accessToken, this.campaign.id)
          .then(function (result) {
            context.predictive = result
            // remise à 0 du loader temporaire
            context.predictiveLaunched = false
            // refresh de la predictive, toutes les 45 secondes
            if (['en_attente', 'queued', 'encours', 'en cours'].includes(context.predictive.status)) {
              setTimeout(context.loadPredictive, 45 * 1000)
            }
          })
    }
  }
}
</script>
