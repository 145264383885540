<template>
  <div class="text-center">
    <v-dialog
      v-model="dialogChangelog().isOpen"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ $t("components.footer.changelog.name") }}
        </v-card-title>

        <v-card-text v-if="data != null">
          <div v-if="environment != 'production'">
            <h4>Not released (section visible uniquement en recette)</h4>
            <ul>
              <li
                v-for="(category,name) in data.unreleased.content"
                :key="name"
              >
                {{
                  ($t("components.footer.changelog." + name)).charAt(0).toUpperCase() +
                    ($t("components.footer.changelog." + name)).slice(1)
                }}
                <ul>
                  <li
                    v-for="line in category"
                    :key="line"
                  >
                    {{ line }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div
            v-for="(release,version) in data.released"
            :key="version"
          >
            <h4>v{{ version }} - {{ release.title }} ({{ $d(new Date(release.date)) }})</h4>
            <ul>
              <li
                v-for="(category,name) in release.content"
                v-if="category.length > 0"
                :key="name"
              >
                {{
                  ($t("components.footer.changelog." + name)).charAt(0).toUpperCase() +
                    ($t("components.footer.changelog." + name)).slice(1)
                }}
                <ul>
                  <li
                    v-for="line in category"
                    :key="line"
                  >
                    {{ line }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="closeDialog('changelog')"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rawDataFr from '../../../changelog-fr.json'
import rawDataEn from '../../../changelog-en.json'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      environment: process.env.NODE_ENV,
      dataEn: {},
      dataFr: {},
      data: null
    }
  },
  computed: {
    locale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    locale: function () {
      this.data = this.locale === 'fr' ? this.dataFr : this.dataEn
    }
  },
  created () {
    this.dataFr = rawDataFr
    this.dataEn = rawDataEn
    this.data = this.locale === 'fr' ? this.dataFr : this.dataEn
  },
  methods: {
    ...mapActions('dialog', ['closeDialog']),
    ...mapGetters('dialog', ['dialogChangelog'])
  }
}
</script>
