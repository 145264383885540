import VueJwtDecode from 'vue-jwt-decode'

export default {
    resetToken (state) {
        state.refreshToken = null
        state.refreshTokenPayload = null
        state.accessToken = null
        state.accessTokenPayload = null
        state.userInfo = null
        this._vm.$cookie.delete('refreshToken', { domain: process.env.VUE_APP_domain_cookie })
        this._vm.$cookie.delete('accessToken', { domain: process.env.VUE_APP_domain_cookie })
    },
    setRefreshToken (state, refreshToken) {
        state.refreshToken = 'Refresh ' + refreshToken
        state.refreshTokenPayload = VueJwtDecode.decode(refreshToken)

        state.expireTimeRefreshCookie = new Date(state.refreshTokenPayload.expires)
        this._vm.$cookie.set('refreshToken', refreshToken, { expires: state.expireTimeRefreshCookie, domain: process.env.VUE_APP_domain_cookie })
    },
    setAccessToken (state, accessToken) {
        state.accessToken = 'Bearer ' + accessToken
        state.accessTokenPayload = VueJwtDecode.decode(accessToken)

        state.expireTimeAccessCookie = new Date(state.accessTokenPayload.expires)
        this._vm.$cookie.set('accessToken', accessToken, { expires: state.expireTimeAccessCookie, domain: process.env.VUE_APP_domain_cookie })
    },
    setUserInfo (state, userInfo) {
        state.userInfo = userInfo
    },
    setSettingEmailCampaignEnd (state, setting) {
        state.settingEmailCampaignEnd = setting
    },
    setSettingEmailCampaignEndToken (state, setting) {
        state.settingEmailCampaignEndToken = setting
    },
    setSettingWhiteLabelImageUrl (state, setting) {
        state.settingWhiteLabelImageUrl = setting
    },
    setSettingEmailCampaignAutoDeletion (state, setting) {
        state.settingEmailCampaignAutoDeletion = setting
    },
    setSettingCampaignAutoDeletionDelay (state, setting) {
        state.settingCampaignAutoDeletionDelay = setting
    }
}
