<template>
  <v-footer
    id="footer"
    absolute
    class="grey lighten-3"
    data-v-tour-interface-step="13"
  >
    <v-row class="text-center">
      <v-col>
        <a :href="cguLink" class="small-text" target="_blank">
          {{ $t('components.footer.tos') }}
        </a>
      </v-col>
      <v-col>
        <a :href="dataTreatmentLink" class="small-text" target="_blank">
          {{ $t('components.footer.data-treatment') }}
        </a>
      </v-col>
      <v-col>
        <a
          class="small-text"
          :href="landingPageUrl"
          target="_blank"
        >
          {{ $t('components.footer.landing-page') }}
        </a>
      </v-col>
      <v-col>
        <a
          class="small-text"
          :href="developerUrl"
          target="_blank"
          data-v-tour-unitary-step="11"
        >
          {{ $t('components.footer.dev-portal') }}
        </a>
      </v-col>
      <v-col>
        <a
          class="small-text"
          data-v-tour-beta-step="4"
          @click="openDialog('changelog')"
        >
          v{{ version }}
        </a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      developerUrl: process.env.VUE_APP_Developer_Url,
      landingPageUrl: process.env.VUE_APP_Landing_Page_Url
    }
  },
  computed: {
    cguLink() {
      return `${process.env.VUE_APP_Landing_Page_Url}${this.$i18n.locale}/CGU`
    },
    dataTreatmentLink() {
      return `${process.env.VUE_APP_Landing_Page_Url}${this.$i18n.locale}/data-treatment`
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog'])
  }
}
</script>

<style lang="scss">
  .v-card__text, .v-card__title {
      word-break: normal !important;
  }

  #footer {
    .col {
      padding: 3px;

      a {
        color: #3C3C3B;
        font-size: 12px;
      }
    }
  }
</style>
