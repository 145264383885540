<template>
  <div
    id="tour-dialog"
    class="text-center"
  >
    <v-dialog
      v-model="dialogTour().isOpen"
      max-width="80%"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ $t('tour.dialog.title') }}
        </v-card-title>

        <v-card-text class="no-padding-x">
          <div class="container">
            <v-row>
              <v-col
                v-for="cat in categories"
                :key="cat.title"
                class="choice"
                sm="4"
                cols="12"
                @click="cat.click()"
              >
                <h4 class="text-center">
                  {{ cat.title }}
                </h4>
                <p class="text-center">
                  {{ cat.text }}
                </p>
                <v-img :src="cat.img" />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="closeDialog('tour')"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
      data: function () {
        return {
          categories: []
        }
      },
      mounted() {
        this.generateCategoriesData()
      },
      watch: {
        '$i18n.locale'() {
          this.generateCategoriesData()
        }
      },
      methods: {
        ...mapActions('dialog', ['closeDialog']),
        ...mapGetters('dialog', ['dialogTour']),
        startInterface() {
          this.closeAlltour()
          this.closeDialog('tour')
          // eslint-disable-next-line dot-notation
          this.$tours['interface-global'].start()
        },
        startList() {
          this.closeAlltour()
          this.closeDialog('tour')
          this.$router.push('/page-campaigns?activateTour=campaigns&step=start')
        },
        startUnitary () {
          this.closeAlltour()
          this.closeDialog('tour')
          this.$router.push('/page-validate-unitary?activateTour=unitary&step=start')
        },
        startBeta () {
          this.closeAlltour()
          this.closeDialog('tour')
          // eslint-disable-next-line dot-notation
          this.$tours['beta'].start()
        },
        closeAlltour () {
          // eslint-disable-next-line dot-notation
          this.$tours['interface-global'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['interface-home'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['interface-pricing'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['interface-credits-history'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['interface-user-profile'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['interface-faq'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['interface-contact'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['interface-global-end'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['campaigns'].stop()
          // eslint-disable-next-line dot-notation
          this.$tours['unitary'].stop()
        },
        generateCategoriesData() {
          this.categories = [{
            'title': this.$t('tour.dialog.categories.interface.title'),
            'text': this.$t('tour.dialog.categories.interface.description'),
            'img': '/img/computer.png',
            'click': this.startInterface
          },
            {
              'title': this.$t('tour.dialog.categories.list.title'),
              'text': this.$t('tour.dialog.categories.list.description'),
              'img': '/img/list-validation.png',
              'click': this.startList
            },
            {
              'title': this.$t('tour.dialog.categories.unitary.title'),
              'text': this.$t('tour.dialog.categories.unitary.description'),
              'img': '/img/deep-validation.png',
              'click': this.startUnitary
            }]
        }
      }
    }
</script>
<style>
  .choice {
    border-radius: 10px;
  }
  .choice:hover {
    background-color: #f8fbef;
  }
  .no-padding-x {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
</style>
