<template>
  <v-dialog
    v-model="validationLauncherConfirmationDialog"
    persistent
    max-width="500px"
  >
    <template v-if="list !== null">
      <material-card
        center
        light
      >
        <v-card-text align="center">
          {{ $t('views.campaigns.validation.confirmationContent', [list.emailCount, credits]) }}
        </v-card-text>
        <v-card-text align="center">
          <template v-if="templateRedirectionBuyCredit">
            {{ $t('views.campaigns.validation.confirmationContentNotEnough') }}
          </template>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-btn
              color="warning"
              text
              @click="userCanceled"
            >
              {{ $t('views.campaigns.validation.cancel') }}
            </v-btn>
            <v-spacer />
            <template v-if="templateLaunchCampaign">
              <v-btn
                color="primary"
                @click="userConfirmed"
              >
                {{ $t('views.campaigns.validation.confirm') }}
              </v-btn>
            </template>
            <template
              v-if="templateRedirectionBuyCredit"
              color="primary"
            >
              <v-btn :to="{path:'/page-pricing'}">
                {{ $t('views.campaigns.validation.buyCredit') }}
              </v-btn>
            </template>
          </v-row>
        </v-card-actions>
      </material-card>
    </template>
  </v-dialog>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            list: {
                type: Object,
                default: null
            },
            validationLauncherConfirmationDialog: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState('credit', ['credits']),
            templateRedirectionBuyCredit () {
                return !this.hasEnoughCredit(this.list.emailCount)
            },
            templateLaunchCampaign () {
                return this.hasEnoughCredit(this.list.emailCount)
            }
        },
        methods: {
            close () {
                this.$emit('close')
            },
            // Sur la base de 1 credit = 1 mail
            hasEnoughCredit (emailCount) {
                return this.credits >= emailCount
            },
            userCanceled () {
                this.close()
                this.$emit('userCanceled')
            },
            userConfirmed () {
                this.close()
                this.$emit('userConfirmed', this.list.id)
            }
        }
    }
</script>
