import campaignsAPI from '@/api/campaigns'
import tokenCampaignAPI from '@/api/campaign-via-token'
import listAPI from '@/api/lists'

export default {
    async getCampaignsByListAction ({ commit, state, rootState }, listId) {
        if (rootState.user.accessToken) {
            try {
                const campaigns = await campaignsAPI.getCampaignsByList(rootState.user.accessToken, listId)
                const list = await listAPI.getListFromId(rootState.user.accessToken, listId)
                commit('setCampaigns', campaigns)
                commit('setCampaignFirst', { campaign: campaigns[0], list: list })
            } catch (error) {
                console.error('campaigns/actions.js/getCampaignsByList: ' + error)
            }
        } else {
            console.warn('problème de token')
        }
    },

    async getCampaignStatsFirstCampaignAction ({ commit, state, rootState }) {
        if (rootState.user.accessToken && rootState.campaign.campaignFirst) {
            try {
                const statistiques = await campaignsAPI.getCampaignStats(rootState.user.accessToken, rootState.campaign.campaignFirst.engineName)
                commit('setStatistique', statistiques)
                // Récupération de la progression de la campagne uniquement pour les statuts
                // 'queued' || 'en_attente' || 'encours'
                if (statistiques.state === 'queued' ||
                    statistiques.state === 'en_attente' ||
                    statistiques.state === 'en cours'
                ) {
                    const responseProgressTime = await campaignsAPI.getCampaignProgressTime(rootState.user.accessToken, rootState.campaign.campaignFirst.engineName)
                    commit('setProgressTime', responseProgressTime)
                } else {
                    commit('setProgressTime', null)
                }
            } catch (error) {
                console.error('campaigns/actions.js/getCampaignStatsFirstCampaignAction: ' + error)
            }
        } else {
            console.warn('problème de token')
        }
    },
    async getCampaignStatsByTokenAction ({ commit, state, rootState }, token) {
      try {
          const campaign = await tokenCampaignAPI.getCampaign(token)
          const list = await tokenCampaignAPI.getList(token)
          commit('setCampaignFirst', { campaign: campaign, list: list })
          const statistiques = await tokenCampaignAPI.getEngineCampaign(token)
          commit('setStatistique', statistiques)
          commit('setProgressTime', null)
      } catch (error) {
          console.error('campaigns/actions.js/getCampaignStatsByTokenAction: ' + error)
      }
    },
    async getLatestCampaign ({ commit, state, rootState }) {
        if (rootState.user.accessToken) {
            await campaignsAPI.getLatest(rootState.user.accessToken).then(latestCampaign => {
                commit('setLatestCampaign', latestCampaign)
            }).catch((error, data) => {
              if (error) {
                console.error(error)
              }
                commit('setLatestCampaign', null)
            })
        } else {
            console.warn('problème de token')
        }
    },
    resetCampaign ({ commit, state, rootState }) {
        commit('setLatestCampaign', 'pending')
    },
    async fetchWeekStatistics ({ commit, state, rootState }, numberOfWeeks) {
        if (rootState.user.accessToken) {
            try {
                const weekStatistics = await campaignsAPI.getWeeksStatistics(rootState.user.accessToken, numberOfWeeks)
                commit('setWeekStatistics', weekStatistics)
            } catch (error) {
                console.error('campaigns/actions.js/fetchWeekStatistics: ' + error)
            }
        } else {
            console.warn('problème le token')
        }
    }
}
