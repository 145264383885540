<template>
  <div>
    <v-tour
      name="firstLogin"
      :steps="stepsFirstLogin"
      :options="optionsFirstLogin"
    />
    <v-tour
      name="interface-global"
      :steps="stepsInterfaceGlobal"
      :options="optionsInterface"
    >
      <!-- :callbacks="callbacksInterface" -->
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="toOtherTour(tour,'/page-home','interface','start')"
                >
                  {{ $t('tour.shared.next') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="interface-home"
      :steps="stepsInterfaceHome"
      :options="optionsInterface"
    >
      <!-- :callbacks="callbacksInterface" -->
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(tour.isFirst?interfaceHomeToGlobal(tour):tour.previousStep())"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(tour.isLast?toOtherTour(tour,'/page-pricing','interface','start'):tour.nextStep())"
                >
                  {{ $t('tour.shared.next') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="interface-pricing"
      :steps="stepsInterfacePricing"
      :options="optionsInterface"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(toOtherTour(tour,'/page-home','interface','end'))"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(toOtherTour(tour,'/page-credits-history','interface','start'))"
                >
                  {{ $t('tour.shared.next') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="interface-credits-history"
      :steps="stepsInterfaceHistory"
      :options="optionsInterface"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(tour.isFirst?toOtherTour(tour,'/page-pricing','interface','end'):tour.previousStep())"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(tour.isLast?toOtherTour(tour,'/page-user-profile','interface','start'):tour.nextStep())"
                >
                  {{ $t('tour.shared.next') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="interface-user-profile"
      :steps="stepsInterfaceProfile"
      :options="optionsInterface"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(tour.isFirst?toOtherTour(tour,'/page-credits-history','interface','end'):tour.previousStep())"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(tour.isLast?toOtherTour(tour,'/page-faq','interface','start'):tour.nextStep())"
                >
                  {{ $t('tour.shared.next') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="interface-faq"
      :steps="stepsInterfaceFaq"
      :options="optionsInterface"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(toOtherTour(tour,'/page-user-profile','interface','end'))"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="(toOtherTour(tour,'/page-contact','interface','start'))"
                >
                  {{ $t('tour.shared.next') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="interface-contact"
      :steps="stepsInterfaceContact"
      :options="optionsInterface"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="toOtherTour(tour,'/page-faq','interface','end')"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="interfaceContactToGlobalEnd(tour)"
                >
                  {{ $t('tour.shared.next') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="interface-global-end"
      :steps="stepsInterfaceGlobalEnd"
      :options="optionsInterface"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  v-if="!tour.isLast"
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  class="v-step__button"
                  @click.prevent="tour.isFirst?toOtherTour(tour,'/page-contact','interface','end'):tour.previousStep()"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  v-if="!tour.isLast"
                  class="v-step__button"
                  @click.prevent="tour.nextStep()"
                >
                  {{ $t('tour.shared.next') }}
                </button>
                <button
                  v-if="tour.isLast"
                  class="v-step__button"
                  @click.prevent="tour.stop()"
                >
                  {{ $t('tour.shared.stop') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="campaigns"
      :steps="stepsCampaigns"
      :options="optionsCampaigns"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div slot="actions">
              <div class="v-step__buttons">
                <button
                  v-if="!tour.isLast"
                  class="v-step__button"
                  @click.prevent="tour.stop"
                >
                  {{ $t('tour.shared.skip') }}
                </button>
                <button
                  v-if="!tour.isFirst"
                  class="v-step__button"
                  @click.prevent="campaignsPrevious(tour)"
                >
                  {{ $t('tour.shared.previous') }}
                </button>
                <button
                  v-if="!tour.isLast"
                  class="v-step__button"
                  @click.prevent="campaignsNext(tour)"
                >
                  {{ $t('tour.shared.next') }}
                </button>
                <button
                  v-if="tour.isLast"
                  class="v-step__button"
                  @click.prevent="tour.stop()"
                >
                  {{ $t('tour.shared.stop') }}
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="unitary"
      :steps="stepsUnitary"
      :options="optionsUnitary"
    />
  </div>
</template>
<script type="text/javascript">
import {mapMutations} from 'vuex'

export default {
    data: function () {
      return {
        stepsFirstLogin: [],
        optionsFirstLogin: {},
        stepsInterfaceGlobal: [],
        stepsInterfaceHome: [],
        stepsInterfacePricing: [],
        stepsInterfaceHistory: [],
        stepsInterfaceProfile: [],
        stepsInterfaceFaq: [],
        stepsInterfaceContact: [],
        stepsInterfaceGlobalEnd: [],
        optionsInterface: {},
        stepsCampaigns: [],
        optionsCampaigns: {},
        stepsUnitary: [],
        optionsUnitary: {}
      }
    },
    watch: {
      '$i18n.locale'() {
        this.generateTourData()
      }
    },
    mounted() {
      this.generateTourData()
    },
    methods: {
      ...mapMutations('campaign', ['setCreateCampaignModal']),
      toOtherTour(tour, page, tourName, phase) {
        tour.stop()
        this.$router.push(page + '?activateTour=' + tourName + '&step=' + phase)
      },
      interfaceHomeToGlobal(tour) {
        tour.stop()
        // eslint-disable-next-line dot-notation
        this.$tours['interface-global'].start()
      },
      interfaceContactToGlobalEnd (tour) {
        tour.stop()
        // eslint-disable-next-line dot-notation
        this.$tours['interface-global-end'].start()
      },
      campaignsNext (tour) {
        switch (tour.currentStep) {
          case 1:
            // creation de liste
            this.setCreateCampaignModal(true)
            this.$nextTick(() => {
              tour.nextStep()
            })
            break
          case 4:
            this.setCreateCampaignModal(false)
            this.$nextTick(() => {
              tour.nextStep()
            })
            break
          default:
            tour.nextStep()
        }
      },
      campaignsPrevious (tour) {
        switch (tour.currentStep) {
          case 2:
            // creation de liste
            this.setCreateCampaignModal(false)
            this.$nextTick(() => {
              tour.previousStep()
            })
            break
          case 5:
            this.setCreateCampaignModal(true)
            this.$nextTick(() => {
              tour.previousStep()
            })
            break
          default:
            tour.previousStep()
        }
      },
      generateTourData() {
        const defaultLabels = {
          buttonSkip: this.$t('tour.shared.skip'),
          buttonPrevious: this.$t('tour.shared.previous'),
          buttonNext: this.$t('tour.shared.next'),
          buttonStop: this.$t('tour.shared.stop')
        }
        /** ************ FIRST LOGIN ********** **/
        this.stepsFirstLogin = [{
          target: '[data-v-tour-firstlogin-step="1"]',
          header: {
            title: this.$t('tour.firstlogin.step1.title')
          },
          content: this.$t('tour.firstlogin.step1.content')
        }]
        this.optionsFirstLogin = {
          labels: defaultLabels,
          highlight: true
        }
        /** ************ INTERFACE ************ **/
        this.stepsInterfaceGlobal = [
          {
            target: '[data-v-tour-interface-step="1"]',
            content: this.$t('tour.interface.step1.content'),
            params: {
              placement: 'right'
            }
          }
        ]
        this.stepsInterfaceHome = [
          {
            target: '[data-v-tour-interface-step="2"]',
            content: this.$t('tour.interface.step2.content'),
            params: {
              placement: 'bottom'
            }
          },
          {
            target: '[data-v-tour-interface-step="3"]',
            content: this.$t('tour.interface.step3.content'),
            params: {
              placement: 'bottom'
            }
          },
          {
            target: '[data-v-tour-interface-step="4"]',
            content: this.$t('tour.interface.step4.content'),
            params: {
              placement: 'bottom'
            }
          }
        ]
        this.stepsInterfacePricing = [
          {
            target: '[data-v-tour-interface-step="5"]',
            content: this.$t('tour.interface.step5.content'),
            params: {
              placement: 'top'
            }
          }
        ]
        this.stepsInterfaceHistory = [
          {
            target: '[data-v-tour-interface-step="6"]',
            content: this.$t('tour.interface.step6.content'),
            params: {
              placement: 'bottom'
            }
          },
          {
            target: '[data-v-tour-interface-step="7"]',
            content: this.$t('tour.interface.step7.content'),
            params: {
              placement: 'bottom'
            }
          }
        ]
        this.stepsInterfaceProfile = [
          {
            target: '[data-v-tour-interface-step="8"]',
            content: this.$t('tour.interface.step8.content')
          },
          {
            target: '[data-v-tour-interface-step="9"]',
            content: this.$t('tour.interface.step9.content')
          },
          {
            target: '[data-v-tour-interface-step="10"]',
            content: this.$t('tour.interface.step10.content')
          }
        ]
        this.stepsInterfaceFaq = [
          {
            target: '[data-v-tour-interface-step="11"]',
            content: this.$t('tour.interface.step11.content')
          }
        ]
        this.stepsInterfaceContact = [
          {
            target: '[data-v-tour-interface-step="12"]',
            content: this.$t('tour.interface.step12.content')
          }
        ]
        this.stepsInterfaceGlobalEnd = [
          {
            target: '[data-v-tour-interface-step="13"]',
            content: this.$t('tour.interface.step13.content')
          },
          {
            target: '[data-v-tour-interface-step="14"]',
            content: this.$t('tour.interface.step14.content')
          }
        ]
        this.optionsInterface = {
          labels: defaultLabels
        }
        /** ************ CAMPAIGNS ************ **/
        this.stepsCampaigns = [
          {
            target: '[data-v-tour-campaigns-step="1"]',
            header: {
              title: this.$t('tour.campaigns.step1.title')
            },
            content: this.$t('tour.campaigns.step1.content')
          },
          {
            target: '[data-v-tour-campaigns-step="2"]',
            header: {
              title: this.$t('tour.campaigns.step2.title')
            },
            content: this.$t('tour.campaigns.step2.content')
          },
          {
            target: '[data-v-tour-campaigns-step="3"]',
            content: this.$t('tour.campaigns.step3.content')
          },
          {
            target: '[data-v-tour-campaigns-step="4"]',
            content: this.$t('tour.campaigns.step4.content')
          },
          {
            target: '[data-v-tour-campaigns-step="5"]',
            content: this.$t('tour.campaigns.step5.content')
          },
          {
            target: '[data-v-tour-campaigns-step="6"]',
            content: this.$t('tour.campaigns.step6.content'),
            params: {
              enableScrolling: false
            }
          },
          {
            target: '[data-v-tour-campaigns-step="7"]',
            content: this.$t('tour.campaigns.step7.content')
          },
          {
            target: '[data-v-tour-campaigns-step="6"]',
            header: {
              title: this.$t('tour.campaigns.step8.title')
            },
            content: this.$t('tour.campaigns.step8.content'),
            params: {
              enableScrolling: false
            }
          },
          {
            target: '[data-v-tour-campaigns-step="6"]',
            content: this.$t('tour.campaigns.step9.content'),
            params: {
              enableScrolling: false
            }
          },
          {
            target: '[data-v-tour-campaigns-step="6"]',
            header: {
              title: this.$t('tour.campaigns.step10.title')
            },
            content: this.$t('tour.campaigns.step10.content'),
            params: {
              enableScrolling: false
            }
          },
          {
            target: '[data-v-tour-campaigns-step="6"]',
            content: this.$t('tour.campaigns.step11.content'),
            params: {
              enableScrolling: false
            }
          },
          {
            target: '[data-v-tour-campaigns-step="6"]',
            content: this.$t('tour.campaigns.step12.content'),
            params: {
              enableScrolling: false
            }
          },
          {
            target: '[data-v-tour-campaigns-step="6"]',
            content: this.$t('tour.campaigns.step13.content'),
            params: {
              enableScrolling: false
            }
          },
          {
            target: '[data-v-tour-campaigns-step="14"]',
            content: this.$t('tour.campaigns.step14.content'),
            params: {
              placement: 'right'
            }
          }
        ]
        this.optionsCampaigns = {
          labels: defaultLabels
        }
        /** ************* UNITARY ************* **/
        this.stepsUnitary = [
          {
            target: '[data-v-tour-unitary-step="1"]',
            header: {
              title: this.$t('tour.unitary.step1.title')
            },
            content: this.$t('tour.unitary.step1.content')
          },
          {
            target: '[data-v-tour-unitary-step="2"]',
            header: {
              title: this.$t('tour.unitary.step2.title')
            },
            content: this.$t('tour.unitary.step2.content')
          },
          {
            target: '[data-v-tour-unitary-step="2"]',
            content: this.$t('tour.unitary.step3.content')
          },
          {
            target: '[data-v-tour-unitary-step="2"]',
            content: this.$t('tour.unitary.step4.content')
          },
          {
            target: '[data-v-tour-unitary-step="5"]',
            header: {
              title: this.$t('tour.unitary.step5.title')
            },
            content: this.$t('tour.unitary.step5.content')
          },
          {
            target: '[data-v-tour-unitary-step="6"]',
            header: {
              title: this.$t('tour.unitary.step6.title')
            },
            content: this.$t('tour.unitary.step6.content'),
            params: {
              placement: 'bottom'
            }
          },
          {
            target: '[data-v-tour-unitary-step="6"]',
            content: this.$t('tour.unitary.step7.content'),
            params: {
              placement: 'bottom'
            }
          },
          {
            target: '[data-v-tour-unitary-step="8"]',
            content: this.$t('tour.unitary.step8.content')
          },
          {
            target: '[data-v-tour-unitary-step="9"]',
            content: this.$t('tour.unitary.step9.content')
          },
          {
            target: '[data-v-tour-unitary-step="10"]',
            content: this.$t('tour.unitary.step10.content'),
            params: {
              placement: 'right'
            }
          },
          {
            target: '[data-v-tour-unitary-step="11"]',
            content: this.$t('tour.unitary.step11.content')
          }
        ]
        this.optionsUnitary = {
          labels: defaultLabels
        }
      }
    }
  }
</script>
<style>
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
</style>
