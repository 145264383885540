import cloneDeep from 'lodash/cloneDeep'

export default {
    constructDateFileName ($vue) {
        return $vue.$d(new Date(), 'long')
    },
    constructFile ($vue, _items) {
        const firstLevelAttributsName = ['email', 'validated', 'validationType', 'status', 'category']
        const firstLevelAttributsLen = firstLevelAttributsName.length

        /**  Only for simple validation mail */
        const simpleMailAttributsName = ['alias', 'free', 'autogenerated', 'protectedAttr', 'catchall', 'disposable', 'role', 'suspect', 'spamtrap']
        const simpleMailAttributsLen = simpleMailAttributsName.length

        /**  Only for deep  validation mail */
        const deepMailExtraName = ['socialMedia', 'marketingPlateform', 'breachList', 'fraudList', 'blacklisted', 'young', 'suspect']
        const deepMailExtraLen = deepMailExtraName.length

        const headerRow = []
        let headerRowEnable = true

        const items = cloneDeep(_items)

        let data = items.map((item) => {
            let row = ''
            // insertion des valeurs de premier niveau
            for (let i = 0; i < firstLevelAttributsLen; i++) {
                // insertion du header du tableau
                if (headerRowEnable) {
                    headerRow.push($vue.$t(`views.validateUnitary.simpleTable.${firstLevelAttributsName[i]}`))
                }

                if (item[firstLevelAttributsName[i]] === null) {
                    row += ','
                    continue
                }
                // récupération de la traduction
                switch (firstLevelAttributsName[i]) {
                    case 'validationType':
                        row += $vue.$t(`views.validateUnitary.simpleTable.${item[firstLevelAttributsName[i]]}`)
                        break
                    case 'validated':
                        row += $vue.$d(new Date(item[firstLevelAttributsName[i]]), 'long')
                        break
                    default:
                        row += item[firstLevelAttributsName[i]]
                        break
                }

                row += ','
            }

            // insertion des attributs pour les mails simple
            for (let j = 0; j < simpleMailAttributsLen; j++) {
                // insertion du header du tableau
                if (headerRowEnable) {
                    headerRow.push($vue.$t(`views.validateUnitary.modalDetail.${simpleMailAttributsName[j]}`))
                }

                if (item.attributs[simpleMailAttributsName[j]] === null) {
                    row += ','
                    continue
                }
                // récupération de la traduction
                row += $vue.$t(`views.validateUnitary.modalDetail.${item.attributs[simpleMailAttributsName[j]].value}`)
                row += ','
            }

            /**  Only for deep mail */

            // positive extras
            for (let k = 0; k < deepMailExtraLen; k++) {
                // insertion du header du tableau
                if (headerRowEnable) {
                    headerRow.push($vue.$t(`views.validateUnitary.modalDetail.${deepMailExtraName[k]}`))
                }
                if (item.validationType === 'SIMPLE' || typeof item.extras !== 'object') {
                    row += ','
                    continue
                }
                // récupération de la traduction
                row += $vue.$t(`views.validateUnitary.modalDetail.${item.extras[deepMailExtraName[k]].value}`)
                row += ','
            }

            // score
            if (headerRowEnable) {
                headerRow.push($vue.$t('views.validateUnitary.modalDetail.score'))
            }
            if (item.validationType === 'SIMPLE' || typeof item.score !== 'object') {
                row += ','
            } else {
                row += `${item.score.result}/${item.score.total}`
                row += ','
            }

            if (headerRowEnable) {
                headerRowEnable = false
            }
            return row
        })

        data = [headerRow, ...data]
        data = [data.join('\n')]
        return data
    }
}
