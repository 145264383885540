/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
        path: '/page-connexion',
        name: 'components.toolbar.page-title-connexion',
        view: 'connexion'
    },
    {
        path: '/page-reset',
        name: 'components.toolbar.page-title-reset',
        view: 'reset'
    },
    {
        path: '/page-signin',
        name: 'components.toolbar.page-title-signin',
        view: 'sign-in'
    },
    {
        path: '/page-signin-confirmation',
        name: 'components.toolbar.page-title-signin-confirmation',
        view: 'sign-in-confirmation'
    },
    {
        path: '/page-home',
        name: 'components.toolbar.page-title-home',
        view: 'home'
    },
    {
        path: '/page-user-profile',
        name: 'components.toolbar.page-title-user-profile',
        view: 'user-profile'
    },
    {
        path: '/page-campaigns',
        name: 'components.toolbar.page-title-campaigns',
        view: 'campaigns'
    },
    {
        path: '/page-validate-unitary',
        name: 'components.toolbar.page-title-validate-unitary',
        view: 'validate-unitary'
    },
    {
        path: '/page-pricing',
        name: 'components.toolbar.page-title-pricing',
        view: 'pricing'
    },
    {
        path: '/page-credits-history',
        name: 'components.toolbar.page-title-credits-history',
        view: 'credits-history'
    },
    {
        path: '/page-faq',
        name: 'components.toolbar.page-title-faq',
        view: 'faq'
    },
    {
        path: '/page-contact',
        name: 'components.toolbar.page-title-contact',
        view: 'contact'
    },
    {
        path: '/page-download',
        name: 'components.toolbar.page-title-download',
        view: 'download'
    }
]
