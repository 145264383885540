export default {
    dialog: [
        {
            name: 'cgu',
            isOpen: false
        },
        {
            name: 'changelog',
            isOpen: false
        },
        {
            name: 'donneesPersonnelles',
            isOpen: false
        },
        {
            name: 'tour',
            isOpen: false
        }
    ]

}
