<template>
  <div class="campaigns--remove_list_button">
    <v-tooltip
      v-if="!deleting"
      color="#fff"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            color="secondary"
            outlined
            @click="removeConfirmationDialog = true"
          >
            {{ $t('views.campaigns.removeList.buttonLabel') }}
          </v-btn>
        </div>
      </template>
      {{ $t('views.campaigns.removeList.tooltip') }}
    </v-tooltip>
    <v-progress-circular
      v-else
      color="primary"
      indeterminate
      size="18"
    />

    <v-dialog
      v-model="removeConfirmationDialog"
      max-width="500px"
      @click:outside="$emit('close')"
    >
      <material-card
        :title="$t('views.campaigns.removeList.confirmationDialogTitle')"
        color="primary"
      >
        <v-card-text v-if="validatedContext">
          {{ $t('views.campaigns.removeList.contentValidated') }}
        </v-card-text>
        <v-card-text v-else>
          {{ $t('views.campaigns.removeList.contentNoValidated') }}
        </v-card-text>
        <v-row
          v-if="!deleting"
          justify="center"
        >
          <v-btn
            color="secondary"
            outlined
            style="margin: 10px auto"
            @click="removeConfirmationDialog = false"
          >
            {{ $t('views.campaigns.removeList.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            rounded
            @click="removeList()"
          >
            {{ $t('views.campaigns.removeList.confirm') }}
          </v-btn>
        </v-row>
        <v-row
          v-else
          justify="center"
        >
          <v-progress-circular
            color="primary"
            indeterminate
            size="18"
          />
        </v-row>
      </material-card>
    </v-dialog>
  </div>
</template>

<script>
  import listAPI from '@/api/lists'
  import { mapState } from 'vuex'

  export default {
    props: {
      listId: {
        type: [Number, String]
      },
      validatedContext: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      removeConfirmationDialog: false,
      deleting: false
    }),
    computed: {
      ...mapState('user', ['accessToken'])
    },
    methods: {
      removeList () {
        this.deleting = true
        listAPI.deleteList(this.accessToken, this.listId)
            .then(result => this.$emit('confirmation'))
            .catch(error => this.$emit('error', error))
            .finally(() => { this.deleting = false })
      }
    }
  }
</script>
