<template>
  <material-card
    color="primary"
    :title="$t('components.invoice.form.title')"
    :text="$t('components.invoice.form.subtitle')"
  >
    <v-form
      ref="addressForm"
    >
      <v-row>
        <v-col :cols="12">
          <v-radio-group
            v-model="type"
            :rules="requiredRules"
            row
          >
            <v-radio
              :label="$t('components.invoice.form.labels.individual')"
              value="individual"
            />
            <v-radio
              :label="$t('components.invoice.form.labels.company')"
              value="company"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="type == 'individual'">
        <v-col
          :md="6"
          :cols="12"
        >
          <v-text-field
            v-model="individualSurname"
            class="purple-input"
            :label="$t('components.invoice.form.labels.surname')+' *'"
            :rules="requiredRules"
          />
        </v-col>
        <v-col
          :md="6"
          :cols="12"
        >
          <v-text-field
            v-model="individualLastname"
            class="purple-input"
            :label="$t('components.invoice.form.labels.lastname')+' *'"
            :rules="requiredRules"
          />
        </v-col>
      </v-row>
      <v-row v-if="type == 'company'">
        <v-col
          :md="6"
          :cols="12"
        >
          <v-text-field
            v-model="companyName"
            class="purple-input"
            :label="$t('components.invoice.form.labels.companyName')+' *'"
            :rules="requiredRules"
          />
        </v-col>
        <v-col
          :md="6"
          :cols="12"
        >
          <v-text-field
            v-model="tvaNumber"
            class="purple-input"
            :label="$t('components.invoice.form.labels.vat')"
            :rules="vatRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12">
          <v-text-field
            v-model="addressLine1"
            class="purple-input"
            :label="$t('components.invoice.form.labels.addressLineOne')+' *'"
            :rules="requiredRules"
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="addressLine2"
            class="purple-input"
            :label="$t('components.invoice.form.labels.addressLineTwo')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :md="5"
          :sm="8"
          :cols="12"
        >
          <v-text-field
            v-model="city"
            class="purple-input"
            :label="$t('components.invoice.form.labels.city')+' *'"
            :rules="requiredRules"
          />
        </v-col>
        <v-col
          :md="3"
          :sm="4"
          :cols="12"
        >
          <v-text-field
            v-model="postalCode"
            class="purple-input"
            :label="$t('components.invoice.form.labels.postalCode')"
          />
        </v-col>
        <v-col
          :md="4"
          :cols="12"
        >
          <v-select
            v-model="country"
            :items="countries"
            item-text="name"
            item-value="code"
            class="purple-input"
            :label="$t('components.invoice.form.labels.country')+' *'"
            :rules="requiredRules"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :sm="6"
          :cols="12"
        >
          <em>* {{ $t('components.invoice.form.requiredField') }}</em>
        </v-col>
        <v-col
          :sm="6"
          :cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            :loading="isUpdating"
            :disabled="isUpdating"
            @click="updateAddress"
          >
            {{ $t('components.invoice.form.update') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </material-card>
</template>
<script>
  import { mapState } from 'vuex'
  import countriesAPI from '@/api/external/restcountries'
  import invoiceAPI from '@/api/invoices'

  export default {
    data () {
      const requiredField = this.$t('components.invoice.form.requiredField')
      const invalidVatField = this.$t('components.invoice.form.invalidVatField')
      const vatRegex = new RegExp('^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$')
      return {
          type: '',
          individualSurname: '',
          individualLastname: '',
          companyName: '',
          tvaNumber: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          postalCode: '',
          country: {},
          countries: [],
          isUpdating: false,
          requiredRules: [v => !!v || requiredField],
          vatRules: [vat => (vat.length == 0 || vatRegex.test(vat)) || invalidVatField]
      }
    },
    computed: {
      ...mapState('user', ['accessToken', 'userInfo']),
      locale: function () {
        return this.$i18n.locale
      }
    },
    watch: {
      locale: function () {
        this.countries = countriesAPI.getLocalizedCountries(this.$i18n.locale)
      }
    },
    created () {
      this.countries = countriesAPI.getLocalizedCountries(this.$i18n.locale)

      invoiceAPI.getInvoiceAddress(this.accessToken)
        .then((address) => {
          this.type = address.type.toLowerCase()
          this.addressLine1 = address.addressLine1
          this.addressLine2 = address.addressLine2
          this.city = address.city
          this.postalCode = address.postalCode
          this.country = address.country
          if (this.type == 'individual') {
            this.individualSurname = address.surname
            this.individualLastname = address.lastName
          }
          if (this.type == 'company') {
            this.companyName = address.companyName
            this.tvaNumber = address.tvaNumber
          }
        })
    },
    methods: {
      updateAddress (event) {
        if (!this.$refs.addressForm.validate()) {
          this.$toast(this.$t('components.invoice.form.thereIsStillErrors'), {
            icon: 'mdi-alert-circle',
            color: 'red'
          })
          return
        }

        this.isUpdating = true
        // peuplement
        const address = {
          userId: this.userInfo.id,
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          city: this.city,
          postalCode: this.postalCode,
          country: this.country,
          type: this.type
        }

        if (this.type == 'company') {
          address.companyName = this.companyName
          address.tvaNumber = this.tvaNumber
        } else if (this.type == 'individual') {
          address.surname = this.individualSurname
          address.lastName = this.individualLastname
        }
        // update
        invoiceAPI.saveInvoiceAddress(this.accessToken, address)
          .then(response => {
            this.$toast(this.$t('components.invoice.form.updateSuccess'), {
              icon: 'mdi-information-outline',
              color: 'primary'
            })
          })
          .catch(error => {
            console.error(error)
            this.$toast(this.$t('global.genericError'), {
              icon: 'mdi-alert-circle',
              color: 'red'
            })
          })
          .finally(() => {
            this.isUpdating = false
            this.$refs.addressForm.resetValidation()
          })
      }
    }
  }
</script>
