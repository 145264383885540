<template>
  <v-main>
    <core-safari-warning/>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view/>
      </v-fade-transition>
      <CoreFooter v-if="accessTokenPayload || $route.path === '/page-connexion'"/>
    </div>
  </v-main>
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('user', ['accessTokenPayload'])
  }
}
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>