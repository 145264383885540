import axios from '@/plugins/axios'

export default {
  /**
   * @param accessTokenHeader
   * @param validationType String : 'simple'(default) || 'deep'
   * @returns {Promise<[] | validation>}
   */
  startValidateUnitary (accessTokenHeader, mail, _validationType) {
    // default value = 'simple'
    const validationType = _validationType || 'simple'
    const url = process.env.VUE_APP_Unitary_Url + '?type=' + validationType

    const headers = {
      'Content-Type': 'text/plain',
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios
      .post(url, mail, { headers: headers })
      .then(response => {
        return response.data
      })
  },

  /**
   * @description permet d'avoir le détail du check comme le coup et son nom
   * @param accessTokenHeader
   * @returns {Promise<T>}
   */
  getDetailType (accessTokenHeader) {
    const url = process.env.VUE_APP_Unitary_Url + 'type/'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios
      .get(url, { headers: headers })
      .then(response => {
        return response.data
      })
  },

  getLimitedValidateUnitaries (accessTokenHeader) {
    const url = process.env.VUE_APP_Unitary_Url + '?limit=500'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios
      .get(url, { headers: headers })
      .then(response => {
        return response.data
      })
  },

  /**
   * @description Permet de récupérer les statistiques des validations unitaires sur un certain nombre de semaine
   * a été développé initialement pour afficher des statistiques sur l'accueil
   * @param accessTokenHeader
   * @param _numberOfWeeks
   * @returns {Q.Promise<any> | PromiseLike<any>}
   */
  getWeeksStatistics (accessTokenHeader, _numberOfWeeks) {
    const numberOfWeeks = _numberOfWeeks || 12 // 3 mois par défaut
    const url = process.env.VUE_APP_Unitary_Url + 'stats?weeks=' + numberOfWeeks

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios
        .get(url, { headers: headers })
        .then(response => {
          return response.data
        })
  }
}
