export default class CampaignCharts {
  constructor () {
    this.colorSheme = {
      Safe: 'rgb(105,233,52)',
      Risky: 'rgb(255,149,0)',
      Unsafe: 'rgb(255,37,42)',
      Valid: 'rgb(105,233,52)',
      ValidUnfaith: 'rgb(158,255,118)',
      InboxFull: 'rgb(118,255,132)',
      Disposable: 'rgb(28,68,255)',
      Catchall: 'rgb(61,164,255)',
      CatchallUnfaith: 'rgb(157,209,255)',
      Role: 'rgb(255,253,124)',
      Suspect: 'rgb(255,192,37)',
      Incorrect: 'rgb(245,127,33)',
      Invalid: 'rgb(235,98,92)',
      Spamtrap: 'rgb(235,4,0)',
      Unverified: 'rgb(60,60,60)',
      Autogenerated: 'rgb(177,177,177)',
      Protected: 'rgb(139,0,139)',
      Duplicate: 'rgb(105,233,52)'
    }
  }

  generateSimpleTableDataForPie (statistiques, context) {
    return {
      datasets: [{
        backgroundColor: [
          this.colorSheme.Unsafe,
          this.colorSheme.Risky,
          this.colorSheme.Safe
        ],
        data: [
          statistiques.simpleStat.count.Unsafe,
          statistiques.simpleStat.count.Risky,
          statistiques.simpleStat.count.Safe
        ],
        percent: [
          statistiques.simpleStat.percent.Unsafe,
          statistiques.simpleStat.percent.Risky,
          statistiques.simpleStat.percent.Safe
        ]
      }],
      labels: [
        context.$t('views.campaigns.totalUnsafe.label'),
        context.$t('views.campaigns.totalRisky.label'),
        context.$t('views.campaigns.totalSafe.label')
      ]
    }
  }

  generateSimpleTableDataForExportPie (statistiques, context, lang) {
    return {
      datasets: [{
        backgroundColor: [
          this.colorSheme.Unsafe,
          this.colorSheme.Risky,
          this.colorSheme.Safe
        ],
        data: [
          statistiques.simpleStat.count.Unsafe,
          statistiques.simpleStat.count.Risky,
          statistiques.simpleStat.count.Safe
        ],
        percent: [
          statistiques.simpleStat.percent.Unsafe,
          statistiques.simpleStat.percent.Risky,
          statistiques.simpleStat.percent.Safe
        ]
      }],
      labels: [
        context.$t('components.pdf-report.chartLabels.unsafe', lang, [statistiques.simpleStat.count.Unsafe, statistiques.simpleStat.percent.Unsafe]),
        context.$t('components.pdf-report.chartLabels.risky', lang, [statistiques.simpleStat.count.Risky, statistiques.simpleStat.percent.Risky]),
        context.$t('components.pdf-report.chartLabels.safe', lang, [statistiques.simpleStat.count.Safe, statistiques.simpleStat.percent.Safe])
      ]
    }
  }

  // pas de unverified (N/A pour le résultat)
  generateDetailsTableDataForPie (statistiques, context) {
    return {
      datasets: [{
        backgroundColor: [
          this.colorSheme.Valid,
          this.colorSheme.ValidUnfaith,
          this.colorSheme.InboxFull,
          this.colorSheme.Disposable,
          this.colorSheme.Catchall,
          this.colorSheme.CatchallUnfaith,
          this.colorSheme.Autogenerated,
          this.colorSheme.Protected,
          this.colorSheme.Role,
          this.colorSheme.Suspect,
          this.colorSheme.Incorrect,
          this.colorSheme.Invalid,
          this.colorSheme.Spamtrap
        ],
        data: [
          statistiques.deepStat.count.Valid,
          statistiques.deepStat.count.ValidUnfaith,
          statistiques.deepStat.count.Full,
          statistiques.deepStat.count.Disposable,
          statistiques.deepStat.count.Catchall,
          statistiques.deepStat.count.CatchallUnfaith,
          statistiques.deepStat.count.Autogenerated,
          statistiques.deepStat.count.Protected,
          statistiques.deepStat.count.Role,
          statistiques.deepStat.count.Suspect,
          statistiques.deepStat.count.Incorrect,
          statistiques.deepStat.count.Invalid,
          statistiques.deepStat.count.Spamtrap
        ],
        percent: [
          statistiques.deepStat.percent.Valid,
          statistiques.deepStat.percent.ValidUnfaith,
          statistiques.deepStat.percent.Full,
          statistiques.deepStat.percent.Disposable,
          statistiques.deepStat.percent.Catchall,
          statistiques.deepStat.percent.CatchallUnfaith,
          statistiques.deepStat.percent.Autogenerated,
          statistiques.deepStat.percent.Protected,
          statistiques.deepStat.percent.Role,
          statistiques.deepStat.percent.Suspect,
          statistiques.deepStat.percent.Incorrect,
          statistiques.deepStat.percent.Invalid,
          statistiques.deepStat.percent.Spamtrap
        ]
      }],
      labels: [
        context.$t('views.campaigns.totalValid.label'),
        context.$t('views.campaigns.totalValidUnfaith.label'),
        context.$t('views.campaigns.totalInboxFull.label'),
        context.$t('views.campaigns.totalDisposable.label'),
        context.$t('views.campaigns.totalCatchall.label'),
        context.$t('views.campaigns.totalCatchallUnfaith.label'),
        context.$t('views.campaigns.totalAutogenerated.label'),
        context.$t('views.campaigns.totalProtected.label'),
        context.$t('views.campaigns.totalRole.label'),
        context.$t('views.campaigns.totalSuspect.label'),
        context.$t('views.campaigns.totalIncorrect.label'),
        context.$t('views.campaigns.totalInvalid.label'),
        context.$t('views.campaigns.totalSpamtrap.label')
      ]
    }
  }

  generateDetailsTableDataForExportPie (statistiques, context, lang) {
    const data = {
      datasets: [{
        backgroundColor: [],
        data: [],
        percent: []
      }],
      labels: []
    }

    // suppression des duplicates car % monte au dela de 100
    if (statistiques.deepStat.count.Valid > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Safe)
      data.datasets[0].data.push(statistiques.deepStat.count.Valid)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Valid)
      data.labels.push(context.$t('components.pdf-report.chartLabels.valid', lang, [statistiques.deepStat.count.Valid, statistiques.deepStat.percent.Valid]))
    }
    if (statistiques.deepStat.count.ValidUnfaith > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.ValidUnfaith)
      data.datasets[0].data.push(statistiques.deepStat.count.ValidUnfaith)
      data.datasets[0].percent.push(statistiques.deepStat.percent.ValidUnfaith)
      data.labels.push(context.$t('components.pdf-report.chartLabels.validUnfaith', lang, [statistiques.deepStat.count.ValidUnfaith, statistiques.deepStat.percent.ValidUnfaith]))
    }
    if (statistiques.deepStat.count.Full > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.InboxFull)
      data.datasets[0].data.push(statistiques.deepStat.count.Full)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Full)
      data.labels.push(context.$t('components.pdf-report.chartLabels.full', lang, [statistiques.deepStat.count.Full, statistiques.deepStat.percent.Full]))
    }
    if (statistiques.deepStat.count.Disposable > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Disposable)
      data.datasets[0].data.push(statistiques.deepStat.count.Disposable)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Disposable)
      data.labels.push(context.$t('components.pdf-report.chartLabels.disposable', lang, [statistiques.deepStat.count.Disposable, statistiques.deepStat.percent.Disposable]))
    }
    if (statistiques.deepStat.count.Catchall > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Catchall)
      data.datasets[0].data.push(statistiques.deepStat.count.Catchall)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Catchall)
      data.labels.push(context.$t('components.pdf-report.chartLabels.catchall', lang, [statistiques.deepStat.count.Catchall, statistiques.deepStat.percent.Catchall]))
    }
    if (statistiques.deepStat.count.CatchallUnfaith > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.CatchallUnfaith)
      data.datasets[0].data.push(statistiques.deepStat.count.CatchallUnfaith)
      data.datasets[0].percent.push(statistiques.deepStat.percent.CatchallUnfaith)
      data.labels.push(context.$t('components.pdf-report.chartLabels.catchallUnfaith', lang, [statistiques.deepStat.count.CatchallUnfaith, statistiques.deepStat.percent.CatchallUnfaith]))
    }
    if (statistiques.deepStat.count.Autogenerated > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Autogenerated)
      data.datasets[0].data.push(statistiques.deepStat.count.Autogenerated)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Autogenerated)
      data.labels.push(context.$t('components.pdf-report.chartLabels.autogenerated', lang, [statistiques.deepStat.count.Autogenerated, statistiques.deepStat.percent.Autogenerated]))
    }
    if (statistiques.deepStat.count.Protected > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Protected)
      data.datasets[0].data.push(statistiques.deepStat.count.Protected)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Protected)
      data.labels.push(context.$t('components.pdf-report.chartLabels.protected', lang, [statistiques.deepStat.count.Protected, statistiques.deepStat.percent.Protected]))
    }
    if (statistiques.deepStat.count.Role > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Role)
      data.datasets[0].data.push(statistiques.deepStat.count.Role)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Role)
      data.labels.push(context.$t('components.pdf-report.chartLabels.role', lang, [statistiques.deepStat.count.Role, statistiques.deepStat.percent.Role]))
    }
    if (statistiques.deepStat.count.Suspect > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Suspect)
      data.datasets[0].data.push(statistiques.deepStat.count.Suspect)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Suspect)
      data.labels.push(context.$t('components.pdf-report.chartLabels.suspect', lang, [statistiques.deepStat.count.Suspect, statistiques.deepStat.percent.Suspect]))
    }
    if (statistiques.deepStat.count.Incorrect > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Incorrect)
      data.datasets[0].data.push(statistiques.deepStat.count.Incorrect)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Incorrect)
      data.labels.push(context.$t('components.pdf-report.chartLabels.incorrect', lang, [statistiques.deepStat.count.Incorrect, statistiques.deepStat.percent.Incorrect]))
    }
    if (statistiques.deepStat.count.Invalid > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Invalid)
      data.datasets[0].data.push(statistiques.deepStat.count.Invalid)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Invalid)
      data.labels.push(context.$t('components.pdf-report.chartLabels.invalid', lang, [statistiques.deepStat.count.Invalid, statistiques.deepStat.percent.Invalid]))
    }
    if (statistiques.deepStat.count.Spamtrap > 0) {
      data.datasets[0].backgroundColor.push(this.colorSheme.Spamtrap)
      data.datasets[0].data.push(statistiques.deepStat.count.Spamtrap)
      data.datasets[0].percent.push(statistiques.deepStat.percent.Spamtrap)
      data.labels.push(context.$t('components.pdf-report.chartLabels.spamtrap', lang, [statistiques.deepStat.count.Spamtrap, statistiques.deepStat.percent.Spamtrap]))
    }

    return data
  }
};
