import axios from '@/plugins/axios'

export default {
  // next 2 methods gets only active products
  getProductList (accessTokenHeader) {
    const url = process.env.VUE_APP_Product_Url + '?orderBy=mail_credits'

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessTokenHeader,
      'Access-Control-Allow-Origin': '*'
    }

    // eslint-disable-next-line no-undef
    return axios.get(url, headers).then(response => response.data.products)
  },

  getProductListWithVat (accessTokenHeader, vatRate) {
    const url = process.env.VUE_APP_Product_Url + '?orderBy=mail_credits&vat=' + vatRate

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessTokenHeader,
      'Access-Control-Allow-Origin': '*'
    }

    // eslint-disable-next-line no-undef
    return axios.get(url, headers).then(response => response.data.products)
  },

  // all get also expired products, used for billing history purpose
  getFullProductList (accessTokenHeader) {
    const url = process.env.VUE_APP_Product_Url + 'all?orderBy=mail_credits'

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessTokenHeader,
      'Access-Control-Allow-Origin': '*'
    }

    // eslint-disable-next-line no-undef
    return axios.get(url, headers).then(response => response.data.products)
  }
}
