<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    mobile-breakpoint="991"
    persistent
    width="260"
  >
    <v-img
      :src="drawer_bg"
      height="100%"
    >
      <v-list
        class="fill-height"
        nav
      >
        <v-list-group
          :value="websites"
          color="brand-primary"
        >
          <template v-slot:activator>
            <v-list-item-avatar color="white">
              <v-img
                :src="logo"
                contain
                height="34"
              />
            </v-list-item-avatar>
            <v-list-item-title class="title">
              Check
            </v-list-item-title>
          </template>
          <v-list-item
            v-for="(entitie,i) in mailnjoy_websites"
            :key="i"
            :href="entitie.url"
            target="_blank"
          >
            <v-list-item-avatar color="white">
              <v-img
                :src="entitie.logo"
                contain
                height="34"
              />
            </v-list-item-avatar>
            <v-list-item-title v-text="entitie.name" />
          </v-list-item>
        </v-list-group>
        <v-divider />
        <v-list-item
          v-for="(entitie, i) in entitiesPage"
          :key="i"
          :to="entitie.link"
          v-bind="entitie.additionnalAttributs"
          class="v-list-item"
        >
          <v-list-item-icon>
            <v-icon>{{ entitie.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="$t(entitie.label)" />
        </v-list-item>
      <!--     FOR TESTING ONLY
      <v-list-item @click="testService">
        <v-list-item-icon>
          <v-icon> mdi-ab-testing </v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="'TESTING SERVICE'" />
      </v-list-item>
    </v-list>-->
      </v-list>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    logo: './img/mailnjoy_check.png',
    drawer_bg: './img/drawer_bg.jpg',
    responsive: false,
    websites: false,
    mailnjoy_websites: [
      {
        url: process.env.VUE_APP_Developer_Url,
        logo: './img/developer.png',
        name: 'Developer'
      }
    ]
  }),
  computed: {
    // ...mapState('user', ['accessToken', 'userInfo']),
    ...mapState('app', ['entitiesPage']),
    items () {
      return this.$t('Layout.View.items')
    },
    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.$store.state.app.drawer = val
      }
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    onResponsiveInverted () {
      this.responsive = window.innerWidth < 991
    },
    testService () {
      // API a tester ici, ne pas oublier l'import
    }
  }
}
</script>

<style lang="scss">
  #app-drawer {
    .v-image__image--cover {
      opacity: 0.4;
    }
    .v-image__image--contain {
      top: 9px;
      height: 60%;
    }

    .v-list {
      border-radius: 0;

      .v-list-item:first-of-type {
        margin-top: 0;
      }

      .v-list-item__icon {
        margin-right: 12px;
      }
    }
  }
</style>
