import axios from '@/plugins/axios'

export default {
  createPredictive (accessTokenHeader, listId) {
    const url = process.env.VUE_APP_Predictive_Url + '?listId=' + listId

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'post', url: url, headers: headers })
  },

  getPredictiveByCampaign (accessTokenHeader, campaignId) {
    const url = process.env.VUE_APP_Predictive_Url + campaignId

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
  },

  async getPredictiveProgressTime (accessTokenHeader, predictiveEngineName) {
    const url = process.env.VUE_APP_Predictive_Url + predictiveEngineName + '/time/'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data.time
      })
  }
}
