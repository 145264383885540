import userAPI from '@/api/users'
import settingAPI from '@/api/setting'

export default {
    logOut ({ commit }) {
        commit('resetToken')
    },
    setRefreshToken ({ commit }, refreshToken) {
        commit('setRefreshToken', refreshToken)
    },
    setAccessToken ({ commit }, accessToken) {
        commit('setAccessToken', accessToken)
    },
    // récupére les infos de l'utilisateur via l'API, à partir de son token
    refreshUserInfo ({ commit, state }) {
        if (state.accessTokenPayload) {
            userAPI
                .getUserInfo(state.accessToken)
                .then(user => {
                    commit('setUserInfo', user)
                })
                .catch(error => {
                    console.error('User/actions.js/refreshUserInfo: -- userAPI' + error)
                    commit('resetToken')
                })
        } else {
            commit('resetToken')
        }
    },
    refreshSettingEmailCampaignEnd ({ commit, state }) {
        if (state.accessTokenPayload) {
            // Récupération de certain settings
            settingAPI.getEmailCampaignEnd(state.accessToken)
                .then(setting => {
                  // défaut vrai
                    if (setting === 'false') {
                        commit('setSettingEmailCampaignEnd', false)
                    } else {
                        commit('setSettingEmailCampaignEnd', true)
                    }
                })
                .catch(error => {
                    console.error('User/actions.js/refreshSettingEmailCampaignEnd -- settingAPI: ' + error)
                    commit('setSettingEmailCampaignEnd', true)
                })
        }
    },
    updateSettingEmailCampaignEnd ({ commit, state }, value) {
        if (state.accessTokenPayload) {
            settingAPI.updateEmailCampaignEnd(state.accessToken, value)
                .then(() => {
                    commit('setSettingEmailCampaignEnd', value)
                })
                .catch(error => {
                    console.error('User/actions.js/updateSettingEmailCampaignEnd -- settingAPI: ' + error)
                })
        }
    },
    refreshSettingEmailCampaignEndToken ({ commit, state }) {
        if (state.accessTokenPayload) {
            // Récupération de certain settings
            settingAPI.getEmailCampaignEndToken(state.accessToken)
                .then(setting => {
                  // défaut faux
                    if (setting === 'true') {
                        commit('setSettingEmailCampaignEndToken', true)
                    } else {
                        commit('setSettingEmailCampaignEndToken', false)
                    }
                })
                .catch(error => {
                    console.error('User/actions.js/refreshSettingEmailCampaignEndToken -- settingAPI: ' + error)
                    commit('setSettingEmailCampaignEndToken', false)
                })
        }
    },
    updateSettingEmailCampaignEndToken ({ commit, state }, value) {
        if (state.accessTokenPayload) {
            settingAPI.updateEmailCampaignEndToken(state.accessToken, value)
                .then(() => {
                    commit('setSettingEmailCampaignEndToken', value)
                })
                .catch(error => {
                    console.error('User/actions.js/updateEmailCampaignEndToken -- settingAPI: ' + error)
                })
        }
    },
    refreshSettingWhiteLabelImageUrl ({ commit, state }) {
        if (state.accessTokenPayload) {
            // Récupération de certain settings
            settingAPI.getWhiteLabelImageUrl(state.accessToken)
                .then(setting => {
                    commit('setSettingWhiteLabelImageUrl', setting)
                })
                .catch(error => {
                    console.error('User/actions.js/refreshSettingWhiteLabelImageUrl -- settingAPI: ' + error)
                })
        }
    },
    updateSettingWhiteLabelImageUrl ({ commit, state }, value) {
        if (state.accessTokenPayload) {
            settingAPI.updateWhiteLabelImageUrl(state.accessToken, value)
                .then(() => {
                    commit('setSettingWhiteLabelImageUrl', value)
                })
                .catch(error => {
                    console.error('User/actions.js/updateEmailWhiteLabelImageUrl -- settingAPI: ' + error)
                })
        }
    },
    refreshSettingWhiteLabelImageUrlWithCampaignToken ({ commit, state }, campaignToken) {
        // Récupération de certain settings
        settingAPI.getWhiteLabelImageUrlWithCampaignToken(campaignToken)
            .then(setting => {
                commit('setSettingWhiteLabelImageUrl', setting)
            })
            .catch(error => {
                console.error('User/actions.js/refreshSettingWhiteLabelImageUrlWithCampaignToken -- settingAPI: ' + error)
            })
    },
    refreshSettingEmailCampaignAutoDeletion ({ commit, state }) {
        // Récupération de certain settings
        settingAPI.getEmailCampaignAutoDeletion(state.accessToken)
            .then(setting => {
                // défaut vrai
                if (setting === 'false') {
                    commit('setSettingEmailCampaignAutoDeletion', false)
                } else {
                    commit('setSettingEmailCampaignAutoDeletion', true)
                }
            })
            .catch(error => {
                console.error('User/actions.js/refreshSettingEmailCampaignAutoDeletion -- settingAPI: ' + error)
            })
    },
    updateSettingEmailCampaignAutoDeletion ({ commit, state }, value) {
        if (state.accessTokenPayload) {
            settingAPI.updateEmailCampaignAutoDeletion(state.accessToken, value)
                .then(() => {
                    commit('setSettingEmailCampaignAutoDeletion', value)
                })
                .catch(error => {
                    console.error('User/actions.js/updateEmailCampaignAutoDeletion -- settingAPI: ' + error)
                })
        }
    },
    refreshSettingCampaignAutoDeletionDelay ({ commit, state }) {
        // Récupération de certain settings
        settingAPI.getCampaignAutoDeletionDelay(state.accessToken)
            .then(setting => {
                // suppression des valeurs abérantes, comme le fait le backend
                if (isNaN(setting) || isNaN(parseInt(setting)) || setting > state.settingCampaignAutoDeletionDelayMaxValue) {
                    commit('setSettingCampaignAutoDeletionDelay', state.settingCampaignAutoDeletionDelayMaxValue)
                } else if (setting < state.settingCampaignAutoDeletionDelayMinValue) {
                    commit('setSettingCampaignAutoDeletionDelay', state.settingCampaignAutoDeletionDelayMinValue)
                } else {
                  commit('setSettingCampaignAutoDeletionDelay', parseInt(setting))
                }
            })
            .catch(error => {
                console.error('User/actions.js/refreshSettingCampaignAutoDeletionDelay -- settingAPI: ' + error)
            })
    },
    updateSettingCampaignAutoDeletionDelay ({ commit, state }, value) {
        if (state.accessTokenPayload) {
            settingAPI.updateCampaignAutoDeletionDelay(state.accessToken, value)
                .then(() => {
                    commit('setSettingCampaignAutoDeletionDelay', value)
                })
                .catch(error => {
                    console.error('User/actions.js/updateCampaignAutoDeletionDelay -- settingAPI: ' + error)
                })
        }
    }
}
