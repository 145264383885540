import findIndex from 'lodash/findIndex'

export default {
    addEmailIntoEmailsChecked (state, email) {
        state.emailsBeingValidated.push(email)
        state.emailsChecked.push(email)
    },
    replaceEmailIntoEmailsChecked (state, email) {
        let index = findIndex(state.emailsBeingValidated, { key: email.key })
        state.emailsBeingValidated.splice(index, 1)
         index = findIndex(state.emailsChecked, { key: email.key })
        state.emailsChecked.splice(index, 1, email)
    },
    removeEmailFromEmailsChecked (state, key) {
        let index = findIndex(state.emailsBeingValidated, { key: key })
        state.emailsBeingValidated.splice(index, 1)
        index = findIndex(state.emailsChecked, { key: key })
        state.emailsChecked.splice(index, 1)
    },
    setEmailsChecked (state, emails) {
        state.emailsChecked = emails
    },
    setProductValidationUnitary (state, productValidationUnitary) {
        state.productValidationUnitary = productValidationUnitary
    },
    setWeekStatistics (state, weekStatistics) {
        // let dataToTransform = mockData
        const dataToTransform = weekStatistics

        // On prépare l'objet qui va nous servir à construire le graphique d'aire cumulé
        const statistics = []
        statistics.unsafe = []
        statistics.safe = []
        statistics.risky = []
        statistics.verysafe = []
        statistics.date = []
        statistics.count = []
        statistics.count.unsafe = []
        statistics.count.safe = []
        statistics.count.risky = []
        statistics.count.verysafe = []

        // On traite la donné afin d'avoir le résultat voulu
        // x = poucentage, y = date, on stock les valeurs absolue pour pouvoir les afficher au survol
        Object.keys(dataToTransform).forEach(key => {
            const data = dataToTransform[key]
            statistics.unsafe.push(100 * data.categories.nbUnsafe / data.totalMail)
            statistics.count.unsafe.push(data.categories.nbUnsafe)

            statistics.safe.push(100 * data.categories.nbSafe / data.totalMail)
            statistics.count.safe.push(data.categories.nbSafe)

            statistics.risky.push(100 * data.categories.nbRisky / data.totalMail)
            statistics.count.risky.push(data.categories.nbRisky)

            statistics.verysafe.push(100 * data.categories.nbVerySafe / data.totalMail)
            statistics.count.verysafe.push(data.categories.nbVerySafe)

            statistics.date.push(new Date(data.date))
        })

        state.weekStatistics = statistics
    }
}
