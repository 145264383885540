export default {
    setCampaigns (state, campaigns) {
        /**
         * On prend uniquement la première campagne
         * todo : pour le moment il n'y a qu'une seule campagne par liste, mais dans le futur
         * un liste peut comporter plusieurs campagne
         * il faudra du coup faire un forEach pour récupérer l'ensemble des campagnes, mais cela demandera
         * également de changer UI et UX
         */
        state.campaign = campaigns
    },
    setCampaignFirst (state, payload) {
        if (payload.list && payload.campaign) {
            payload.campaign.list = payload.list
        }
        state.campaignFirst = payload.campaign
    },

    // L'index de chaque objet dans le tableau de [campaignSimple] et [campaignDetails]
    // définit l'ordre dans le tableau côté FRONT
    setStatistique (state, statistiques) {
        const dataToInsert = []
        // debugger
        const campaignSimple = [
            {
                name: 'totalSafe',
                download: 'safe',
                count: statistiques.totalSafe,
                percent: parseInt(100 * statistiques.totalSafe / statistiques.totalEmail)
            }, {
                name: 'totalRisky',
                download: 'risky',
                count: statistiques.totalRisky,
                percent: parseInt(100 * statistiques.totalRisky / statistiques.totalEmail)
            }, {
                name: 'totalUnsafe',
                download: 'unsafe',
                count: statistiques.totalUnsafe,
                percent: parseInt(100 * statistiques.totalUnsafe / statistiques.totalEmail)
            }, {
                name: 'totalUnverified',
                download: 'unverified',
                count: statistiques.totalUnverified,
                percent: 'N/A'
            }
        ]

        const campaignDetails = [
            {
                name: 'totalValid',
                download: 'valides',
                count: statistiques.totalValid,
                percent: parseInt(100 * statistiques.totalValid / statistiques.totalEmail)
            }, {
                name: 'totalValidUnfaith',
                download: 'valides-unfaith',
                count: statistiques.totalValidUnfaith,
                percent: parseInt(100 * statistiques.totalValidUnfaith / statistiques.totalEmail)
            }, {
                name: 'totalDuplicate',
                download: 'duplique',
                count: statistiques.totalDuplicate,
                percent: parseInt(100 * statistiques.totalDuplicate / statistiques.totalEmail)
            }, {
                name: 'totalInboxFull',
                download: 'valides-inboxfull',
                count: statistiques.totalInboxFull,
                percent: parseInt(100 * statistiques.totalInboxFull / statistiques.totalEmail)
            }, {
                name: 'totalDisposable',
                download: 'disposable',
                count: statistiques.totalDisposable,
                percent: parseInt(100 * statistiques.totalDisposable / statistiques.totalEmail)
            }, {
                name: 'totalCatchall',
                download: 'catchall',
                count: statistiques.totalCatchall,
                percent: parseInt(100 * statistiques.totalCatchall / statistiques.totalEmail)
            }, {
                name: 'totalCatchallUnfaith',
                download: 'catchall-unfaith',
                count: statistiques.totalCatchallUnfaith,
                percent: parseInt(100 * statistiques.totalCatchallUnfaith / statistiques.totalEmail)
            }, {
                name: 'totalAutogenerated',
                download: 'autogenerated',
                count: statistiques.totalAutogenerated,
                percent: parseInt(100 * statistiques.totalAutogenerated / statistiques.totalEmail)
            }, {
                name: 'totalProtected',
                download: 'protected',
                count: statistiques.totalProtected,
                percent: parseInt(100 * statistiques.totalProtected / statistiques.totalEmail)
            }, {
                name: 'totalRole',
                download: 'role',
                count: statistiques.totalRole,
                percent: parseInt(100 * statistiques.totalRole / statistiques.totalEmail)
            }, {
                name: 'totalSuspect',
                download: 'suspects',
                count: statistiques.totalSuspect,
                percent: parseInt(100 * statistiques.totalSuspect / statistiques.totalEmail)
            }, {
                name: 'totalIncorrect',
                download: 'malformes',
                count: statistiques.totalIncorrect,
                percent: parseInt(100 * statistiques.totalIncorrect / statistiques.totalEmail)
            }, {
                name: 'totalInvalid',
                download: 'invalides',
                count: statistiques.totalInvalid,
                percent: parseInt(100 * statistiques.totalInvalid / statistiques.totalEmail)
            }, {
                name: 'totalSpamtrap',
                download: 'spamtrap',
                count: statistiques.totalSpamtrap,
                percent: parseInt(100 * statistiques.totalSpamtrap / statistiques.totalEmail)
            }, {
                name: 'totalUnverified',
                download: 'unverified',
                count: statistiques.totalUnverified,
                percent: 'N/A'
            }]

        const statistics = []

        statistics.deepStat = []

        statistics.deepStat.percent = []
        statistics.deepStat.percent.Catchall = parseInt(100 * statistiques.totalCatchall / statistiques.totalEmail)
        statistics.deepStat.percent.CatchallUnfaith = parseInt(100 * statistiques.totalCatchallUnfaith / statistiques.totalEmail)
        statistics.deepStat.percent.Disposable = parseInt(100 * statistiques.totalDisposable / statistiques.totalEmail)
        statistics.deepStat.percent.Duplicate = parseInt(100 * statistiques.totalDuplicate / statistiques.totalEmail)
        statistics.deepStat.percent.Full = parseInt(100 * statistiques.totalInboxFull / statistiques.totalEmail)
        statistics.deepStat.percent.Invalid = parseInt(100 * statistiques.totalInvalid / statistiques.totalEmail)
        statistics.deepStat.percent.Autogenerated = parseInt(100 * statistiques.totalAutogenerated / statistiques.totalEmail)
        statistics.deepStat.percent.Protected = parseInt(100 * statistiques.totalProtected / statistiques.totalEmail)
        statistics.deepStat.percent.Incorrect = parseInt(100 * statistiques.totalIncorrect / statistiques.totalEmail)
        statistics.deepStat.percent.Role = parseInt(100 * statistiques.totalRole / statistiques.totalEmail)
        statistics.deepStat.percent.Spamtrap = parseInt(100 * statistiques.totalSpamtrap / statistiques.totalEmail)
        statistics.deepStat.percent.Suspect = parseInt(100 * statistiques.totalSuspect / statistiques.totalEmail)
        statistics.deepStat.percent.Valid = parseInt(100 * statistiques.totalValid / statistiques.totalEmail)
        statistics.deepStat.percent.ValidUnfaith = parseInt(100 * statistiques.totalValidUnfaith / statistiques.totalEmail)
        statistics.deepStat.percent.Unverified = 'N/A'

        statistics.deepStat.count = []
        statistics.deepStat.count.Catchall = statistiques.totalCatchall
        statistics.deepStat.count.CatchallUnfaith = statistiques.totalCatchallUnfaith
        statistics.deepStat.count.Disposable = statistiques.totalDisposable
        statistics.deepStat.count.Duplicate = statistiques.totalDuplicate
        statistics.deepStat.count.Full = statistiques.totalInboxFull
        statistics.deepStat.count.Invalid = statistiques.totalInvalid
        statistics.deepStat.count.Autogenerated = statistiques.totalAutogenerated
        statistics.deepStat.count.Protected = statistiques.totalProtected
        statistics.deepStat.count.Incorrect = statistiques.totalIncorrect
        statistics.deepStat.count.Role = statistiques.totalRole
        statistics.deepStat.count.Spamtrap = statistiques.totalSpamtrap
        statistics.deepStat.count.Suspect = statistiques.totalSuspect
        statistics.deepStat.count.Valid = statistiques.totalValid
        statistics.deepStat.count.ValidUnfaith = statistiques.totalValidUnfaith
        statistics.deepStat.count.Unverified = statistiques.totalUnverified

        statistics.simpleStat = []

        statistics.simpleStat.percent = []
        statistics.simpleStat.percent.Risky = parseInt(100 * statistiques.totalRisky / statistiques.totalEmail)
        statistics.simpleStat.percent.Safe = parseInt(100 * statistiques.totalSafe / statistiques.totalEmail)
        statistics.simpleStat.percent.Unsafe = parseInt(100 * statistiques.totalUnsafe / statistiques.totalEmail)
        statistics.simpleStat.percent.Unverified = 'N/A'

        statistics.simpleStat.count = []
        statistics.simpleStat.count.Risky = statistiques.totalRisky
        statistics.simpleStat.count.Safe = statistiques.totalSafe
        statistics.simpleStat.count.Unsafe = statistiques.totalUnsafe
        statistics.simpleStat.count.Unverified = statistiques.totalUnverified
        dataToInsert.campaignStatus = statistiques.state
        dataToInsert.stats = statistiques
        dataToInsert.statistics = statistics
        dataToInsert.campaigns = {
            simple: campaignSimple,
            details: campaignDetails
        }
        state.campaignFirst = { ...state.campaignFirst, ...dataToInsert }
    },
    setProgressTime (state, progressTime) {
        let dataToInsert
        // Transformation de la donnée pour pouvoir l'afficher correctement
        if (progressTime != null && progressTime.time != null) {
            dataToInsert = progressTime
            const timeLeft = parseInt(progressTime.time.timeLeft) // temps de traitement restant
            const waiting = parseInt(progressTime.time.waiting) // temps d'attente avant le lancement du traitement
            const processing = parseInt(progressTime.time.processing) // temps de traitement du processus
            const total = waiting + processing // temps total avant la fin du traitement

            // on passe les temps en millisecondes, car donnée en minutes par l'API
            dataToInsert.time.waiting = waiting * 60000
            dataToInsert.time.processing = processing * 60000
            dataToInsert.time.timeLeft = timeLeft * 60000
            dataToInsert.time.total = total * 60000

            // Insertion dans le Store
            dataToInsert.progressTime = progressTime
        }

        state.campaignFirst = { ...state.campaignFirst, ...dataToInsert }
    },
    setLatestCampaign (state, latestCampaign) {
        state.latestCampaign = latestCampaign
    },
    setWeekStatistics (state, weekStatistics) {
        // let dataToTransform = mockData
        const dataToTransform = weekStatistics

        // On prépare l'objet qui va nous servir à construire le graphique d'aire cumulé
        const statistics = []

        statistics.deepStat = []

        statistics.deepStat.percent = []
        statistics.deepStat.percent.Catchall = []
        statistics.deepStat.percent.CatchallUnfaith = []
        statistics.deepStat.percent.Disposable = []
        statistics.deepStat.percent.Full = []
        statistics.deepStat.percent.Invalid = []
        statistics.deepStat.percent.Incorrect = []
        statistics.deepStat.percent.Autogenerated = []
        statistics.deepStat.percent.Protected = []
        statistics.deepStat.percent.Role = []
        statistics.deepStat.percent.Spamtrap = []
        statistics.deepStat.percent.Suspect = []
        statistics.deepStat.percent.Valid = []
        statistics.deepStat.percent.ValidUnfaith = []

        statistics.deepStat.count = []
        statistics.deepStat.count.Catchall = []
        statistics.deepStat.count.CatchallUnfaith = []
        statistics.deepStat.count.Disposable = []
        statistics.deepStat.count.Full = []
        statistics.deepStat.count.Invalid = []
        statistics.deepStat.count.Incorrect = []
        statistics.deepStat.count.Autogenerated = []
        statistics.deepStat.count.Protected = []
        statistics.deepStat.count.Role = []
        statistics.deepStat.count.Spamtrap = []
        statistics.deepStat.count.Suspect = []
        statistics.deepStat.count.Valid = []
        statistics.deepStat.count.ValidUnfaith = []

        statistics.simpleStat = []

        statistics.simpleStat.percent = []
        statistics.simpleStat.percent.Risky = []
        statistics.simpleStat.percent.Safe = []
        statistics.simpleStat.percent.Unsafe = []

        statistics.simpleStat.count = []
        statistics.simpleStat.count.Risky = []
        statistics.simpleStat.count.Safe = []
        statistics.simpleStat.count.Unsafe = []

        statistics.date = []

        // On traite la donné afin d'avoir le résultat voulu
        // x = poucentage, y = date, on stock les valeurs absolue pour pouvoir les afficher au survol
        Object.keys(dataToTransform).forEach(key => {
            const data = dataToTransform[key]
            // DeepStat
            statistics.deepStat.percent.Catchall.push(100 * data.deepStat.nbCatchall / data.totalMail)
            statistics.deepStat.count.Catchall.push(data.deepStat.nbCatchall)
            statistics.deepStat.percent.CatchallUnfaith.push(100 * data.deepStat.nbCatchallUnfaith / data.totalMail)
            statistics.deepStat.count.CatchallUnfaith.push(data.deepStat.nbCatchallUnfaith)
            statistics.deepStat.percent.Disposable.push(100 * data.deepStat.nbDisposable / data.totalMail)
            statistics.deepStat.count.Disposable.push(data.deepStat.nbDisposable)
            statistics.deepStat.percent.Full.push(100 * data.deepStat.nbFull / data.totalMail)
            statistics.deepStat.count.Full.push(data.deepStat.nbFull)
            statistics.deepStat.percent.Invalid.push(100 * data.deepStat.nbInvalid / data.totalMail)
            statistics.deepStat.count.Invalid.push(data.deepStat.nbInvalid)
            statistics.deepStat.percent.Autogenerated.push(100 * data.deepStat.nbAutogenerated / data.totalMail)
            statistics.deepStat.count.Autogenerated.push(data.deepStat.nbAutogenerated)
            statistics.deepStat.percent.Protected.push(100 * data.deepStat.nbProtected / data.totalMail)
            statistics.deepStat.count.Protected.push(data.deepStat.nbProtected)

            // Cas spéciale ou l'on change le nom de la donnée MalFormed qui est Incorrect partout dans le projet
            statistics.deepStat.percent.Incorrect.push(100 * data.deepStat.nbMalformed / data.totalMail)
            statistics.deepStat.count.Incorrect.push(data.deepStat.nbMalformed)

            statistics.deepStat.percent.Role.push(100 * data.deepStat.nbRole / data.totalMail)
            statistics.deepStat.count.Role.push(data.deepStat.nbRole)
            statistics.deepStat.percent.Spamtrap.push(100 * data.deepStat.nbSpamtrap / data.totalMail)
            statistics.deepStat.count.Spamtrap.push(data.deepStat.nbSpamtrap)
            statistics.deepStat.percent.Suspect.push(100 * data.deepStat.nbSuspect / data.totalMail)
            statistics.deepStat.count.Suspect.push(data.deepStat.nbSuspect)
            statistics.deepStat.percent.Valid.push(100 * data.deepStat.nbValid / data.totalMail)
            statistics.deepStat.count.Valid.push(data.deepStat.nbValid)
            statistics.deepStat.percent.ValidUnfaith.push(100 * data.deepStat.nbValidUnfaith / data.totalMail)
            statistics.deepStat.count.ValidUnfaith.push(data.deepStat.nbValidUnfaith)

            // simpleStat
            statistics.simpleStat.percent.Risky.push(100 * data.simpleStat.nbRisky / data.totalMail)
            statistics.simpleStat.count.Risky.push(data.simpleStat.nbRisky)
            statistics.simpleStat.percent.Safe.push(100 * data.simpleStat.nbSafe / data.totalMail)
            statistics.simpleStat.count.Safe.push(data.simpleStat.nbSafe)
            statistics.simpleStat.percent.Unsafe.push(100 * data.simpleStat.nbUnsafe / data.totalMail)
            statistics.simpleStat.count.Unsafe.push(data.simpleStat.nbUnsafe)

            statistics.date.push(new Date(data.date))
        })

        state.weekStatistics = statistics
    },
    setCreateCampaignModal (state, createCampaignModal) {
        state.createCampaignModal = createCampaignModal
    }
}
