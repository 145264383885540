// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
// Routes
import paths from './paths'
import store from '../store'
import VueCookie from 'vue-cookie'

function route (path, view, name) {
    return {
        name: name || view,
        path,
        component: (resovle) => import(
            `@/views/${view}.vue`
            ).then(resovle)
    }
}

Vue.use(Router)

// Create a new router
let router
// eslint-disable-next-line prefer-const
router = new Router({
    mode: 'history',
    routes: paths.map(path => route(path.path, path.view, path.name)).concat([
        { path: '*', redirect: '/page-home' }
    ]),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
          // https://lifesaver.codes/answer/vue-router-scrollbehaviour-not-working-properly-in-some-environments-1459
          setTimeout(() => document.getElementById(to.hash.substring(1)).scrollIntoView(), 600)
          return {
            selector: to.hash
          }
        }
        return { x: 0, y: 0 }
    }
})

// https://stackoverflow.com/questions/45091380/vue-router-keep-query-parameter-and-use-same-view-for-children
function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  const nextParams = {}
  // transmettre l'origin si il y a, en cas de nouvelle inscription
   if (!store.getters['user/isConnected']() && !hasQueryParams(to) && hasQueryParams(from) && from.query.origin) {
    nextParams.name = to.name
    nextParams.query = {}
    nextParams.query.origin = from.query.origin
  }

  /* redirection sauf si la personne est connectée ou si on est sur la page de connexion,
   * la page de reset de mot de passe, l'inscription ou la page download qui est accessible
   * hors ligne avec un token
   */
  if (!(store.getters['user/isConnected']() || to.path === '/page-connexion' || to.path === '/page-reset' ||
      to.path === '/page-signin' || to.path === '/page-signin-confirmation' || to.path === '/page-download')) {
    // récupération des cookies s'ils existent
    const refreshToken = VueCookie.get('refreshToken')
    const accessToken = VueCookie.get('accessToken')
    if (refreshToken && accessToken) {
      store.commit('user/setRefreshToken', refreshToken)
      store.commit('user/setAccessToken', accessToken)
    } else {
      nextParams.path = '/page-connexion'
      nextParams.name = 'components.toolbar.page-title-connexion'
      nextParams.view = 'connexion'
      nextParams.params = {}
    }
  }

  if (Object.keys(nextParams).length > 0) {
    next(nextParams)
  } else {
    next()
  }
})

Vue.use(Meta)

export default router
