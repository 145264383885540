import listAPI from '@/api/lists'

export default {
    async refreshLists ({ commit, state, rootState }) {
        if (rootState.user.accessTokenPayload && rootState.user.userInfo && rootState.user.userInfo.id) {
            await listAPI.getLists(rootState.user.accessToken, rootState.user.userInfo.id)
                .then(lists => {
                    // sécurité
                    if (typeof lists === 'undefined') {
                        lists = []
                    }

                    // ajout des champs en lazyloading
                    if (lists != null && lists.length > 0) {
                        lists.forEach((list) => {
                            list.firstEmails = false
                            list.campaigns = false
                        })
                    }
                    commit('setLists', lists)
                })
                .catch(error => {
                    // @todo: handle error
                    console.error('List/actions.js/refreshLists: ' + error)
                })
        }
    },
    async getLatestList ({ commit, state, rootState }) {
        if (rootState.user.accessToken) {
            try {
                await listAPI.getLatest(rootState.user.accessToken).then(latestCampaign => {
                    commit('setLatestList', latestCampaign)
                })
            } catch (e) {
                console.warn('problème de avec la requête listAPI.getLatest()')
                commit('setLatestList', null)
            }
        } else {
            console.warn('problème de token')
        }
    },
    resetList ({ commit, state, rootState }) {
        commit('setLatestList', 'pending')
    }
}
