import axios from '@/plugins/axios'

export default {
    getEmailCampaignEnd (accessTokenHeader) {
      return this.getSetting(accessTokenHeader, 'validate_notif_email_campaign_end')
    },
    updateEmailCampaignEnd (accessTokenHeader, value) {
      return this.updateSetting(accessTokenHeader, value, 'validate_notif_email_campaign_end')
    },
     getEmailCampaignEndToken (accessTokenHeader) {
      return this.getSetting(accessTokenHeader, 'validate_notif_email_campaign_end-with-token')
    },
    updateEmailCampaignEndToken (accessTokenHeader, value) {
      return this.updateSetting(accessTokenHeader, value, 'validate_notif_email_campaign_end-with-token')
    },
    getWhiteLabelImageUrl (accessTokenHeader) {
      return this.getSetting(accessTokenHeader, 'validate_white_label_image_url')
    },
    getWhiteLabelImageUrlWithCampaignToken (campaignToken) {
      const url = process.env.VUE_APP_Setting_Url + '?setting=validate_white_label_image_url&authentMethod=campaignToken'
      const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'userId': campaignToken.userId,
        'listId': campaignToken.listId,
        'campaignId': campaignToken.campaignId,
        'token': campaignToken.token
      }
      // eslint-disable-next-line no-undef
      return axios({ method: 'get', url: url, headers: headers })
        .then(response => {
            return response.data
        })
    },
    updateWhiteLabelImageUrl (accessTokenHeader, value) {
        return this.updateSetting(accessTokenHeader, value, 'validate_white_label_image_url')
    },
    getEmailCampaignAutoDeletion (accessTokenHeader) {
      return this.getSetting(accessTokenHeader, 'validate_notif_email_campaign_auto_deletion')
    },
    updateEmailCampaignAutoDeletion (accessTokenHeader, value) {
      return this.updateSetting(accessTokenHeader, value, 'validate_notif_email_campaign_auto_deletion')
    },
    getCampaignAutoDeletionDelay (accessTokenHeader) {
      return this.getSetting(accessTokenHeader, 'validate_campaign_auto_deletion_delay')
    },
    updateCampaignAutoDeletionDelay (accessTokenHeader, value) {
      return this.updateSetting(accessTokenHeader, value, 'validate_campaign_auto_deletion_delay')
    },
    getPreferredLanguage (accessTokenHeader) {
      return this.getSetting(accessTokenHeader, 'global_preferred_language')
    },
    updatePreferredLanguage (accessTokenHeader, value) {
        return this.updateSetting(accessTokenHeader, value, 'global_preferred_language')
    },
    getSetting (accessTokenHeader, settingName) {
      const url = process.env.VUE_APP_Setting_Url + '?setting=' + settingName
      const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: accessTokenHeader
      }
      // eslint-disable-next-line no-undef
      return axios({ method: 'get', url: url, headers: headers })
        .then(response => {
            return response.data
        })
    },
    updateSetting (accessTokenHeader, value, settingName) {
      const url = process.env.VUE_APP_Setting_Url + '?setting=' + settingName
      const headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: accessTokenHeader
      }
      const data = {
        settingKey: settingName,
        value: value,
        msSource: 'validate'
      }
      // eslint-disable-next-line no-undef
      return axios({
        method: 'post',
        url: url,
        headers: headers,
        data: data
      })
    }
}
