import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/lang'

Vue.use(VueI18n)

// récupération préférence utilisateur plus tard, charge la locale du navigateur en attendant
let browserLocale = navigator.language
if (browserLocale.length > 2) {
  browserLocale = browserLocale.substring(0, 2)
}

const numberFormats = {
  'en': {
      eur: {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol'
      },
      usd: {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol'
      },
      usdSimple: {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol'
      }
  },
  'fr': {
      eur: {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol'
      },
      usd: {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol'
      },
      usdSimple: {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol'
      }
  }
}

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  },
  'fr': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  }
}

const i18n = new VueI18n({
  locale: browserLocale,
  fallbackLocale: 'en',
  messages,
  dateTimeFormats,
  numberFormats
})

export default i18n
