import axios from '@/plugins/axios'

export default {
  getInvoiceAddress (accessTokenHeader) {
    const url = process.env.VUE_APP_Invoice_Url + 'address'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.error('catch error from getInvoiceAddress => ', e)
        return null
      })
  },

  saveInvoiceAddress (accessTokenHeader, address) {
    const url = process.env.VUE_APP_Invoice_Url + 'address'

    const data = address

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'post', url: url, headers: headers, data: data })
  },

  getVatRate (accessTokenHeader) {
    const url = process.env.VUE_APP_Invoice_Url + 'vat'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
  },

  getInvoice (accessTokenHeader, transactionId) {
    const url = process.env.VUE_APP_Invoice_Url + '?transaction-id=' + transactionId

    const headers = {
      Authorization: accessTokenHeader
    }

    return axios({ method: 'get', url: url, headers: headers, responseType: 'arraybuffer' })
        .then(response => {
          return response.data
        })
        .catch(() => {
          return null
        })
  }
}
