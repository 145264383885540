export default {
    refreshToken: null, // le refresh token, obtenu en s'identifiant, permettant d'obtenir des accessToken
    accessToken: null, // l'access token, permettant d'accéder à des ressources
    expireTimeRefreshCookie: null,
    expireTimeAccessCookie: null,
    refreshTokenPayload: null, // le contenu du refresh token
    accessTokenPayload: null, // le contenu de l'access token
    userInfo: null, // les informations de l'utilisateur (mail, nom, addresse, ...),
    settingEmailCampaignEnd: false,
    settingEmailCampaignEndToken: false,
    settingWhiteLabelImageUrl: null,
    settingEmailCampaignAutoDeletion: false,
    settingCampaignAutoDeletionDelay: 0,
    settingCampaignAutoDeletionDelayMaxValue: 365,
    settingCampaignAutoDeletionDelayMinValue: 1
}
