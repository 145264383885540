import axios from '@/plugins/axios'

export default {
  startCampaign (accessTokenHeader, listId) {
    const url = process.env.VUE_APP_Campaign_Url + '?listId=' + listId

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'post', url: url, headers: headers })
  },

  /**
   * @description récupération d'une liste de campagne
   * @param accessTokenHeader
   * @param listId
   * @returns {Promise<boolean>}
   */
  getCampaignsByList (accessTokenHeader, listId) {
    const url = process.env.VUE_APP_Campaign_Url + '?listId=' + listId

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data.campaigns
      })
  },

  /**
   * @description récupération de l'historique de la campagne (liste des status)
   * @param accessTokenHeader
   * @param campaignEngineId
   * @returns {Promise<AxiosResponse<any>>}
   */
  getCampaignStatusHistory (accessTokenHeader, campaignEngineId) {
    const url = process.env.VUE_APP_Campaign_Url + campaignEngineId + '/responses'
    const headers = {
      Authorization: accessTokenHeader
    }
    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data.responses
      })
  },

  /**
   @description
   récupération des stats de la campagne

   @model
   state
   totalEmail
   totalChecked
   totalDuplicate
   @detailCut
   totalValid
   totalInboxFull
   totalDisposable
   totalCatchall
   totalRole
   totalSuspect
   totalIncorrect
   totalInvalid
   totalSpamtrap
   totalUnverified

   @simpleCut
   totalSafe
   totalRisky
   totalUnsafe
   @info
   totalChecked = totalEmail - totalDuplicate

   * @param campaignEngineName
   * @returns {Promise<AxiosResponse<any>>}
   */
  getCampaignStats (accessTokenHeader, campaignEngineName) {
    const url = process.env.VUE_APP_Campaign_Url + '?engineName=' + campaignEngineName

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data.campaign
      })
        .catch(e => {
          console.error('catch error from getCampaignStats => ', e)
          return null
        })
  },

  /** Récupérer un fichier de résultat de campagne.
   * fileName in [
   *    disposable,
   *    catchall,
   *    invalides,
   *    malformes (front), // incorrects
   *    role,
   *    spamtrap,
   *    suspects (front),
   *    valides,
   *    valides-inboxfull,
   *    unverified
   *
   *
   *
   *    pre-analyse/email-duplique ??
   *
   *    SAFE-to-send,
   *    RISKY-to-send,
   *    UNSAFE-to-send
   *
   *    zip => full
   * ]
   * Les 9 premiers sont le tris détaillé, les 3 derniers la version simplifiée
   * Retourne le fichier en blob (404 si le nom de fichier n'est pas dans la liste)

   * @doc https://weblab.ownmailist.net/micro-services/validate-microservice/wikis/home
   * @param accessTokenHeader
   * @param campaignEngineName
   * @param fileName
   * @returns {Promise<Blob>}
   */
  getResultFile (accessTokenHeader, campaignEngineName, fileName) {
    const url = process.env.VUE_APP_Campaign_Url + campaignEngineName + '/file?fileName=' + fileName

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return new Blob([response.data], { type: 'text/csv' })
      })
  },

  /**
   * @description Permet de récupérer le status et le temps restant de la campagne
   * @doc https://weblab.ownmailist.net/micro-services/validate-microservice/wikis/home
   * @param accessTokenHeader
   * @param campaignEngineName
   * @returns {Promise<Blob>}
   */
  async getCampaignProgressTime (accessTokenHeader, campaignEngineName) {
    const url = process.env.VUE_APP_Campaign_Url + campaignEngineName + '/time/'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.error('catch error from getCampaignProgressTime => ', e)
        return null
      })
  },

  async getLatest (accessTokenHeader) {
    const url = process.env.VUE_APP_Campaign_Url + 'latest/'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
        .then(response => {
          return response.data
        })
        .catch(e => {
          console.error('catch error from getLatest => ', e)
          return null
        })
  },

  /**
   * @description Permet de récupérer les statistiques des campagnes sur un certain nombre de semaine
   * a été développé initialement pour afficher des statistiques sur l'accueil
   * @param accessTokenHeader
   * @param _numberOfWeeks
   * @returns {Q.Promise<any> | PromiseLike<any>}
   */
  getWeeksStatistics (accessTokenHeader, _numberOfWeeks) {
    const numberOfWeeks = _numberOfWeeks || 12 // 3 mois par défaut
    const url = process.env.VUE_APP_Campaign_Url + 'stats?weeks=' + numberOfWeeks

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios
        .get(url, { headers: headers })
        .then(response => {
          return response.data
        })
  },

  getCampaignsStatus (accessTokenHeader) {
    const url = process.env.VUE_APP_Campaign_Url + '?key=listId&field=status'

    const headers = {
      Authorization: accessTokenHeader
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
        .catch(e => {
          console.error('catch error from getCampaignStats => ', e)
          return null
        })
  }
}
