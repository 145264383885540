<template>
  <div class="campaigns--remove_list_button">
    <v-tooltip
      color="#fff"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            v-if="!archiving"
            color="secondary"
            outlined
            @click="archiveConfirmationDialog = true"
          >
            {{ $t('views.campaigns.archiveList.buttonLabel') }}
          </v-btn>
          <v-progress-circular
            v-else
            color="primary"
            indeterminate
            size="18"
          />
        </div>
      </template>
      {{ $t('views.campaigns.archiveList.tooltip') }}
    </v-tooltip>

    <v-dialog
      v-model="archiveConfirmationDialog"
      max-width="500px"
      @click:outside="$emit('close')"
    >
      <material-card
        :title="$t('views.campaigns.archiveList.confirmationDialogTitle')"
        color="primary"
      >
        <v-card-text v-if="validatedContext">
          {{ $t('views.campaigns.archiveList.contentValidated') }}
        </v-card-text>
        <v-card-text v-else>
          {{ $t('views.campaigns.archiveList.contentNoValidated') }}
        </v-card-text>
        <v-row
          v-if="!archiving"
          justify="center"
        >
          <v-btn
            color="secondary"
            outlined
            style="margin: 10px auto"
            @click="archiveConfirmationDialog = false"
          >
            {{ $t('views.campaigns.archiveList.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            rounded
            @click="archiveList()"
          >
            {{ $t('views.campaigns.archiveList.confirm') }}
          </v-btn>
        </v-row>
        <v-row
          v-else
          justify="center"
        >
          <v-progress-circular
            color="primary"
            indeterminate
            size="18"
          />
        </v-row>
      </material-card>
    </v-dialog>
  </div>
</template>

<script>
  import listAPI from '@/api/lists'
  import { mapState } from 'vuex'

  export default {
    props: {
      listId: {
        type: [Number, String]
      },
      validatedContext: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      archiveConfirmationDialog: false,
      archiving: false
    }),
    computed: {
      ...mapState('user', ['accessToken'])
    },
    methods: {
      archiveList () {
        this.archiving = true
        listAPI.archiveList(this.accessToken, this.listId)
            .then(result => this.$emit('confirmation'))
            .catch(error => this.$emit('error', error))
            .finally(() => { this.archiving = false })
      }
    }
  }
</script>
