<template
  v-if="campaign"
  :key="templateKEY"
>
  <!-- CASE : initialisation -->
  <v-col
    v-if="campaign.stats && (
      campaign.stats.state === 'initialisation' ||
      campaign.stats.state === 'en_attente')"
  >
    <v-card-text align="center">
      <p>
        {{ $t(`views.campaigns.status.${campaign.stats.state}`) }}
      </p>
      <v-progress-linear
        color="primary"
        :indeterminate="true"
      />
    </v-card-text>
  </v-col>
  <!-- CASE : encours -->
  <v-col
    v-else-if="(campaign.stats && (campaign.stats.state === 'en cours' || campaign.stats.state === 'encours') && campaign.progressTime.time.timeLeft !== 0) ||
      campaign.stats.state === 'queued'"
  >
    <v-card-text class="campaign_validation_progression_bar--active">
      <v-avatar
        class="picto"
        color="primary"
      >
        <v-icon
          dark
          x-large
          :class="{animatedRotation : animateRotate}"
        >
          mdi-timer-sand
        </v-icon>
      </v-avatar>
      <div class="content">
        <p align="center">
          {{ $t("views.campaigns.progression.listBefore") }}
          <b>{{ campaign.name }}</b>
          {{ $t("views.campaigns.progression.listAfter") }}
        </p>
        <p align="center">
          <b>{{ $t("views.campaigns.progression.mails",[campaign.list.emailCount]) }}</b>
          <span class="spacer-inline" />
          {{ $t("views.campaigns.progression.date") }}
          <b>{{ this.$d( new Date(campaign.created), 'long') }}</b>
        </p>
        <v-progress-linear
          :value="progressionPercent"
          color="primary"
          height="25"
        >
          <template v-slot="{ value }">
            <strong>{{ Math.ceil(value) }}% - {{ timeLeftInProgressBarString }}</strong>
          </template>
        </v-progress-linear>
      </div>
    </v-card-text>
  </v-col>
  <v-col v-else>
    <v-card-text>
      <v-card-text align="center">
        <p>{{ $t(`views.campaigns.status.finishInComing`) }}</p>
        <v-progress-linear
          color="primary"
          :indeterminate="true"
        />
      </v-card-text>
    </v-card-text>
  </v-col>
</template>

<script>
    export default {
        props: {
            campaign: {
                type: Object,
                default: null
            }
        },
        data: function () {
            return {
                animateRotate: false,
                timeLeftInProgressBarString: 'calcule en cours...',
                timeLeftInProgressBar: 0,
                progressionPercent: 0,
                intervalInProgress: 0,
                templateKEY: 0,
                initTime: 1000 * 10, // 10 sec
                finalisationTime: 1000 * 15 // 10 sec
            }
        },
        beforeRouteEnter () {
            clearInterval(this.intervalInProgress)
        },
        watch: {
            campaign () {
                this.templateKEY++
                this.initProgressionBar()
            }
        },
        mounted () {
            this.initProgressionBar()
        },
        beforeDestroy () {
            clearInterval(this.intervalInProgress)
        },
        methods: {
            initProgressionBar () {
                switch (this.campaign.stats.state) {
                    case 'en cours' :
                        this.timeLeftInProgressBar = this.campaign.time.timeLeft
                        if (this.campaign.time.timeLeft === 0) {
                            return this.launchTimeoutRefresh(this.finalisationTime)
                        }
                        return this.launchIntervalProgression()
                    case 'queued' :
                        this.timeLeftInProgressBar = this.campaign.time.total
                        return this.launchIntervalProgression()
                  default:
                      this.launchTimeoutRefresh(this.initTime)
                      break
                }
            },

            launchTimeoutRefresh (_time) {
                this.templateKEY++
                setTimeout(() => {
                    this.$emit('refreshCampaign')
                }, _time)
            },

            // lance l'interval qui permettra de mettre à jour le pourcentage de la progress bar
            // @info : les temps peuvent changer en fonction de l'évolution de l'engine derrière
            //
            // on relance la requête toutes les 8 minutes (progressTime = 1min * progressTimeMinutes)
            // SI le temps restant est inférieur à 8 minutes (progressTimeMinutes)
            // ALORS on rajoute artificiellement 2 minutes
            //
            // on fait la mise à jour de l'interface toutes les minutes (updatePercentInProgressBar)
            launchIntervalProgression () {
                let relaunchTime
                    const updatePercentInProgressBar = 10 * 1000
                    const progressTimeMinutes = 8 // temps en minute
                    const progressTime = 1000 * 60 * progressTimeMinutes

                // check du temps restant
                if (this.campaign.stats.state === 'en cours' && this.campaign.time.timeLeft === 0) {
                    return this.initProgressionBar()
                }

                if (this.campaign.time.total > progressTime) {
                    relaunchTime = progressTime
                } else {
                    relaunchTime = this.timeLeftInProgressBar + (2 * 60 * 1000)
                    // on rajoute 3 minutes pour cacher les défaut de l'estimation
                    this.timeLeftInProgressBar += (3 * 60 * 1000)
                }
                clearInterval(this.intervalInProgress)
                // init de la progressBar
                this.reloadProgressBar()

                // mise à jour à intervale régulière
                this.intervalInProgress = setInterval(() => {
                    this.timeLeftInProgressBar -= updatePercentInProgressBar
                    this.reloadProgressBar()
                }, updatePercentInProgressBar)

                // on relance la requête pour récupérer des données fraiches du serveur
                setTimeout(() => {
                    clearInterval(this.intervalInProgress)
                    this.$emit('refreshCampaign')
                }, relaunchTime)
            },

            // Calcule des temps pour afficher la progresse bar
            reloadProgressBar () {
                const temps_ecoule_minutes = (new Date() - new Date(this.campaign.created))
                const temps_total = temps_ecoule_minutes + this.timeLeftInProgressBar
                const temps_restant = this.timeLeftInProgressBar

                if (temps_ecoule_minutes > 0) {
                    this.progressionPercent = 100 * temps_ecoule_minutes / temps_total
                } else {
                    this.progressionPercent = 100
                }

                // animation du sablier
                this.animateRotate = !this.animateRotate
                setTimeout(() => {
                    this.animateRotate = !this.animateRotate
                }, 300)

                // update du temps affiché
                this.timeLeftInProgressBarString = this.outputStringFormat(temps_restant)

                // Sécurité dans le cas ou le temps est égale ou supérieur à 100% on déclenche l'appelle la requête
                if (this.progressionPercent >= 100) {
                    clearInterval(this.intervalInProgress)
                    this.$emit('refreshCampaign')
                }

                // forceRefresh le template
                this.templateKEY++
            },

            // permet de formater en texte tel que : "String xHeures et String xMinutes"
            outputStringFormat (_data) {
                _data = _data / 60000 // converti en simple minutes
                if (_data < 60) {
                    // Les minutes
                    const minutes = Math.floor(_data % 60)
                    // eslint-disable-next-line no-undef
                  return (minutes <= 1)
                        ? this.$t('views.campaigns.progression.minute', [minutes])
                        : this.$t('views.campaigns.progression.minutes', [minutes])
                } else {
                    // les heures
                    const hours = Math.floor(_data / 60)
                    // eslint-disable-next-line no-undef
                    const hoursString = (hours === 1)
                        ? this.$t('views.campaigns.progression.hour', [hours])
                        : this.$t('views.campaigns.progression.hours', [hours])

                    // Les minutes
                    const minutes = Math.floor(_data % 60)
                    // eslint-disable-next-line no-undef
                    const minutesString = (minutes <= 1)
                        ? this.$t('views.campaigns.progression.minute')
                        : this.$t('views.campaigns.progression.minutes', [minutes])
                    return hoursString + ' ' + minutesString
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-icon {
        transform: rotateX(0);
        transition: transform 0s ease-out 800s;

        &.animatedRotation {
            transform: rotateX(180deg);
        }
    }
</style>
