import creditsAPI from '@/api/credits'

export default {
  refreshCredits ({ commit, state, rootState }) {
    if (rootState.user.accessToken) {
      creditsAPI.getUserCredits(rootState.user.accessToken)
        .then(credits => {
          commit('setCredits', credits)
        })
        .catch(error => {
          console.error('credit/actions.js/refreshCredits: ' + error)
        })
    } else {
      commit('setCredits', 0)
      console.warn('Les crédit n\'ont pas les crédentials pour se lancer')
    }
  },
  refreshCreditsHistory ({ commit, state, rootState }) {
    if (rootState.user.accessToken && rootState.user.userInfo) {
      creditsAPI.getUserCreditHistory(rootState.user.accessToken, rootState.user.userInfo.id)
        .then(credits => {
          commit('setCreditsHistory', credits)
        })
        .catch(error => {
          console.error('credit/actions.js/refreshCreditsHistory: ' + error)
        })
    } else {
      commit('setCreditsHistory', '')
      console.warn('L\'historique de crédit n\'a pas les crédentials pour se lancer')
    }
  },

  async fetchWeekStatistics ({ commit, state, rootState }, numberOfWeeks) {
    if (rootState.user.accessToken) {
      try {
        const weekStatistics = await creditsAPI.getWeeksStatistics(rootState.user.accessToken, numberOfWeeks)
        commit('setWeekStatistics', weekStatistics)
      } catch (error) {
        console.error('credit/actions.js/fetchWeekStatistics: ' + error)
      }
    } else {
      console.warn('problème le token')
    }
  }
}
