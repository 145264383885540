
<template>
  <v-banner
    v-if="isSafari"
    color="warning"
    icon="mdi-alert"
    single-line
    dark
  >
    {{ $t('components.safari-warning.text') }}
  </v-banner>
</template>

<script>
  export default {
    data: function () {
      return {
        isSafari: true
      }
    },
    mounted: function () {
      // https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
      const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1
      const safariAgent = navigator.userAgent.indexOf('Safari') > -1
      this.isSafari = !chromeAgent && safariAgent
    }
  }
</script>
