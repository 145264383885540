import validatadeUnitaryUtils from '@/utils/validate-unitary'
import colorUtils from '@/utils/color'

export default class ValidateUnitaryCurveCharts {
    generateCurve (statistics, _vueApp) {
      const dates = statistics.date.map((date) => {
        return _vueApp.$d(date)
      })
        return {
            datasets: [
              {
                label: _vueApp.$t('views.home.validateUnitary.graph.verysafe'),
                borderWidth: 5,
                borderColor: colorUtils.pSBC(0.10, validatadeUnitaryUtils.colorSheme.verySafe),
                backgroundColor: validatadeUnitaryUtils.colorSheme.verySafe,
                data: statistics.verysafe,
                count: statistics.count.verysafe
              }, {
                label: _vueApp.$t('views.home.validateUnitary.graph.safe'),
                borderWidth: 5,
                borderColor: colorUtils.pSBC(0.10, validatadeUnitaryUtils.colorSheme.safe),
                backgroundColor: validatadeUnitaryUtils.colorSheme.safe,
                data: statistics.safe,
                count: statistics.count.safe
              }, {
                label: _vueApp.$t('views.home.validateUnitary.graph.risky'),
                borderWidth: 5,
                borderColor: colorUtils.pSBC(0.10, validatadeUnitaryUtils.colorSheme.risky),
                backgroundColor: validatadeUnitaryUtils.colorSheme.risky,
                data: statistics.risky,
                count: statistics.count.risky
              }, {
                label: _vueApp.$t('views.home.validateUnitary.graph.unsafe'),
                borderWidth: 5,
                borderColor: colorUtils.pSBC(0.10, validatadeUnitaryUtils.colorSheme.unsafe),
                backgroundColor: validatadeUnitaryUtils.colorSheme.unsafe,
                data: statistics.unsafe,
                count: statistics.count.unsafe
              }
            ],
            labels: dates
        }
    }
};
