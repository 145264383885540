<template>
  <material-card
    class="credit_statistic"
    icon="mdi-chart-bar"
    :title="$t('views.home.credits.statistic.title')"
    color="primary"
  >
    <v-row data-v-tour-interface-step="3" />
    <v-row class="credit_statistic--flex_container_row">
      <v-col
        :cols="12"
        :sm="6"
        :md="12"
        :xl="6"
        align="center"
        align-self="center"
      >
        <v-select
          v-model="selectModel"
          class="select"
          :items="numberOfWeeks"
          item-text="text"
          item-value="value"
          :label="$t('views.home.campaign.statistic.selectWeeks')"
        />
      </v-col>
      <v-col
        :cols="12"
        :sm="6"
        :md="12"
        :xl="6"
        class="credit_statistic--flex_container_col_donuts"
      >
        <div class="chart_container--donuts">
          <canvas id="credit_statistic-week" />
        </div>
      </v-col>
    </v-row>
  </material-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Chart from 'chart.js'
import CreditPieCharts from './CreditPieCharts'

const creditPieCharts = new CreditPieCharts()

export default {
  data: function () {
    return {
      selectModel: 13,
      numberOfWeeks: [],
      myChart: null
    }
  },
  computed: {
    ...mapState('credit', ['weekStatistics']),
    locale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    selectModel () {
      this.updateCurveChart()
    },
    locale: function () {
      this.generateNumberOfWeeksOptions()
      this.createCurveChart()
    }
  },
  created () {
    this.generateNumberOfWeeksOptions()
    this.createCurveChart()
    window.addEventListener('resize', this.switchLegendOnCharts)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.switchLegendOnCharts)
  },
  methods: {
    ...mapActions('credit', ['fetchWeekStatistics']),
    async updateCurveChart () {
      await this.fetchWeekStatistics(this.selectModel)
      this.myChart.data = creditPieCharts.generateCurve(this.weekStatistics, this)
      this.myChart.update()
    },
    async createCurveChart () {
      // on fait l'appel pour récupérer les données statistiques : this.weekStatistics
      await this.fetchWeekStatistics(this.selectModel)
      // construction du graphique
      const ctx = document.getElementById('credit_statistic-week')
      if (ctx) {
        // eslint-disable-next-line no-unused-vars
        this.myChart = new Chart(ctx, {
          type: 'doughnut',
          data: creditPieCharts.generateCurve(this.weekStatistics, this),
          options: {
            responsive: true,
            legend: {
              display: window.innerWidth > 420,
              position: 'bottom'
            },
            animation: {
              animateScale: true,
              animateRotate: true
            }
          }
        })
      }
    },
    switchLegendOnCharts () {
      if (this.myChart !== null) {
        this.myChart.legend.options.display = window.innerWidth > 420
      }
    },
    generateNumberOfWeeksOptions () {
      this.numberOfWeeks = [{
        text: this.$t('views.home.campaign.statistic.graph.title.1'),
        value: 1
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.2'),
        value: 2
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.4'),
        value: 4
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.13'),
        value: 13
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.26'),
        value: 26
      }, {
        text: this.$t('views.home.campaign.statistic.graph.title.52'),
        value: 52
      }]
    }
  }
}
</script>
