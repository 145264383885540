import axios from '@/plugins/axios'

export default {
    getUserInfo (accessTokenHeader) {
        const url = process.env.VUE_APP_User_Url
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.get(url, { headers: headers })
            .then(response => response.data)
    },
    // https://weblab.ownmailist.net/micro-services/user-microservice/wikis/home#put-useruserid
    updateUser (accessTokenHeader, user) {
        const url = process.env.VUE_APP_User_Url
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.put(url, user, { headers: headers })
    },
    updatePassword (accessTokenHeader, newPassword) {
        const url = process.env.VUE_APP_User_Url + 'password'

        const headers = {
            'Content-Type': 'text/plain',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.put(url, newPassword, { headers: headers })
    },
    updatePasswordToken (accessTokenHeader, newPassword, email, token) {
      const url = process.env.VUE_APP_User_Url + 'password-token?email=' + email + '&token=' + token

      const headers = {
          'Content-Type': 'text/plain',
          Authorization: accessTokenHeader
      }

      // eslint-disable-next-line no-undef
      return axios.put(url, newPassword, { headers: headers })
        .then(response => response.data)
    },
    /**
     * @responseCode 200 = valid
     * @responseCode 403 = invalid
     * @param accessTokenHeader
     * @param email
     * @param token
     * @returns {Q.Promise<any> | * | PromiseLike<any>}
     */
    async getValidityOfTokenPassword (accessTokenHeader, email, token) {
        const url = process.env.VUE_APP_User_Url + 'password-token?email=' + email + '&token=' + token

        const headers = {
          'Content-Type': 'text/plain',
          Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios.get(url, { headers: headers })
          .then(response => {
            return response
          }).catch(error => {
            return error
          })
    },
    resetPassword (accessTokenHeader, email) {
      const url = process.env.VUE_APP_User_Url + 'password-token'

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': accessTokenHeader
      }

      const data = {
        'email': email,
        'rootUrl': process.env.VUE_APP_Origin_Url
      }

      // eslint-disable-next-line no-undef
      return axios.post(url, data, { headers: headers })
    },
    signin (userInfo) {
      const url = process.env.VUE_APP_User_Url + 'signin-token?redirect=' + process.env.VUE_APP_Origin_Url

      const headers = {
        'Content-Type': 'application/json'
      }

      // eslint-disable-next-line no-undef
      return axios.post(url, userInfo, { headers: headers })
    },
    completeSignin (token) {
      const url = process.env.VUE_APP_User_Url + 'signin-token?token=' + token

      const headers = {
        'Content-Type': 'application/json'
      }

      const data = {}

      // eslint-disable-next-line no-undef
      return axios.put(url, data, { headers: headers })
    },
    resendSigninToken (userEmail) {
        const url = process.env.VUE_APP_User_Url + 'signin-token?redirect=' + process.env.VUE_APP_Origin_Url +
            '&userEmail=' + userEmail

        // eslint-disable-next-line no-undef
        return axios.put(url)
    }
}
