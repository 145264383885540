import axios from '@/plugins/axios'

export default {
  validateToken (token) {
    const url = process.env.VUE_APP_Campaign_Token_Url

    const headers = {
      'userId': token.userId,
      'listId': token.listId,
      'campaignId': token.campaignId,
      'token': token.token
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
  },

  getList (token) {
    const url = process.env.VUE_APP_List_Url + 'authentMethod=campaignToken'

    const headers = {
      'userId': token.userId,
      'listId': token.listId,
      'campaignId': token.campaignId,
      'token': token.token
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
  },

  getCampaign (token) {
    const url = process.env.VUE_APP_Campaign_Url + token.campaignId

    const headers = {
      'userId': token.userId,
      'listId': token.listId,
      'campaignId': token.campaignId,
      'token': token.token
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
  },

  getEngineCampaign (token) {
    const url = process.env.VUE_APP_Campaign_Url + 'engine'

    const headers = {
      'userId': token.userId,
      'listId': token.listId,
      'campaignId': token.campaignId,
      'token': token.token
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return response.data
      })
  },

  /**
   * @description récupération d'une liste de campagne
   * @param accessTokenHeader
   * @param listId
   * @returns {Promise<boolean>}
   */
  getResultFile (token, filenName) {
    const url = process.env.VUE_APP_Campaign_Url + 'file?fileName=' + filenName

    const headers = {
      'userId': token.userId,
      'listId': token.listId,
      'campaignId': token.campaignId,
      'token': token.token
    }

    // eslint-disable-next-line no-undef
    return axios({ method: 'get', url: url, headers: headers })
      .then(response => {
        return new Blob([response.data], { type: 'text/csv' })
      })
  }
}
