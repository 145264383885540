import axios from 'axios'

axios.interceptors.response.use((response) => {
    // rien
    return response
}, (error) => {
  // vérifier qu'il n'y ai pas de handler personnalisé
  // handle par défaut
  try {
    if (error.message === 'Network Error') {
      // eslint-disable-next-line no-undef
      vueApp.$toast(vueApp.$t('global.networkError'), {
        icon: 'mdi-wifi-off',
        color: 'orange'
      })
    } else if (error.response !== null && error.response.status === 503) {
      // eslint-disable-next-line no-undef
      vueApp.$toast(vueApp.$t('global.networkError'), {
        icon: 'mdi-wifi-off',
        color: 'orange'
      })
    } else if (error.response !== null && error.response.status === 403) {
      if (error.config.url.search(process.env.VUE_APP_Session_Url) > -1) {
        // eslint-disable-next-line no-undef
        vueApp.$toast(vueApp.$t('components.login.invalid-credentials'), {
          icon: 'mdi-alert-circle',
          color: 'red'
        })
      }
    } else {
      // on catch le cas particulier du GET sur la validité du token lors d'un reset de mot de passe
      if (error.config.url.search('password-token?email=') === -1 && error.config.method !== 'get') {
        // eslint-disable-next-line no-undef
        vueApp.$toast(vueApp.$t('global.genericError'), {
          icon: 'mdi-alert-circle',
          color: 'red'
        })
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-undef
    vueApp.$toast(vueApp.$t('global.genericError'), {
      icon: 'mdi-alert-circle',
      color: 'red'
    })
  }
  return Promise.reject(error)
})

export default axios
