import axios from '@/plugins/axios'

export default {
    getUserCredits (accessTokenHeader) {
        const url = process.env.VUE_APP_Credit_Url
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios({ method: 'get', url: url, headers: headers })
            .then(response => {
                return response.data.credits
            })
    },

    // ajout et suppression des crédits
    getUserCreditHistory (accessTokenHeader, userId) {
        const url = process.env.VUE_APP_Credit_Url + userId + '/history'
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios({ method: 'get', url: url, headers: headers })
            .then(response => {
                return response.data.history
            })
    },

    /**
     * @description Permet de récupérer les statistiques des crédits sur un certain nombre de semaine
     * a été développé initialement pour afficher des statistiques sur l'accueil
     * @param accessTokenHeader
     * @param _numberOfWeeks
     * @param _type
     * @returns {Q.Promise<any> | PromiseLike<any>}
     */
    getWeeksStatistics (accessTokenHeader, _numberOfWeeks, _type) {
        const numberOfWeeks = _numberOfWeeks || 12 // 3 mois par défaut
        const type = _type || 'simple' // simple par défaut
        const url = process.env.VUE_APP_Credit_Url + 'stats?type=' + type + '&weeks=' + numberOfWeeks

        const headers = {
            Authorization: accessTokenHeader
        }

        // eslint-disable-next-line no-undef
        return axios
            .get(url, { headers: headers })
            .then(response => {
                return response.data
            })
    }
}
