<template>
  <span
    v-if="validateWeather"
    class="valid_weather--container"
  >
    <v-tooltip
      v-if="validateWeather.code === 'green'"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-icon
          color="primary"
          v-on="on"
        >
          mdi-white-balance-sunny
        </v-icon>
      </template>
      <span>{{ validateWeather.description }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="validateWeather.code === 'yellow'"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-icon
          color="#e4d235"
          v-on="on"
        >
          mdi-weather-partly-cloudy
        </v-icon>
      </template>
      <span>{{ validateWeather.description }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="validateWeather.code === 'orange'"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-icon
          color="orange"
          v-on="on"
        >
          mdi-weather-cloudy
        </v-icon>
      </template>
      <span>{{ validateWeather.description }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="validateWeather.code === 'red'"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-icon
          color="orange"
          v-on="on"
        >
          mdi-weather-pouring
        </v-icon>
      </template>
      <span>{{ validateWeather.description }}</span>
    </v-tooltip>

    <v-tooltip
      v-if="validateWeather.code === 'brown' || validateWeather.code === 'black'"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-icon
          color="red"
          v-on="on"
        >
          mdi-weather-lightning
        </v-icon>
      </template>
      <span>{{ validateWeather.description }}</span>
    </v-tooltip>
  </span>
</template>
<script>
    export default {
        props: {
            validateWeather: {
                type: Object,
                default: null
            }
        }
    }
</script>
