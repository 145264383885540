import Vue from 'vue'
import Vuetify from 'vuetify'
import customTheme from './theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    themes: {
      light: customTheme
    }
  }
})
