export default class CreditPieCharts {
    colorSheme = {
        totalCampaign: 'rgb(255,192,37)',
        totalSimple: 'rgb(118,255,132)',
        totalDeep: 'rgb(61,164,255)'
    }

    generateCurve (statistics, _vueApp) {
        return {
            datasets: [{
                backgroundColor: [
                    this.colorSheme.totalCampaign,
                    this.colorSheme.totalSimple,
                    this.colorSheme.totalDeep
                ],
                data: [
                    statistics.totalCampaign,
                    statistics.totalSimple,
                    statistics.totalDeep
                ]
            }],
            labels: [
                _vueApp.$t('views.home.credits.statistic.totalCampaignLabel'),
                _vueApp.$t('views.home.credits.statistic.totalSimpleLabel'),
                _vueApp.$t('views.home.credits.statistic.totalDeepLabel')
            ]
        }
    }
};
