export default {
    setCredits (state, credits) {
        state.credits = credits
    },
    setCreditsHistory (state, creditsTable) {
        state.creditsHistory = creditsTable
    },

    setWeekStatistics (state, weekStatistics) {
        state.weekStatistics = weekStatistics
    }
}
