<template>
  <material-card
    :title="$t('views.validateUnitary.simpleTable.title')"
    color="primary"
  >
    <v-card-actions data-v-tour-unitary-step="6">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('views.validateUnitary.simpleTable.search')"
        single-line
        hide-details
        data-v-tour-unitary-step="8"
      />
      <v-spacer />
      <v-tooltip
        v-if="!isCsvDownloadRunning"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            data-v-tour-unitary-step="9"
            v-on="on"
            @click="downloadAllCsv()"
          >
            {{ $t('views.validateUnitary.simpleTable.export') }}
            <v-icon class="ml-2 mr-1">
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </template>
        {{ $t('views.validateUnitary.simpleTable.downloadAllFile.instructions') }}
      </v-tooltip>
      <v-progress-circular
        v-else
        color="primary"
        indeterminate
        size="18"
      />
    </v-card-actions>
    <v-data-table
      :headers="validateUnitarySimpleHeaders"
      :items="emailsSimple"
      :search="search"
      :no-data-text="$t('views.validateUnitary.simpleTable.noData')"
      :no-results-text="$t('views.validateUnitary.simpleTable.noResult')"
      sort-by="validated"
      sort-desc
      update:
      @current-items="updateVisibleItems"
    >
      <template v-slot:item.email="{ item }">
        <span v-if="item.email">
          {{ item.email }}
        </span>
      </template>
      <template v-slot:item.validationType="{ item }">
        <span v-if="item.validationType">
          {{ $t('views.validateUnitary.simpleTable.'+ item.validationType) }}
        </span>
      </template>
      <template v-slot:item.validated="{ item }">
        <span v-if="item.validated">
          {{ $d(new Date(item.validated), 'long') }}
        </span>
      </template>
      <template v-slot:item.seeDetails="{ item }">
        <div
          v-if="item"
          class="cell-action"
          @click="$emit('openDetails', item)"
        >
          <v-tooltip
            v-if="typeof item.userId == 'number'"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                color="primary"
                x-large
                v-on="on"
              >
                mdi-email-search
              </v-icon>
            </template>
            <span> {{ $t('views.validateUnitary.simpleTable.detail') }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.category="{ item }">
        <span v-if="item.userId == null">
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </span>
        <v-chip
          v-else
          :color="wichColorChip(item)"
          :text-color="wichTextColorChip(item)"
        >
          {{ $t('views.validateUnitary.simpleTable.'+ item.category) }}
        </v-chip>
      </template>
    </v-data-table>
  </material-card>
</template>
<script>

    import { mapState, mapActions } from 'vuex'
    import exportFile from './exportFile'
    import validateAPI from '@/api/validate-unitary'

    export default {
        props: {
            emailsSimple: {
                type: Array,
                default: () => ([])
            }
        },
        data: function () {
            return {
                search: '',
                isCsvDownloadRunning: false,
                // header des listes de mails
                validateUnitarySimpleHeaders: [
                    {
                        text: this.$t('views.validateUnitary.simpleTable.email'),
                        value: 'email',
                        class: 'validate_unitary--table_list--col_email'
                    },
                    {
                        text: this.$t('views.validateUnitary.simpleTable.date'),
                        value: 'validated',
                        class: 'validate_unitary--table_list--col_date',
                        filterable: false
                    },
                    {
                        text: this.$t('views.validateUnitary.simpleTable.type'),
                        value: 'validationType',
                        class: 'validate_unitary--table_list--col_type',
                        sortable: false,
                        filterable: false,
                        align: 'center'
                    },
                    {
                        text: this.$t('views.validateUnitary.simpleTable.category'),
                        value: 'category',
                        filterable: false,
                        sortable: false,
                        align: 'center'
                    },
                    { // bouton de détails
                        text: this.$t('views.validateUnitary.simpleTable.details'),
                        value: 'seeDetails',
                        sortable: false,
                        filterable: false
                    }
                ],
                visibleItems: []
            }
        },
        computed: {
            ...mapState('user', ['accessToken']),
            ...mapState('validateUnitary', ['emailsChecked'])
        },
        methods: {
            ...mapActions('validateUnitary', ['fetchEmailsValided']),
            async downloadAllCsv () {
                this.isCsvDownloadRunning = true
                const fileName = 'all-validate-unitary' + exportFile.constructDateFileName(this)
                validateAPI.getLimitedValidateUnitaries(this.accessToken)
                  .then(data => {
                    const dataFormated = exportFile.constructFile(this, data)
                    const blob = new Blob([dataFormated], { type: 'text/csv' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = fileName + '.csv'
                    link.click()
                    this.isCsvDownloadRunning = false
                  })
            },
            updateVisibleItems (items) {
                this.visibleItems = items
            },
            wichColorChip (value) {
                if (typeof value !== 'object' && typeof value.type !== 'string' && typeof value.value !== 'boolean') return ''
                switch (true) {
                    case value.category === 'VERY_SAFE':
                        return 'primary'
                    case value.category === 'RISKY':
                        return 'orange'
                    case value.category === 'UNSAFE':
                        return 'red'
                    default:
                        return '#aadc25'
                }
            },
            wichTextColorChip (value) {
                if (typeof value !== 'object' && typeof value.type !== 'string' && typeof value.value !== 'boolean') return ''
                return 'white'
            }
        }
    }
</script>
