<template>
  <material-card
    class="last_campaign"
    :icon="campaignList.icon"
    :title="$t('views.home.campaign.latest.title')"
    color="primary"
  >
    <v-row data-v-tour-interface-step="2" />
    <v-row
      v-if="templateDisplay === 'pending'"
      class="text-center last_campaign--flex_container_row"
      align="center"
    >
      <v-col :cols="12">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-col>
    </v-row>

    <v-row
      v-if="templateDisplay === 'no-list'"
      class="text-center last_campaign--flex_container_row"
      align="center"
    >
      <v-col :cols="12">
        <div>
          {{ $t('views.home.campaign.latest.createFirstList.content') }}
        </div>
        <router-link :to="'/page-campaigns?from=buttonCreateFirstList'">
          <v-btn color="primary">
            {{ $t('views.home.campaign.latest.createFirstList.button') }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <v-row
      v-if="templateDisplay === 'list-and-campaign-without-validation'"
      align="center"
      class="last_campaign--flex_container_row"
    >
      <v-col
        :cols="12"
        class="text-center"
      >
        <div>
          {{ $t('views.home.campaign.latest.list-and-campaign-without-validation.content',[latestList.name]) }}
        </div>
        <router-link :to="`/page-campaigns?from=viewCampaignDetail&idList=${latestList.id}`">
          <v-btn color="primary">
            {{ $t('views.home.campaign.latest.list-and-campaign-without-validation.button') }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <v-row
      v-if="templateDisplay === 'campaign-under-validation'"
      align="center"
      class="last_campaign--flex_container_row"
    >
      <campaigns-validation-progression-bar
        :campaign="campaignSelected"
        @refreshCampaign="refreshCampaign"
      />
    </v-row>

    <v-row
      v-if="templateDisplay === 'campaign-validated'"
      class="last_campaign_validated last_campaign--flex_container_row"
    >
      <v-col
        :cols="12"
        :sm="6"
        :md="12"
        :xl="6"
        align="center"
        align-self="center"
        class="last_campaign_validated--container_content"
      >
        <v-row no-gutters>
          <v-col
            :cols="4"
            align-self="center"
          >
            <img
              :src="logo"
              class="last_campaign_validated--image"
            >
          </v-col>
          <v-col
            :cols="8"
            align-self="center"
          >
            <p class="last_campaign_validated--text">
              {{ $t("views.home.campaign.latest.campaign-validated.content.list") }}
              <b>{{ campaignSelected.name }}</b><br>
              {{ $t("views.home.campaign.latest.campaign-validated.content.date") }}
              <b>{{ $d(new Date(campaignSelected.created)) }}</b>
              {{ $t("views.home.campaign.latest.campaign-validated.content.mail",[campaignSelected.stats.totalEmail]) }}
            </p>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
        >
          <v-col :cols="12">
            <v-btn
              :to="`/page-campaigns?from=viewCampaignDetail&idList=${campaignSelected.listId}`"
              class="last_campaign_validated--button"
              color="primary"
            >
              {{ $t('views.home.campaign.latest.campaign-validated.button') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        :cols="12"
        :sm="6"
        :md="12"
        :xl="6"
        class="last_campaign--flex_container_col_donuts"
      >
        <div class="chart_container--donuts">
          <canvas id="simpleCampaign-pie" />
        </div>
      </v-col>
    </v-row>
  </material-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ApiCampaign from '@/api/campaigns'
import Chart from 'chart.js'
import CampaignCharts from '@/components/campaigns/CampaignsCharts'

const campaignCharts = new CampaignCharts()

  export default {
    data: function () {
      return {
        myChart: null,
        logo: './img/mailnjoy_check.png',
        templateDisplay: 'pending',
        campaignValidated: null,
        campaignSelected: null,
      }
    },
    computed: {
      ...mapState('campaign', ['latestCampaign', 'campaignFirst']),
      ...mapState('list', ['latestList']),
      ...mapState('user', ['accessToken']),
      campaignList () {
        return this.entitiesPageCampaigns()
      },
      locale: function () {
        return this.$i18n.locale
      }
    },
    watch: {
      locale: function () {
        if (this.campaignFirst) {
          this.createPieChart(
              'simpleCampaign-pie',
              campaignCharts.generateSimpleTableDataForPie(this.campaignFirst.statistics, this)
          )
        }
      }
    },
    created () {
      this.resetCampaign()
      this.resetList()
      this.findLatestCampaign()
      window.addEventListener('resize', this.switchLegendOnCharts)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.switchLegendOnCharts)
    },
    methods: {
      ...mapActions('campaign', ['getLatestCampaign', 'resetCampaign', 'getCampaignsByListAction', 'getCampaignStatsFirstCampaignAction']),
      ...mapActions('list', ['getLatestList', 'resetList']),
      ...mapGetters('app', ['entitiesPageCampaigns']),

      async refreshCampaign () {
        await this.findLatestCampaign()
      },
      async findLatestCampaign () {
        await this.getLatestList()
        if (this.latestList === null) {
          this.templateDisplay = 'no-list'
        } else {
          await this.getLatestCampaign()
          if (this.latestCampaign === null) {
            this.templateDisplay = 'list-and-campaign-without-validation'
          } else {
            // récupération d'une campagne par rapport à une liste
            await this.getCampaignsByListAction(this.latestCampaign.listId)

            // récupération des statistiques sur cette première (dans la liste) campagne
            await this.getCampaignStatsFirstCampaignAction()

            this.campaignSelected = this.campaignFirst

            // CASE : aucune campagne n'a jamais été lancée
            if (typeof this.campaignFirst === 'undefined') {
              this.campaignSelected.campaignStatus = 'not launch'
            }

            switch (this.latestCampaign.state) {
              case 'queued' :
              case 'en_attente' :
              case 'en cours' :
                this.templateDisplay = 'campaign-under-validation'
                break
              case 'terminee':
                if (this.latestCampaign.readyToPrintClient) {
                  ApiCampaign
                    .getCampaignStats(this.accessToken, this.latestCampaign.engineName)
                    .then(result => { this.campaignValidated = result })
                    .finally(() => {
                      this.templateDisplay = 'campaign-validated'
                      // affichage des stats simplifiées
                      setTimeout(() => {
                          this.createPieChart(
                            'simpleCampaign-pie',
                            campaignCharts.generateSimpleTableDataForPie(this.campaignFirst.statistics, this)
                          )
                        }, 100
                      )
                    })
                } else {
                  this.templateDisplay = 'campaign-under-validation'
                }
                break
              default:
                this.templateDisplay = 'list-and-campaign-without-validation'
                break
            }
          }
        }
      },
      // Création d'une tarte de statistique
      createPieChart (chartId, chartData) {
        const ctx = document.getElementById(chartId)
        if (ctx) {
          // eslint-disable-next-line no-unused-vars
          this.myChart = new Chart(ctx, {
            type: 'doughnut',
            data: chartData,
            options: {
              responsive: true,
              legend: {
                display: window.innerWidth > 420,
                position: 'bottom'
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  // Permet d'afficher autre chose que les données brut par défaut
                  label: function (tooltipItem, data) {
                    const item = data.datasets[tooltipItem.datasetIndex]
                    return `${data.labels[tooltipItem.index]} : ${item.data[tooltipItem.index]} (${item.percent[tooltipItem.index]}%)`
                  }
                }
              },
              animation: {
                animateScale: true,
                animateRotate: true
              }
            }
          })
        }
      },
      switchLegendOnCharts () {
        if (this.myChart !== null) {
          this.myChart.legend.options.display = window.innerWidth > 420
        }
      }
    }
  }
</script>
